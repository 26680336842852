import { mdiPencil } from "@mdi/js";
import { Button, Input, ModalProps, Row } from "antd";
import { ContractorVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import { MaterialIcon } from "components/MaterialIcon";
import CustomModal from "components/ui/CustomModal";
import useAllSlenderTownsQuery from "hooks/queries/useAllSlenderTownsQuery";
import useEditFormModal from "hooks/useEditModal";
import useFeature from "hooks/useFeature";
import { mapPublicTownVmToExtendedSelectValue } from "models/common";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, useGrapeAntdForm } from "widgets/form";
import { ListRequestParams } from "widgets/table/useTableUtils";
import {
  ContractorCreateFormValues,
  mapToCreateContractorCommand,
  mapToUpdateContractorCommand,
} from "../models";
import CompanyFieldset from "./components/CompanyFieldset";
import ContactsFieldset from "./components/ContactsFieldset";
import TownsFieldset from "./components/TownsFieldset";

export interface ContractorCreateModalProps extends ModalProps {
  fetchDataParams?: ListRequestParams;
}

export interface ContractorVmWithEmailArray
  extends Omit<ContractorVm, "email"> {
  email?: string | string[] | null;
}

export interface ContractorCreateFormValuesWithEmailArray
  extends Omit<ContractorCreateFormValues, "email"> {
  email?: string | string[] | null;
}

const ContractorCreateModal: React.FC<ContractorCreateModalProps> = ({
  onCancel,
  visible,
  fetchDataParams,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils =
    useGrapeAntdForm<ContractorCreateFormValuesWithEmailArray>();
  const dispatch = useDispatch();
  const { editingItemId } = useSelector((state) => state.contractorCreate);
  const { contractor: data } = useSelector((state) => state.contractorView);

  const contractor = useMemo(() => {
    return data ? { ...data, email: data?.email?.split(";") } : undefined;
  }, [data]);

  const { create, edit } = useSelector(
    (state) => state.loading.effects.contractorCreate
  );
  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const townsQuery = useAllSlenderTownsQuery();

  const getInitialValues = useCallback(
    (
      itemData: ContractorVmWithEmailArray
    ): ContractorCreateFormValuesWithEmailArray => {
      const headquarterAddressTownVm = townsQuery.data?.items?.find(
        (item) => item.name === itemData.headquarterAddress?.city
      );
      const postalAddressTownVm = townsQuery.data?.items?.find(
        (item) => item.name === itemData.postalAddress?.city
      );

      return {
        contactPersons: [
          ...(itemData.contacts?.length
            ? itemData.contacts.map((contact) => ({
                email: contact.email || "",
                familyname: contact.familyname || "",
                forename: contact.forename || "",
                phone: {
                  number: contact.phone?.number || "",
                },
                id: contact.id,
                userId: contact.userId,
                registrationState: contact.registrationState,
              }))
            : [
                {
                  familyname: "",
                  forename: "",
                  email: "",
                  phone: {
                    number: "",
                  },
                },
              ]),
        ],
        email: (itemData.email as string) || [],
        headquarterAddress: {
          ...itemData.headquarterAddress,
          zip: itemData.headquarterAddress?.zip,
          city: mapPublicTownVmToExtendedSelectValue(headquarterAddressTownVm),
          street: itemData.headquarterAddress?.street || "",
          number: itemData.headquarterAddress?.number || "",
        },
        name: itemData.name || "",
        phone: {
          number: itemData.phone?.number || "",
        },
        postalAddress: {
          ...itemData.postalAddress,
          zip: itemData.postalAddress?.zip,
          city: mapPublicTownVmToExtendedSelectValue(postalAddressTownVm),
          street: itemData.postalAddress?.street || "",
          number: itemData.postalAddress?.number || "",
        },
        townIds: itemData.towns?.map((town) => town.id || ""),
        companyRegisterNumber: itemData.companyRegisterNumber || undefined,
        taxNumber: itemData.taxNumber || undefined,
        isActive: itemData.isActive,
        repairmanEmailOfTaskAssignment: itemData.repairmanEmailOfTaskAssignment,
        id: itemData.id,
        status: itemData.status,
      };
    },
    [townsQuery.data?.items]
  );

  const { formMode, setFormMode } = useEditFormModal<
    ContractorVmWithEmailArray,
    ContractorCreateFormValuesWithEmailArray
  >({
    item: contractor,
    formUtils,
    getInitialValues,
    modalIsVisible: visible,
    resetItemId: dispatch.contractorCreate.setEditingItemId,
    itemId: editingItemId,
  });

  const handleFinish = useCallback(
    async (values: ContractorCreateFormValuesWithEmailArray): Promise<void> => {
      const updatedValues = {
        ...values,
        email: Array.isArray(values.email)
          ? values.email?.join(";")
          : (values.email as string),
      };

      if (formMode === "Create") {
        await dispatch.contractorCreate.create(
          mapToCreateContractorCommand(updatedValues)
        );

        dispatch.contractorsList.fetchList(fetchDataParams);
      }

      if (formMode === "Edit") {
        await dispatch.contractorCreate.edit(
          mapToUpdateContractorCommand(updatedValues)
        );

        if (typeof updatedValues.id === "string") {
          dispatch.contractorView.fetchContractor(updatedValues.id);
        }
      }

      onCancel?.("" as any);
      formUtils.form.resetFields();
    },
    [
      dispatch.contractorCreate,
      dispatch.contractorView,
      dispatch.contractorsList,
      fetchDataParams,
      formMode,
      formUtils.form,
      onCancel,
    ]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  useEffect(() => {
    if (contractor && visible) {
      setFormMode("Edit");
    } else {
      setFormMode("Create");
    }
  }, [contractor, setFormMode, visible]);

  return (
    <CustomModal
      {...rest}
      onCancel={onCancel}
      title={
        formMode === "Create"
          ? t("contractor.createModal.title")
          : t("contractor.editModal.title")
      }
      size="xl"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{ loading: create.loading || edit.loading }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      formInstance={formUtils.form}
      visible={visible}
      longModal
    >
      <CustomForm
        formUtils={formUtils}
        onFinish={handleFinish}
        viewMode={formMode === "View"}
      >
        {formMode === "View" && (
          <Row justify="end">
            <Button
              icon={<MaterialIcon path={mdiPencil} />}
              onClick={() => setFormMode("Edit")}
            >
              {t("common.edit")}
            </Button>
          </Row>
        )}

        <GrapeAntdForm.Item name="id" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item name="status" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        <Row gutter={[0, 48]}>
          <CompanyFieldset formMode={formMode} />
          {!isMaintainedDevicesFeatureEnabled && <TownsFieldset />}
          <ContactsFieldset formMode={formMode} formUtils={formUtils} />
        </Row>
      </CustomForm>
    </CustomModal>
  );
};

export default ContractorCreateModal;
