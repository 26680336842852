/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignSpecialPlaceToContractorCommand,
    AssignSpecialPlaceToContractorCommandFromJSON,
    AssignSpecialPlaceToContractorCommandToJSON,
    AssignSpecialPlaceToDevicesCommand,
    AssignSpecialPlaceToDevicesCommandFromJSON,
    AssignSpecialPlaceToDevicesCommandToJSON,
    ContractorVm,
    ContractorVmFromJSON,
    ContractorVmToJSON,
    CreateSpecialPlaceCommand,
    CreateSpecialPlaceCommandFromJSON,
    CreateSpecialPlaceCommandToJSON,
    DetachSpecialPlaceFromContractorCommand,
    DetachSpecialPlaceFromContractorCommandFromJSON,
    DetachSpecialPlaceFromContractorCommandToJSON,
    DetachSpecialPlaceFromDevicesCommand,
    DetachSpecialPlaceFromDevicesCommandFromJSON,
    DetachSpecialPlaceFromDevicesCommandToJSON,
    DetachSpecificSpecialPlaceFromDevicesCommand,
    DetachSpecificSpecialPlaceFromDevicesCommandFromJSON,
    DetachSpecificSpecialPlaceFromDevicesCommandToJSON,
    DeviceVm,
    DeviceVmFromJSON,
    DeviceVmToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllSpecialPlacesQuery,
    GetAllSpecialPlacesQueryFromJSON,
    GetAllSpecialPlacesQueryToJSON,
    SpecialPlaceVm,
    SpecialPlaceVmFromJSON,
    SpecialPlaceVmToJSON,
    SpecialPlaceVmPaginatedSearchResponse,
    SpecialPlaceVmPaginatedSearchResponseFromJSON,
    SpecialPlaceVmPaginatedSearchResponseToJSON,
    UpdateSpecialPlaceCommand,
    UpdateSpecialPlaceCommandFromJSON,
    UpdateSpecialPlaceCommandToJSON,
} from '../models';

export interface ApiSpecialPlacesAssignSpecialPlaceToContractorPostRequest {
    assignSpecialPlaceToContractorCommand?: AssignSpecialPlaceToContractorCommand;
}

export interface ApiSpecialPlacesAssignSpecialPlaceToDevicesPutRequest {
    assignSpecialPlaceToDevicesCommand?: AssignSpecialPlaceToDevicesCommand;
}

export interface ApiSpecialPlacesDetachSpecialPlaceFromContractorDeleteRequest {
    detachSpecialPlaceFromContractorCommand?: DetachSpecialPlaceFromContractorCommand;
}

export interface ApiSpecialPlacesDetachSpecialPlaceFromDevicesPutRequest {
    detachSpecialPlaceFromDevicesCommand?: DetachSpecialPlaceFromDevicesCommand;
}

export interface ApiSpecialPlacesDetachSpecificSpecialPlaceFromDevicesPutRequest {
    detachSpecificSpecialPlaceFromDevicesCommand?: DetachSpecificSpecialPlaceFromDevicesCommand;
}

export interface ApiSpecialPlacesGetAllPostRequest {
    getAllSpecialPlacesQuery?: GetAllSpecialPlacesQuery;
}

export interface ApiSpecialPlacesGetSpecialPlaceContractorsidGetRequest {
    id: string;
}

export interface ApiSpecialPlacesGetSpecialPlaceDevicesidGetRequest {
    id: string;
}

export interface ApiSpecialPlacesIdDeleteRequest {
    id: string;
}

export interface ApiSpecialPlacesIdGetRequest {
    id: string;
}

export interface ApiSpecialPlacesIdPutRequest {
    id: string;
    updateSpecialPlaceCommand?: UpdateSpecialPlaceCommand;
}

export interface ApiSpecialPlacesPostRequest {
    createSpecialPlaceCommand?: CreateSpecialPlaceCommand;
}

/**
 * 
 */
export class SpecialPlacesApi extends runtime.BaseAPI {

    /**
     * Assigns a special place to a contractor
     */
    async apiSpecialPlacesAssignSpecialPlaceToContractorPostRaw(requestParameters: ApiSpecialPlacesAssignSpecialPlaceToContractorPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/AssignSpecialPlaceToContractor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignSpecialPlaceToContractorCommandToJSON(requestParameters.assignSpecialPlaceToContractorCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assigns a special place to a contractor
     */
    async apiSpecialPlacesAssignSpecialPlaceToContractorPost(requestParameters: ApiSpecialPlacesAssignSpecialPlaceToContractorPostRequest): Promise<void> {
        await this.apiSpecialPlacesAssignSpecialPlaceToContractorPostRaw(requestParameters);
    }

    /**
     * Assigns special place to multiple devices
     */
    async apiSpecialPlacesAssignSpecialPlaceToDevicesPutRaw(requestParameters: ApiSpecialPlacesAssignSpecialPlaceToDevicesPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/AssignSpecialPlaceToDevices`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AssignSpecialPlaceToDevicesCommandToJSON(requestParameters.assignSpecialPlaceToDevicesCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assigns special place to multiple devices
     */
    async apiSpecialPlacesAssignSpecialPlaceToDevicesPut(requestParameters: ApiSpecialPlacesAssignSpecialPlaceToDevicesPutRequest): Promise<void> {
        await this.apiSpecialPlacesAssignSpecialPlaceToDevicesPutRaw(requestParameters);
    }

    /**
     * Detaches a Special place from a Contractor Delete
     */
    async apiSpecialPlacesDetachSpecialPlaceFromContractorDeleteRaw(requestParameters: ApiSpecialPlacesDetachSpecialPlaceFromContractorDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/DetachSpecialPlaceFromContractor`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DetachSpecialPlaceFromContractorCommandToJSON(requestParameters.detachSpecialPlaceFromContractorCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Detaches a Special place from a Contractor Delete
     */
    async apiSpecialPlacesDetachSpecialPlaceFromContractorDelete(requestParameters: ApiSpecialPlacesDetachSpecialPlaceFromContractorDeleteRequest): Promise<void> {
        await this.apiSpecialPlacesDetachSpecialPlaceFromContractorDeleteRaw(requestParameters);
    }

    /**
     * Detach special place from multiple devices
     */
    async apiSpecialPlacesDetachSpecialPlaceFromDevicesPutRaw(requestParameters: ApiSpecialPlacesDetachSpecialPlaceFromDevicesPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/DetachSpecialPlaceFromDevices`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DetachSpecialPlaceFromDevicesCommandToJSON(requestParameters.detachSpecialPlaceFromDevicesCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Detach special place from multiple devices
     */
    async apiSpecialPlacesDetachSpecialPlaceFromDevicesPut(requestParameters: ApiSpecialPlacesDetachSpecialPlaceFromDevicesPutRequest): Promise<void> {
        await this.apiSpecialPlacesDetachSpecialPlaceFromDevicesPutRaw(requestParameters);
    }

    /**
     * Detach specific special place from multiple devices that belong to the given special place
     */
    async apiSpecialPlacesDetachSpecificSpecialPlaceFromDevicesPutRaw(requestParameters: ApiSpecialPlacesDetachSpecificSpecialPlaceFromDevicesPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/DetachSpecificSpecialPlaceFromDevices`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DetachSpecificSpecialPlaceFromDevicesCommandToJSON(requestParameters.detachSpecificSpecialPlaceFromDevicesCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Detach specific special place from multiple devices that belong to the given special place
     */
    async apiSpecialPlacesDetachSpecificSpecialPlaceFromDevicesPut(requestParameters: ApiSpecialPlacesDetachSpecificSpecialPlaceFromDevicesPutRequest): Promise<void> {
        await this.apiSpecialPlacesDetachSpecificSpecialPlaceFromDevicesPutRaw(requestParameters);
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get special places. Yes, get by Post.
     */
    async apiSpecialPlacesGetAllPostRaw(requestParameters: ApiSpecialPlacesGetAllPostRequest): Promise<runtime.ApiResponse<SpecialPlaceVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllSpecialPlacesQueryToJSON(requestParameters.getAllSpecialPlacesQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialPlaceVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get special places. Yes, get by Post.
     */
    async apiSpecialPlacesGetAllPost(requestParameters: ApiSpecialPlacesGetAllPostRequest): Promise<SpecialPlaceVmPaginatedSearchResponse> {
        const response = await this.apiSpecialPlacesGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * List of Contractors of a specific Special place
     */
    async apiSpecialPlacesGetSpecialPlaceContractorsidGetRaw(requestParameters: ApiSpecialPlacesGetSpecialPlaceContractorsidGetRequest): Promise<runtime.ApiResponse<Array<ContractorVm>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSpecialPlacesGetSpecialPlaceContractorsidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/GetSpecialPlaceContractors{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractorVmFromJSON));
    }

    /**
     * List of Contractors of a specific Special place
     */
    async apiSpecialPlacesGetSpecialPlaceContractorsidGet(requestParameters: ApiSpecialPlacesGetSpecialPlaceContractorsidGetRequest): Promise<Array<ContractorVm>> {
        const response = await this.apiSpecialPlacesGetSpecialPlaceContractorsidGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * List of Devices of a special place
     */
    async apiSpecialPlacesGetSpecialPlaceDevicesidGetRaw(requestParameters: ApiSpecialPlacesGetSpecialPlaceDevicesidGetRequest): Promise<runtime.ApiResponse<Array<DeviceVm>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSpecialPlacesGetSpecialPlaceDevicesidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/GetSpecialPlaceDevices{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceVmFromJSON));
    }

    /**
     * List of Devices of a special place
     */
    async apiSpecialPlacesGetSpecialPlaceDevicesidGet(requestParameters: ApiSpecialPlacesGetSpecialPlaceDevicesidGetRequest): Promise<Array<DeviceVm>> {
        const response = await this.apiSpecialPlacesGetSpecialPlaceDevicesidGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiSpecialPlacesIdDeleteRaw(requestParameters: ApiSpecialPlacesIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSpecialPlacesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiSpecialPlacesIdDelete(requestParameters: ApiSpecialPlacesIdDeleteRequest): Promise<void> {
        await this.apiSpecialPlacesIdDeleteRaw(requestParameters);
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiSpecialPlacesIdGetRaw(requestParameters: ApiSpecialPlacesIdGetRequest): Promise<runtime.ApiResponse<SpecialPlaceVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSpecialPlacesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialPlaceVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiSpecialPlacesIdGet(requestParameters: ApiSpecialPlacesIdGetRequest): Promise<SpecialPlaceVm> {
        const response = await this.apiSpecialPlacesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiSpecialPlacesIdPutRaw(requestParameters: ApiSpecialPlacesIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiSpecialPlacesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSpecialPlaceCommandToJSON(requestParameters.updateSpecialPlaceCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiSpecialPlacesIdPut(requestParameters: ApiSpecialPlacesIdPutRequest): Promise<void> {
        await this.apiSpecialPlacesIdPutRaw(requestParameters);
    }

    /**
     * Creates a Special place
     */
    async apiSpecialPlacesPostRaw(requestParameters: ApiSpecialPlacesPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/SpecialPlaces`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateSpecialPlaceCommandToJSON(requestParameters.createSpecialPlaceCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a Special place
     */
    async apiSpecialPlacesPost(requestParameters: ApiSpecialPlacesPostRequest): Promise<string> {
        const response = await this.apiSpecialPlacesPostRaw(requestParameters);
        return await response.value();
    }

}
