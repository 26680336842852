import { FC, ReactNode } from "react";
import styles from "./StretchToCardEdge.module.scss";
import clsx from "clsx";

interface StretchToCardEdgeProps {
  children?: ReactNode;
  className?: string;
}

const StretchToCardEdge: FC<StretchToCardEdgeProps> = ({
  children,
  className,
}) => {
  return <div className={clsx(styles.wrapper, className)}>{children}</div>;
};

export default StretchToCardEdge;
