/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllImagesQuery,
    GetAllImagesQueryFromJSON,
    GetAllImagesQueryToJSON,
    ImageMetadataVmPaginatedSearchResponse,
    ImageMetadataVmPaginatedSearchResponseFromJSON,
    ImageMetadataVmPaginatedSearchResponseToJSON,
} from '../models';

export interface ApiImagesGetAllPostRequest {
    getAllImagesQuery?: GetAllImagesQuery;
}

export interface ApiImagesIdGetRequest {
    id: string;
}

export interface ApiImagesThumbnailIdGetRequest {
    id: string;
}

export interface ApiImagesUploadPostRequest {
    imageName?: string;
    imageImageData?: Blob;
    generateThumbnails?: boolean;
}

/**
 * 
 */
export class ImagesApi extends runtime.BaseAPI {

    /**
     * Returns the filtered instances for the actual query. For query description Lumen.Domain.Messages.Requests.GetAllQuery.
     */
    async apiImagesGetAllPostRaw(requestParameters: ApiImagesGetAllPostRequest): Promise<runtime.ApiResponse<ImageMetadataVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Images/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllImagesQueryToJSON(requestParameters.getAllImagesQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ImageMetadataVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns the filtered instances for the actual query. For query description Lumen.Domain.Messages.Requests.GetAllQuery.
     */
    async apiImagesGetAllPost(requestParameters: ApiImagesGetAllPostRequest): Promise<ImageMetadataVmPaginatedSearchResponse> {
        const response = await this.apiImagesGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Download image
     */
    async apiImagesIdGetRaw(requestParameters: ApiImagesIdGetRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiImagesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Images/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download image
     */
    async apiImagesIdGet(requestParameters: ApiImagesIdGetRequest): Promise<Blob> {
        const response = await this.apiImagesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Download image
     */
    async apiImagesThumbnailIdGetRaw(requestParameters: ApiImagesThumbnailIdGetRequest): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiImagesThumbnailIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Images/Thumbnail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download image
     */
    async apiImagesThumbnailIdGet(requestParameters: ApiImagesThumbnailIdGetRequest): Promise<Blob> {
        const response = await this.apiImagesThumbnailIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Upload an image.
     */
    async apiImagesUploadPostRaw(requestParameters: ApiImagesUploadPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.imageName !== undefined) {
            formParams.append('Image.Name', requestParameters.imageName as any);
        }

        if (requestParameters.imageImageData !== undefined) {
            formParams.append('Image.ImageData', requestParameters.imageImageData as any);
        }

        if (requestParameters.generateThumbnails !== undefined) {
            formParams.append('GenerateThumbnails', requestParameters.generateThumbnails as any);
        }

        const response = await this.request({
            path: `/api/Images/Upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Upload an image.
     */
    async apiImagesUploadPost(requestParameters: ApiImagesUploadPostRequest): Promise<string> {
        const response = await this.apiImagesUploadPostRaw(requestParameters);
        return await response.value();
    }

}
