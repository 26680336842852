import { Col, Descriptions, Input, ModalProps, Row } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomModal from "components/ui/CustomModal";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGrapeAntdForm } from "widgets/form";
import DateDisplay, { DateFormat } from "widgets/table/DateDisplay";
import styles from "./FailureAssignModal.module.scss";
import ChooseContractorToAssign from "./components/ChooseContractorToAssign";
import ChooseRepairmenToAssign from "./components/ChooseRepairmenToAssign";
import GoogleMapsAddressLink from "components/ui/GoogleMapsAddressLink";
import ReporterDataDisplay from "./components/ReporterDataDisplay";
import useFeature from "hooks/useFeature";
import { FailureListRequestParams } from "../models";

const { Search } = Input;

export type FailureAssignType = "contractor" | "repairmen";

interface FailureAssignModalProps extends ModalProps {
  type: FailureAssignType;
  fetchDataParams?: FailureListRequestParams;
  failureListType?: "toAttend";
}

const FailureAssignModal: React.FC<FailureAssignModalProps> = ({
  onCancel,
  visible,
  type,
  fetchDataParams,
  failureListType,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm();
  const dispatch = useDispatch();
  const {
    assignToContractor,
    assignToRepairmen,
    fetchContractorsForTowns,
    fetchContractorsForDevices,
    fetchRepairmen,
  } = useSelector((state) => state.loading.effects.failureAssign);
  const { failure, editingItemId, contractors, repairmen } = useSelector(
    (state) => state.failureAssign
  );
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  const filteredContractors = useMemo(
    () =>
      contractors.filter(({ name }) =>
        name?.toLowerCase().includes(searchKeyword.toLowerCase())
      ),
    [contractors, searchKeyword]
  );

  const filteredRepairmen = useMemo(
    () =>
      repairmen.filter(
        ({ familyname, forename, middlename }) =>
          familyname?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          forename?.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          middlename?.toLowerCase().includes(searchKeyword.toLowerCase())
      ),
    [repairmen, searchKeyword]
  );

  const handleCancel = useCallback(() => {
    onCancel?.("" as any);

    if (!visible) {
      setTimeout(() => {
        formUtils.form.resetFields();

        dispatch.failureAssign.setEditingItemId(undefined);
        dispatch.failureAssign.setContractors([]);
        dispatch.failureAssign.setRepairmen([]);
      }, 300); // modal closing animation's length
    }
  }, [dispatch.failureAssign, formUtils.form, onCancel, visible]);

  const fetchContractorsData = useCallback(() => {
    if (failure?.address?.city && !isMaintainedDevicesFeatureEnabled) {
      return dispatch.failureAssign.fetchContractorsForTowns([
        failure.address.city,
      ]);
    }

    if (failure?.deviceId) {
      return dispatch.failureAssign.fetchContractorsForDevices([
        failure.deviceId,
      ]);
    }
  }, [
    dispatch.failureAssign,
    isMaintainedDevicesFeatureEnabled,
    failure?.address?.city,
    failure?.deviceId,
  ]);

  useEffect(() => {
    if (editingItemId) {
      dispatch.failureAssign.fetchFailure(editingItemId);
    }
  }, [dispatch.failureAssign, editingItemId]);

  useEffect(() => {
    if (visible && type === "contractor") {
      fetchContractorsData();
    }

    if (
      visible &&
      type === "repairmen" &&
      typeof failure?.contractor?.id === "string"
    ) {
      dispatch.failureAssign.fetchRepairmen(failure?.contractor?.id);
    }
  }, [
    dispatch.failureAssign,
    fetchContractorsData,
    failure?.contractor?.id,
    type,
    visible,
  ]);

  return (
    <CustomModal
      {...rest}
      visible={visible}
      onCancel={handleCancel}
      title={
        type === "contractor"
          ? t("failure.assignModal.typeContractor.title")
          : t("failure.assignModal.typeRepairmen.title")
      }
      size="xl"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{
        loading: assignToContractor.loading || assignToRepairmen.loading,
      }}
      cancelButtonProps={{
        disabled: assignToContractor.loading || assignToRepairmen.loading,
      }}
      okText={t("failure.assignModal.okText")}
      formInstance={formUtils.form}
    >
      <Row gutter={40}>
        <Col span={12}>
          <article className={styles.card}>
            <header className={styles.cardHeader}>
              <span>{failure?.type?.name}</span>

              <span>{failure?.identifier}</span>
            </header>
            <main className={styles.cardMain}>
              <Descriptions column={1} layout="vertical" colon={false}>
                <Descriptions.Item label={t("failure.reportingDate")}>
                  <DateDisplay
                    value={failure?.reportedAt}
                    format={DateFormat.Date}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("failure.deadline")}>
                  <DateDisplay
                    value={failure?.deadline}
                    format={DateFormat.Date}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t("failure.recommendedFixingDays")}>
                  <DictionaryDisplay
                    dictionary={failure?.optimalDeadlineDays}
                  />
                </Descriptions.Item>
                {!isMaintainedDevicesFeatureEnabled && (
                  <Descriptions.Item label={t("failure.address")}>
                    <GoogleMapsAddressLink address={failure?.address} />
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={t("failure.comment")}>
                  <ReporterDataDisplay comment={failure?.comment} />
                </Descriptions.Item>
              </Descriptions>
              {(failure?.notifierFamilyname ||
                failure?.notifierForename ||
                failure?.notifierMiddlename ||
                failure?.notifierEmail) && (
                <Descriptions
                  column={1}
                  layout="vertical"
                  colon={false}
                  className={styles.reporterDescriptions}
                >
                  <Descriptions.Item label={t("failure.reporter")}>
                    <ReporterDataDisplay
                      notifierFamilyname={failure.notifierFamilyname}
                      notifierForename={failure.notifierForename}
                      notifierMiddlename={failure.notifierMiddlename}
                      notifierEmail={failure.notifierEmail}
                    />
                  </Descriptions.Item>
                </Descriptions>
              )}
            </main>
          </article>
        </Col>
        <Col span={12}>
          <p>
            {type === "contractor" &&
              t("failure.assignModal.typeContractor.description")}

            {type === "repairmen" &&
              t("failure.assignModal.typeRepairmen.description")}
          </p>
          <div className={styles.searchWrapper}>
            <Search
              size="large"
              onChange={(e) => setSearchKeyword(e.target.value)}
              placeholder={t("failure.assignModal.searchPlaceholder")}
              loading={
                fetchContractorsForTowns.loading ||
                fetchContractorsForDevices.loading ||
                fetchRepairmen.loading
              }
            />
          </div>

          {type === "contractor" && (
            <ChooseContractorToAssign
              formUtils={formUtils}
              failure={failure}
              onCancel={handleCancel}
              contractors={filteredContractors}
              fetchDataParams={fetchDataParams}
              contractorsLoading={
                fetchContractorsForTowns.loading ||
                fetchContractorsForDevices.loading
              }
              failureListType={failureListType}
            />
          )}

          {type === "repairmen" && (
            <ChooseRepairmenToAssign
              formUtils={formUtils}
              failure={failure}
              onCancel={handleCancel}
              repairmen={filteredRepairmen}
              fetchDataParams={fetchDataParams}
              failureListType={failureListType}
            />
          )}
        </Col>
      </Row>
    </CustomModal>
  );
};

export default FailureAssignModal;
