import { useDispatch, useSelector } from "app/store";
import { useEffect } from "react";

const VersionHistoryPage = () => {
  const dispatch = useDispatch();
  const { versionHistory } = useSelector((state) => state.common);

  useEffect(() => {
    dispatch.common.getVersionHistory();
  }, [dispatch.common]);

  return versionHistory ? (
    <div dangerouslySetInnerHTML={{ __html: versionHistory }} />
  ) : (
    <></>
  );
};

export default VersionHistoryPage;
