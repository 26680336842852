import { mdiArrowLeft } from "@mdi/js";
import { PageHeader, Select, Tabs, notification } from "antd";
import { useDispatch, useSelector } from "app/store";
import MobilePageLayout from "components/layout/MobilePageLayout";
import { MaterialIcon } from "components/MaterialIcon";
import StretchToMobileLayoutEdge from "components/StretchToMobileLayoutEdge";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import FailureViewRepairerTabContent from "./components/FailureViewRepairerTabContent";
import FailureViewDetailsTab from "./components/FailureViewDetailsTab/FailureViewDetailsTab";
import styles from "./FailureViewRepairer.module.scss";
import FailureViewWorkbookTab from "./components/FailureViewWorkbookTab/FailureViewWorkbookTab";
import RepairerCommentModal from "./components/RepairerCommentModal";
import useFailureStatusesQuery from "hooks/queries/useFailureStatusesQuery";
import { FailureStatus } from "models/common";
import { globalErrorDisplay } from "utils/globalErrorDisplay";
import { RequestErrorBody } from "api/middleware/errorHandlingMiddleware";
import { FieldRequireTypes } from "api/generated/lumen";

const noticedFailureStatus: FailureStatus = "Noticed";

const FailureViewRepairer: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();
  const { failure } = useSelector((state) => state.failureView);
  const { edit } = useSelector((state) => state.loading.effects.failureCreate);

  const failureStatusesQuery = useFailureStatusesQuery();

  const sealedFailureStatusIds = useMemo(
    () =>
      failureStatusesQuery.data
        ?.filter((item) => item.isSealed)
        .map((item) => item.id) || [],
    [failureStatusesQuery.data]
  );

  const { fetchFailure } = useSelector(
    (state) => state.loading.effects.failureView
  );
  const navigate = useNavigate();

  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [selectedStatusId, setSelectedStatusId] = useState<string | undefined>(
    failure?.status?.id
  );

  const [statusChangeComment, setStatusChangeComment] = useState<
    string | undefined | null
  >(failure?.stateChangeReason);

  // Reason to disable options: 'Noticed' status cannot be acquired again after the failure has been marked closed in any way.
  // When the currently selected status is inactive && the option is an 'open state'
  const failureStatusOptions = useMemo(
    () =>
      failureStatusesQuery.data?.map((item) => ({
        label: item.name,
        value: item.id || "",
        requiresStateChangeReason: item.requiresStateChangeReason,
        disabled: item.value === noticedFailureStatus,
      })),
    [failureStatusesQuery.data]
  );

  const handleResponseError = useCallback(
    async (response: Response) => {
      const responseText = await response.text();

      if (responseText === "") {
        notification.error({
          message: i18n.t<string>("error.common.errorsTitle"),
          duration: 10,
        });
      } else {
        const error: RequestErrorBody = JSON.parse(responseText);

        error.errors?.forEach((item) => {
          const isFieldError = item.fieldname.length > 0;
          if (item.errortype !== null) {
            globalErrorDisplay(
              error.code,
              isFieldError ? item.errortype : item.errorkey,
              isFieldError
            );
          }
        });
      }
    },
    [i18n]
  );

  const fetchFailureData = useCallback(() => {
    if (typeof params.id === "string") {
      dispatch.failureView.fetchFailure(params.id);
    }
  }, [dispatch.failureView, params.id]);

  const handleCommentModalCancel = useCallback(() => {
    setSelectedStatusId(failure?.status?.id);
    setStatusChangeComment(failure?.stateChangeReason);
    if (commentModalOpen) {
      setCommentModalOpen(false);
    }
  }, [commentModalOpen, failure?.stateChangeReason, failure?.status?.id]);

  const handleCommentModalOk = useCallback(
    (selectedStatusId: string) => {
      if (failure) {
        dispatch.failureCreate
          .edit({
            id: failure?.id || "",
            statusId: selectedStatusId,
            stateChangeReason: statusChangeComment,
            typeId: failure.type?.id || "",
            address: {
              ...failure.address,
              zip: failure.address?.zip,
              street: failure.address?.street || "",
              city: failure.address?.city || "",
              number: failure.address?.number || "",
            },
            natureId: failure.nature?.id || "",
            expanseId: failure.expanse?.id || "",
            contractorId: failure.contractor?.id,
            optimalDeadlineDaysId: failure.optimalDeadlineDays?.id,
            deviceId: failure.deviceId,
          })
          .catch((error) => {
            handleCommentModalCancel();
            // Process field errors also, because there's no Form on the Repairer's edit view
            handleResponseError(error);
          })
          .then(() => {
            if (commentModalOpen) {
              setCommentModalOpen(false);
            }
            fetchFailureData();
          });
      }
    },
    [
      commentModalOpen,
      dispatch.failureCreate,
      failure,
      fetchFailureData,
      handleCommentModalCancel,
      handleResponseError,
      statusChangeComment,
    ]
  );

  const isCommentRequired = useMemo(() => {
    const requiresStateChangeReason = failureStatusOptions?.find(
      (status) => status.value === selectedStatusId
    )?.requiresStateChangeReason;

    return requiresStateChangeReason === FieldRequireTypes.Required;
  }, [failureStatusOptions, selectedStatusId]);

  const handleStateSelectChange = useCallback(
    (value: string | undefined) => {
      setSelectedStatusId(value);
      if (!value) {
        return;
      }

      const requiresStateChangeReason = failureStatusOptions?.find(
        (status) => status.value === value
      )?.requiresStateChangeReason;
      if (
        requiresStateChangeReason === FieldRequireTypes.Allowed ||
        requiresStateChangeReason === FieldRequireTypes.Required
      ) {
        setCommentModalOpen(true);
      } else {
        handleCommentModalOk(value);
      }
    },
    [failureStatusOptions, handleCommentModalOk]
  );

  useEffect(() => {
    fetchFailureData();
  }, [fetchFailureData]);

  useEffect(() => {
    return () => {
      dispatch.failureView.reset();
    };
  }, [dispatch.failureView]);

  // Force react state consistency
  useEffect(() => {
    setStatusChangeComment(failure?.stateChangeReason);
  }, [failure?.stateChangeReason]);

  // Needed otherwise the first cancel would clear out the select value to undefined as the 'previously selected' value
  useEffect(() => {
    setSelectedStatusId(failure?.status?.id);
  }, [failure?.status?.id]);

  return (
    <MobilePageLayout
      rightContent={
        <div className={styles.selectWrapper}>
          {fetchFailure.success && failureStatusesQuery.isSuccess && (
            <Select
              size="large"
              dropdownClassName={styles.selectDropdown}
              options={failureStatusOptions}
              defaultValue={failure?.status?.id}
              value={selectedStatusId}
              onChange={handleStateSelectChange}
              disabled={sealedFailureStatusIds.includes(selectedStatusId)}
            />
          )}
        </div>
      }
      leftContent={
        <PageHeader
          onBack={() => navigate("/failures")}
          title={failure?.identifier || " "}
          className={styles.pageHeader}
          backIcon={<MaterialIcon path={mdiArrowLeft} />}
        />
      }
    >
      <StretchToMobileLayoutEdge>
        <Tabs
          defaultActiveKey="1"
          destroyInactiveTabPane
          className={styles.tabs}
        >
          <Tabs.TabPane tab={t("failureRepairman.tabs.details")} key="1">
            <FailureViewRepairerTabContent>
              <FailureViewDetailsTab />
            </FailureViewRepairerTabContent>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("failureRepairman.tabs.workbook")} key="2">
            <FailureViewRepairerTabContent>
              <FailureViewWorkbookTab />
            </FailureViewRepairerTabContent>
          </Tabs.TabPane>
        </Tabs>
      </StretchToMobileLayoutEdge>

      <RepairerCommentModal
        visible={commentModalOpen}
        onCancel={handleCommentModalCancel}
        onOk={() => selectedStatusId && handleCommentModalOk(selectedStatusId)}
        comment={statusChangeComment}
        setComment={setStatusChangeComment}
        loading={edit.loading}
        required={isCommentRequired}
      />
    </MobilePageLayout>
  );
};

export default FailureViewRepairer;
