import { Col, Row, Typography } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "app/store";
import clsx from "clsx";
import BrandImage from "components/ui/BrandImage";
import CardContent from "components/ui/CardContent";
import CustomModal from "components/ui/CustomModal";
import useConfigurationQuery from "hooks/queries/useConfigurationQuery";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./AboutPage.module.scss";

const AboutPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useConfigurationQuery();
  const { aboutModalVisible, versions } = useSelector((state) => state.common);

  useEffect(() => {
    if (aboutModalVisible) {
      dispatch.common.getVersion();
    }
  }, [aboutModalVisible, dispatch.common]);

  const handleCloseModal = () => {
    dispatch.common.setAboutModalVisible(false);
  };

  return (
    <CustomModal
      className={styles.wrapper}
      size="middle"
      visible={aboutModalVisible}
      title={<Row>{t("pageTitle.about")}</Row>}
      okText={t("common.ok")}
      onCancel={handleCloseModal}
      onOk={handleCloseModal}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <Row>
        <Col span={12}>
          <BrandImage
            subPath="fortana-logo.svg"
            className={styles.companyImage}
          />
        </Col>
        <Col span={12} className={styles.brand}>
          <BrandImage
            subPath="mobiliti-logo.svg"
            className={styles.brandImage}
          />
        </Col>
      </Row>

      <Row justify="end">
        <Col>
          <Typography.Title level={5}>
            {query?.data?.applicationName}
          </Typography.Title>
        </Col>
      </Row>

      <Row>
        <Col span={12} className={styles.version}>
          <Typography.Title level={5}>{`${t("about.version")}: ${
            versions?.mainVersion
          }`}</Typography.Title>
        </Col>
        <Col span={12} className={styles.version}>
          <Typography.Link underline target="_blank" href="/versionhistory">
            {t("about.versionHistory")}
          </Typography.Link>
        </Col>
      </Row>

      <TextArea
        rows={6}
        value={`Frontend: ${process.env.REACT_APP_BUILD_NUMBER}
${versions?.version}
        `}
      />

      <Typography.Title level={5} className={styles.headerTitle}>
        {t("about.manufacturer")}
      </Typography.Title>

      <Row>
        <Col span={8}>
          <CardContent className={clsx(styles.cardContent, styles.firstCard)}>
            <Typography.Paragraph strong>
              {t("common.mvmi")}
            </Typography.Paragraph>
            <Typography.Paragraph className={styles.paragraph}>
              {t("common.mvmiAddress")}
            </Typography.Paragraph>

            <Typography.Link underline>
              <a href="mailto:info@mvmi.hu">{t("common.mvmiInfoEmail")}</a>
            </Typography.Link>

            <Typography.Paragraph>
              {t("common.mvmiSupportPhone")}
            </Typography.Paragraph>
          </CardContent>
        </Col>

        <Col span={8}>
          <CardContent className={styles.cardContent}>
            <Typography.Paragraph strong>
              {t("common.mvmiSupport")}
            </Typography.Paragraph>

            <Typography.Link underline>
              <a href="mailto:support@mvmi.hu">
                {t("common.mvmiSupportEmail")}
              </a>
            </Typography.Link>

            <Typography.Paragraph>{t("common.mvmiPhone")}</Typography.Paragraph>
          </CardContent>
        </Col>

        <Col span={8}>
          <CardContent className={clsx(styles.cardContent, styles.lastCard)}>
            <BrandImage subPath="mvmi-logo.svg" className={styles.mvmiImage} />
          </CardContent>
        </Col>
      </Row>
    </CustomModal>
  );
};

export default AboutPage;
