/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContractorExcelVm,
    ContractorExcelVmFromJSON,
    ContractorExcelVmToJSON,
    ContractorListItemVmPaginatedSearchResponse,
    ContractorListItemVmPaginatedSearchResponseFromJSON,
    ContractorListItemVmPaginatedSearchResponseToJSON,
    ContractorVm,
    ContractorVmFromJSON,
    ContractorVmToJSON,
    CreateContractorCommand,
    CreateContractorCommandFromJSON,
    CreateContractorCommandToJSON,
    DeviceVm,
    DeviceVmFromJSON,
    DeviceVmToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllContractorsExcelQuery,
    GetAllContractorsExcelQueryFromJSON,
    GetAllContractorsExcelQueryToJSON,
    GetAllContractorsQuery,
    GetAllContractorsQueryFromJSON,
    GetAllContractorsQueryToJSON,
    GetContractorsBasedOnDeviceIdsQuery,
    GetContractorsBasedOnDeviceIdsQueryFromJSON,
    GetContractorsBasedOnDeviceIdsQueryToJSON,
    SpecialPlaceVm,
    SpecialPlaceVmFromJSON,
    SpecialPlaceVmToJSON,
    UpdateContractorCommand,
    UpdateContractorCommandFromJSON,
    UpdateContractorCommandToJSON,
} from '../models';

export interface ApiContractorsExportPostRequest {
    getAllContractorsExcelQuery?: GetAllContractorsExcelQuery;
}

export interface ApiContractorsGetAllPostRequest {
    getAllContractorsQuery?: GetAllContractorsQuery;
}

export interface ApiContractorsGetContractorDevicesidGetRequest {
    id: string;
}

export interface ApiContractorsGetContractorSpecialPlacesidGetRequest {
    id: string;
}

export interface ApiContractorsGetContractorsBasedOnDeviceIdsPostRequest {
    getContractorsBasedOnDeviceIdsQuery?: GetContractorsBasedOnDeviceIdsQuery;
}

export interface ApiContractorsIdDeleteRequest {
    id: string;
}

export interface ApiContractorsIdGetRequest {
    id: string;
}

export interface ApiContractorsIdPutRequest {
    id: string;
    updateContractorCommand?: UpdateContractorCommand;
}

export interface ApiContractorsPostRequest {
    createContractorCommand?: CreateContractorCommand;
}

/**
 * 
 */
export class ContractorsApi extends runtime.BaseAPI {

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of contractors as Excel file
     */
    async apiContractorsExportPostRaw(requestParameters: ApiContractorsExportPostRequest): Promise<runtime.ApiResponse<ContractorExcelVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllContractorsExcelQueryToJSON(requestParameters.getAllContractorsExcelQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractorExcelVmFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of contractors as Excel file
     */
    async apiContractorsExportPost(requestParameters: ApiContractorsExportPostRequest): Promise<ContractorExcelVm> {
        const response = await this.apiContractorsExportPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of contractors
     */
    async apiContractorsGetAllPostRaw(requestParameters: ApiContractorsGetAllPostRequest): Promise<runtime.ApiResponse<ContractorListItemVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllContractorsQueryToJSON(requestParameters.getAllContractorsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractorListItemVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of contractors
     */
    async apiContractorsGetAllPost(requestParameters: ApiContractorsGetAllPostRequest): Promise<ContractorListItemVmPaginatedSearchResponse> {
        const response = await this.apiContractorsGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * List of Devices of a specific Contractor
     */
    async apiContractorsGetContractorDevicesidGetRaw(requestParameters: ApiContractorsGetContractorDevicesidGetRequest): Promise<runtime.ApiResponse<Array<DeviceVm>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiContractorsGetContractorDevicesidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors/GetContractorDevices{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceVmFromJSON));
    }

    /**
     * List of Devices of a specific Contractor
     */
    async apiContractorsGetContractorDevicesidGet(requestParameters: ApiContractorsGetContractorDevicesidGetRequest): Promise<Array<DeviceVm>> {
        const response = await this.apiContractorsGetContractorDevicesidGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * List of Special Places of a specific Contractor
     */
    async apiContractorsGetContractorSpecialPlacesidGetRaw(requestParameters: ApiContractorsGetContractorSpecialPlacesidGetRequest): Promise<runtime.ApiResponse<Array<SpecialPlaceVm>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiContractorsGetContractorSpecialPlacesidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors/GetContractorSpecialPlaces{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialPlaceVmFromJSON));
    }

    /**
     * List of Special Places of a specific Contractor
     */
    async apiContractorsGetContractorSpecialPlacesidGet(requestParameters: ApiContractorsGetContractorSpecialPlacesidGetRequest): Promise<Array<SpecialPlaceVm>> {
        const response = await this.apiContractorsGetContractorSpecialPlacesidGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of contractors based on deviceIds
     */
    async apiContractorsGetContractorsBasedOnDeviceIdsPostRaw(requestParameters: ApiContractorsGetContractorsBasedOnDeviceIdsPostRequest): Promise<runtime.ApiResponse<Array<ContractorVm>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors/GetContractorsBasedOnDeviceIds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetContractorsBasedOnDeviceIdsQueryToJSON(requestParameters.getContractorsBasedOnDeviceIdsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractorVmFromJSON));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of contractors based on deviceIds
     */
    async apiContractorsGetContractorsBasedOnDeviceIdsPost(requestParameters: ApiContractorsGetContractorsBasedOnDeviceIdsPostRequest): Promise<Array<ContractorVm>> {
        const response = await this.apiContractorsGetContractorsBasedOnDeviceIdsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the contractor signed with the id.
     */
    async apiContractorsIdDeleteRaw(requestParameters: ApiContractorsIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiContractorsIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the contractor signed with the id.
     */
    async apiContractorsIdDelete(requestParameters: ApiContractorsIdDeleteRequest): Promise<void> {
        await this.apiContractorsIdDeleteRaw(requestParameters);
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiContractorsIdGetRaw(requestParameters: ApiContractorsIdGetRequest): Promise<runtime.ApiResponse<ContractorVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiContractorsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractorVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiContractorsIdGet(requestParameters: ApiContractorsIdGetRequest): Promise<ContractorVm> {
        const response = await this.apiContractorsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a contractor
     */
    async apiContractorsIdPutRaw(requestParameters: ApiContractorsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiContractorsIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContractorCommandToJSON(requestParameters.updateContractorCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a contractor
     */
    async apiContractorsIdPut(requestParameters: ApiContractorsIdPutRequest): Promise<void> {
        await this.apiContractorsIdPutRaw(requestParameters);
    }

    /**
     * Creates a contractor
     */
    async apiContractorsPostRaw(requestParameters: ApiContractorsPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Contractors`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContractorCommandToJSON(requestParameters.createContractorCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a contractor
     */
    async apiContractorsPost(requestParameters: ApiContractorsPostRequest): Promise<string> {
        const response = await this.apiContractorsPostRaw(requestParameters);
        return await response.value();
    }

}
