/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressVm,
    AddressVmFromJSON,
    AddressVmFromJSONTyped,
    AddressVmToJSON,
    ContactPersonVm,
    ContactPersonVmFromJSON,
    ContactPersonVmFromJSONTyped,
    ContactPersonVmToJSON,
    DeviceVm,
    DeviceVmFromJSON,
    DeviceVmFromJSONTyped,
    DeviceVmToJSON,
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    DocumentMetadataVm,
    DocumentMetadataVmFromJSON,
    DocumentMetadataVmFromJSONTyped,
    DocumentMetadataVmToJSON,
    NameVm,
    NameVmFromJSON,
    NameVmFromJSONTyped,
    NameVmToJSON,
    PhoneVm,
    PhoneVmFromJSON,
    PhoneVmFromJSONTyped,
    PhoneVmToJSON,
    SpecialPlaceVm,
    SpecialPlaceVmFromJSON,
    SpecialPlaceVmFromJSONTyped,
    SpecialPlaceVmToJSON,
    TownVm,
    TownVmFromJSON,
    TownVmFromJSONTyped,
    TownVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface ContractorVm
 */
export interface ContractorVm {
    /**
     * 
     * @type {string}
     * @memberof ContractorVm
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ContractorVm
     */
    name?: string | null;
    /**
     * 
     * @type {AddressVm}
     * @memberof ContractorVm
     */
    headquarterAddress?: AddressVm;
    /**
     * 
     * @type {AddressVm}
     * @memberof ContractorVm
     */
    postalAddress?: AddressVm;
    /**
     * 
     * @type {string}
     * @memberof ContractorVm
     */
    email?: string | null;
    /**
     * 
     * @type {PhoneVm}
     * @memberof ContractorVm
     */
    phone?: PhoneVm;
    /**
     * 
     * @type {string}
     * @memberof ContractorVm
     */
    taxNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContractorVm
     */
    companyRegisterNumber?: string | null;
    /**
     * 
     * @type {Array<ContactPersonVm>}
     * @memberof ContractorVm
     */
    contacts?: Array<ContactPersonVm> | null;
    /**
     * 
     * @type {Array<TownVm>}
     * @memberof ContractorVm
     */
    towns?: Array<TownVm> | null;
    /**
     * 
     * @type {Array<DocumentMetadataVm>}
     * @memberof ContractorVm
     */
    documents?: Array<DocumentMetadataVm> | null;
    /**
     * 
     * @type {Array<SpecialPlaceVm>}
     * @memberof ContractorVm
     */
    specialPlaces?: Array<SpecialPlaceVm> | null;
    /**
     * 
     * @type {Array<DeviceVm>}
     * @memberof ContractorVm
     */
    devices?: Array<DeviceVm> | null;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof ContractorVm
     */
    status?: DictionaryVm;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorVm
     */
    isInvited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorVm
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorVm
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {NameVm}
     * @memberof ContractorVm
     */
    creator?: NameVm;
    /**
     * 
     * @type {NameVm}
     * @memberof ContractorVm
     */
    modifier?: NameVm;
    /**
     * 
     * @type {Date}
     * @memberof ContractorVm
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof ContractorVm
     */
    createdDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ContractorVm
     */
    repairmanEmailOfTaskAssignment?: boolean;
}

export function ContractorVmFromJSON(json: any): ContractorVm {
    return ContractorVmFromJSONTyped(json, false);
}

export function ContractorVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContractorVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'headquarterAddress': !exists(json, 'headquarterAddress') ? undefined : AddressVmFromJSON(json['headquarterAddress']),
        'postalAddress': !exists(json, 'postalAddress') ? undefined : AddressVmFromJSON(json['postalAddress']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : PhoneVmFromJSON(json['phone']),
        'taxNumber': !exists(json, 'taxNumber') ? undefined : json['taxNumber'],
        'companyRegisterNumber': !exists(json, 'companyRegisterNumber') ? undefined : json['companyRegisterNumber'],
        'contacts': !exists(json, 'contacts') ? undefined : (json['contacts'] === null ? null : (json['contacts'] as Array<any>).map(ContactPersonVmFromJSON)),
        'towns': !exists(json, 'towns') ? undefined : (json['towns'] === null ? null : (json['towns'] as Array<any>).map(TownVmFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(DocumentMetadataVmFromJSON)),
        'specialPlaces': !exists(json, 'specialPlaces') ? undefined : (json['specialPlaces'] === null ? null : (json['specialPlaces'] as Array<any>).map(SpecialPlaceVmFromJSON)),
        'devices': !exists(json, 'devices') ? undefined : (json['devices'] === null ? null : (json['devices'] as Array<any>).map(DeviceVmFromJSON)),
        'status': !exists(json, 'status') ? undefined : DictionaryVmFromJSON(json['status']),
        'isInvited': !exists(json, 'isInvited') ? undefined : json['isInvited'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'creator': !exists(json, 'creator') ? undefined : NameVmFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : NameVmFromJSON(json['modifier']),
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'repairmanEmailOfTaskAssignment': !exists(json, 'repairmanEmailOfTaskAssignment') ? undefined : json['repairmanEmailOfTaskAssignment'],
    };
}

export function ContractorVmToJSON(value?: ContractorVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'headquarterAddress': AddressVmToJSON(value.headquarterAddress),
        'postalAddress': AddressVmToJSON(value.postalAddress),
        'email': value.email,
        'phone': PhoneVmToJSON(value.phone),
        'taxNumber': value.taxNumber,
        'companyRegisterNumber': value.companyRegisterNumber,
        'contacts': value.contacts === undefined ? undefined : (value.contacts === null ? null : (value.contacts as Array<any>).map(ContactPersonVmToJSON)),
        'towns': value.towns === undefined ? undefined : (value.towns === null ? null : (value.towns as Array<any>).map(TownVmToJSON)),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(DocumentMetadataVmToJSON)),
        'specialPlaces': value.specialPlaces === undefined ? undefined : (value.specialPlaces === null ? null : (value.specialPlaces as Array<any>).map(SpecialPlaceVmToJSON)),
        'devices': value.devices === undefined ? undefined : (value.devices === null ? null : (value.devices as Array<any>).map(DeviceVmToJSON)),
        'status': DictionaryVmToJSON(value.status),
        'isInvited': value.isInvited,
        'isActive': value.isActive,
        'isDeleted': value.isDeleted,
        'creator': NameVmToJSON(value.creator),
        'modifier': NameVmToJSON(value.modifier),
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'repairmanEmailOfTaskAssignment': value.repairmanEmailOfTaskAssignment,
    };
}


