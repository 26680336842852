import { mdiDelete, mdiEye } from "@mdi/js";
import { Modal, ModalFuncProps, Space } from "antd";
import { useDispatch, useSelector } from "app/store";
import IconButton from "components/ui/IconButton";
import useTable from "hooks/useTable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import {
  ColumnState,
  ExtendedColumnType,
} from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";

import TableHeaderOptions from "components/table/TableHeaderOptions";
import { TownVm } from "api/generated/lumen";
import TownCreateModal from "../create/TownCreateModal";
import useTownsListColumnTitles from "./useTownsListColumnTitles";

const { confirm } = Modal;

const TownsList: React.FC = () => {
  const { listParams, list } = useSelector((state) => state.townList);
  const { fetchList, generateExcel } = useSelector(
    (state) => state.loading.effects.townList
  );

  const [createModalOpen, setCreateModalOpen] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { columnTitles } = useTownsListColumnTitles();

  const columnParams = useMemo<ColumnConfigParams<TownVm>[]>(
    () =>
      [
        {
          key: "name",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "municipalityName",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "municipalityAddress.zip",
          dataIndex: ["municipalityAddress", "zip"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "municipalityAddress.city",
          dataIndex: ["municipalityAddress", "city"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
          width: "232px",
        },
        {
          key: "municipalityAddress.street",
          dataIndex: ["municipalityAddress", "street"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "municipalityAddress.number",
          dataIndex: ["municipalityAddress", "number"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "email",
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
      ].map((item, index) => ({
        ...item,
        title: columnTitles[index],
      })),
    [columnTitles]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      dispatch.townCreate.setEditingItemId(id);

      setCreateModalOpen(true);
    },
    [dispatch.townCreate]
  );

  const handleDeleteButtonClick = useCallback(
    (id?: string) => {
      const confirmProps: ModalFuncProps = {
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.townList.inactivate(id);

          return dispatch.townList.fetchList(listParams);
        },
      };

      confirm(confirmProps);
    },
    [dispatch.townList, listParams, t]
  );

  const handleNewButtonClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleExportButtonClick = useCallback(() => {
    dispatch.townList.generateExcel(columnTitles);
  }, [columnTitles, dispatch.townList]);

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<TownVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDeleteButtonClick, handleViewButtonClick, t]
  );

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.townList.fetchList(requestParams),
    [dispatch.townList]
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    actionColumnParams,
    listParams,
    list,
    columnStorageName: ColumnStorageName.TOWNS,
    headerOptions: (
      <TableHeaderOptions
        onNewButtonClick={handleNewButtonClick}
        onExportButtonClick={handleExportButtonClick}
        exportLoading={generateExcel.loading}
      />
    ),
    resetListParams: dispatch.townList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.townList.reset,
  });

  return (
    <>
      <CustomTable<TownVm>
        {...customTableProps}
        scrollX={1320}
        selectable={false}
      />

      <TownCreateModal
        visible={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        fetchDataParams={listParams}
      />
    </>
  );
};

export default TownsList;
