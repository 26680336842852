/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FailureCreatorType,
    FailureCreatorTypeFromJSON,
    FailureCreatorTypeFromJSONTyped,
    FailureCreatorTypeToJSON,
    RolePermission,
    RolePermissionFromJSON,
    RolePermissionFromJSONTyped,
    RolePermissionToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    failureCreatorTypeId?: string | null;
    /**
     * 
     * @type {Array<RolePermission>}
     * @memberof Role
     */
    permissions?: Array<RolePermission> | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof Role
     */
    users?: Array<User> | null;
    /**
     * 
     * @type {User}
     * @memberof Role
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof Role
     */
    modifier?: User;
    /**
     * 
     * @type {FailureCreatorType}
     * @memberof Role
     */
    failureCreatorType?: FailureCreatorType;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Role
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Role
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    id?: string;
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'failureCreatorTypeId': !exists(json, 'failureCreatorTypeId') ? undefined : json['failureCreatorTypeId'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] === null ? null : (json['permissions'] as Array<any>).map(RolePermissionFromJSON)),
        'users': !exists(json, 'users') ? undefined : (json['users'] === null ? null : (json['users'] as Array<any>).map(UserFromJSON)),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'failureCreatorType': !exists(json, 'failureCreatorType') ? undefined : FailureCreatorTypeFromJSON(json['failureCreatorType']),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function RoleToJSON(value?: Role | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'failureCreatorTypeId': value.failureCreatorTypeId,
        'permissions': value.permissions === undefined ? undefined : (value.permissions === null ? null : (value.permissions as Array<any>).map(RolePermissionToJSON)),
        'users': value.users === undefined ? undefined : (value.users === null ? null : (value.users as Array<any>).map(UserToJSON)),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'failureCreatorType': FailureCreatorTypeToJSON(value.failureCreatorType),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'id': value.id,
    };
}


