import { mdiDelete, mdiEye } from "@mdi/js";
import { Modal, ModalFuncProps, Space, Tag } from "antd";
import { useDispatch, useSelector } from "app/store";
import IconButton from "components/ui/IconButton";
import useTable from "hooks/useTable";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import {
  ColumnState,
  ExtendedColumnType,
} from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";

import { DictionaryVm, FailureTypeVm } from "api/generated/lumen";
import TableHeaderOptions from "components/table/TableHeaderOptions";
import FailureTypesCreateModal from "../create/FailureTypesCreateModal";
import { dictionaryFilters } from "utils";
import useFailureTypesListColumnTitles from "./useFailureTypesListColumnTitles";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import useFeature from "hooks/useFeature";
import BooleanDisplay from "components/ui/BooleanDisplay";
import useDictionariesQuery from "hooks/queries/useDictionariesQuery";

const { confirm } = Modal;

const FailureTypesList: React.FC = () => {
  const { listParams, list } = useSelector((state) => state.failureTypesList);
  const failureTasksQuery = useDictionariesQuery({
    dictionaryGroupType: "LumenFailureTasks",
    dictionaryIsEditableInApp: true,
  });
  const { fetchList, generateExcel } = useSelector(
    (state) => state.loading.effects.failureTypesList
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const { isEnabled: isFailureTypeSpecialCaseEnabled } =
    useFeature("failureSpecialCase");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { columnTitles } = useFailureTypesListColumnTitles();

  const columnParams = useMemo<ColumnConfigParams<FailureTypeVm>[]>(
    () =>
      [
        {
          key: "name",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "tasks",
          sort: false,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.FILTER,
          filters: dictionaryFilters(failureTasksQuery.data || []),
          filterOptionsLoading: failureTasksQuery.isLoading,
          render: (value: DictionaryVm[] | null) => {
            return value?.length ? (
              value.map((item) => item.name).join(", ")
            ) : (
              <EmptyContent />
            );
          },
        },
        ...(isFailureTypeSpecialCaseEnabled
          ? [
              {
                key: "specialCase",
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.YES_NO,
                render: (value: boolean) => {
                  return (
                    <BooleanDisplay
                      value={value}
                      trueDisplay={<Tag color="success">{t("common.yes")}</Tag>}
                      falseDisplay={<Tag color="error">{t("common.no")}</Tag>}
                    />
                  );
                },
              },
            ]
          : []),
      ].map((item, index) => ({
        ...item,
        title: columnTitles[index],
      })),
    [
      columnTitles,
      failureTasksQuery.data,
      failureTasksQuery.isLoading,
      isFailureTypeSpecialCaseEnabled,
      t,
    ]
  );

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      dispatch.failureTypesCreate.setEditingFailureTypeId(id);

      setCreateModalOpen(true);
    },
    [dispatch.failureTypesCreate]
  );

  const handleDeleteButtonClick = useCallback(
    (id?: string) => {
      const confirmProps: ModalFuncProps = {
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.failureTypesList.deleteItem({ id });

          return dispatch.failureTypesList.fetchList(listParams);
        },
      };

      confirm(confirmProps);
    },
    [dispatch.failureTypesList, listParams, t]
  );

  const handleNewButtonClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleExportButtonClick = useCallback(() => {
    dispatch.failureTypesList.generateExcel(columnTitles);
  }, [columnTitles, dispatch.failureTypesList]);

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<FailureTypeVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDeleteButtonClick, handleViewButtonClick, t]
  );

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.failureTypesList.fetchList(requestParams),
    [dispatch.failureTypesList]
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    actionColumnParams,
    listParams,
    list,
    columnStorageName: ColumnStorageName.FAILURE_TYPES,
    headerOptions: (
      <TableHeaderOptions
        onNewButtonClick={handleNewButtonClick}
        onExportButtonClick={handleExportButtonClick}
        exportLoading={generateExcel.loading}
      />
    ),
    resetListParams: dispatch.failureTypesList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.failureTypesList.reset,
  });

  return (
    <>
      <CustomTable<FailureTypeVm>
        {...customTableProps}
        scrollX={800}
        selectable={false}
      />

      <FailureTypesCreateModal
        visible={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        fetchDataParams={listParams}
      />
    </>
  );
};

export default FailureTypesList;
