import { Blocker } from "react-router";
import { FormUtils } from "./useGrapeAntdForm";
import { useTranslation } from "react-i18next";
import { useDispatch } from "app/store";
import { useCallback, useEffect } from "react";
import { Modal } from "antd";

interface NavigationBlockerProps {
  blocker: Blocker;
  formUtils?: FormUtils<any>;
  onBeforeNavigation?: () => void;
  onCancel?: () => void;
}

const useNavigationBlocker = ({
  blocker,
  formUtils,
  onBeforeNavigation,
  onCancel,
}: NavigationBlockerProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBeforeNavigation = useCallback(() => {
    formUtils?.form.resetFields();
    onBeforeNavigation?.();
    blocker.proceed?.();
  }, [formUtils, blocker, onBeforeNavigation]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      Modal.confirm({
        title: t("common.navigationBlocker.title"),
        content: t("common.navigationBlocker.content"),
        okText: t("common.navigationBlocker.confirm"),
        cancelText: t("common.navigationBlocker.stayOnPage"),
        onOk: handleBeforeNavigation,
        onCancel: () => {
          blocker.reset?.();
          onCancel?.();
        },
        centered: true,
      });
    }
  }, [blocker, t, handleBeforeNavigation, formUtils, dispatch, onCancel]);
};

export default useNavigationBlocker;
