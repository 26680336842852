/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
} from '../models';

export interface ApiMaintenanceEmailtestToAddressGetRequest {
    toAddress: string;
}

export interface ApiMaintenanceStreetImportPostRequest {
    excelFile?: Blob;
}

/**
 * 
 */
export class MaintenanceApi extends runtime.BaseAPI {

    /**
     * Send an e-mail to check the functionality
     */
    async apiMaintenanceEmailtestToAddressGetRaw(requestParameters: ApiMaintenanceEmailtestToAddressGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.toAddress === null || requestParameters.toAddress === undefined) {
            throw new runtime.RequiredError('toAddress','Required parameter requestParameters.toAddress was null or undefined when calling apiMaintenanceEmailtestToAddressGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Maintenance/emailtest/{toAddress}`.replace(`{${"toAddress"}}`, encodeURIComponent(String(requestParameters.toAddress))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send an e-mail to check the functionality
     */
    async apiMaintenanceEmailtestToAddressGet(requestParameters: ApiMaintenanceEmailtestToAddressGetRequest): Promise<void> {
        await this.apiMaintenanceEmailtestToAddressGetRaw(requestParameters);
    }

    /**
     * Imports street list
     */
    async apiMaintenanceStreetImportPostRaw(requestParameters: ApiMaintenanceStreetImportPostRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.excelFile !== undefined) {
            formParams.append('ExcelFile', requestParameters.excelFile as any);
        }

        const response = await this.request({
            path: `/api/Maintenance/StreetImport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Imports street list
     */
    async apiMaintenanceStreetImportPost(requestParameters: ApiMaintenanceStreetImportPostRequest): Promise<number> {
        const response = await this.apiMaintenanceStreetImportPostRaw(requestParameters);
        return await response.value();
    }

}
