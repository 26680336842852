import { useDispatch } from "app/store";
import useAuth from "features/auth/useAuth";
import React, { useEffect } from "react";
import RepairersFailureList from "./repairer/RepairersFailureList";
import FailureTabs from "./tabs/FailureTabs";

const FailuresListHub: React.FC = () => {
  const dispatch = useDispatch();
  const { hasRole } = useAuth();
  const view = localStorage.getItem("fortana-view");

  useEffect(() => {
    return () => {
      dispatch.failureList.reset();
    };
  }, [dispatch.failureList]);

  return hasRole(["Repairman"]) ||
    (hasRole(["Contractor"]) && view === "repairman") ? (
    <RepairersFailureList />
  ) : (
    <FailureTabs />
  );
};

export default FailuresListHub;
