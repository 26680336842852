import { forwardRef, useContext } from "react";
import { Button, ButtonProps } from "antd";
import { GrapeAntdFormContext } from "./GrapeAntdForm";

interface GrapeAntdFormSubmitProps extends ButtonProps {
  onlySubmitValidForm?: boolean;
}

const GrapeAntdFormSubmit = forwardRef(
  (
    {
      children,
      disabled,
      onlySubmitValidForm = false,
      loading,
      ...props
    }: GrapeAntdFormSubmitProps,
    ref?: React.Ref<HTMLElement>
  ) => {
    const {
      submittable,
      loading: formContextLoading,
      fieldsAreTouched,
    } = useContext(GrapeAntdFormContext);

    return (
      <Button
        htmlType="submit"
        disabled={
          (onlySubmitValidForm && !submittable) || disabled || !fieldsAreTouched
        }
        loading={formContextLoading || loading}
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    );
  }
);

export default GrapeAntdFormSubmit;
