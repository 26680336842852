import { mdiAlarm, mdiAlert } from "@mdi/js";
import { Avatar, Button, Divider, Space } from "antd";
import { MaterialIcon } from "components/MaterialIcon";
import useAuth from "features/auth/useAuth";
import useFailureDeadlineInfoQuery from "hooks/queries/useFailureDeadlineInfoQuery";
import React from "react";
import { useTranslation } from "react-i18next";
import ProfileMenu from "./ProfileMenu";
import styles from "./Header.module.scss";
import { useDispatch } from "app/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "./Logo";

const Header: React.FC = () => {
  const { userInfo } = useAuth();
  const query = useFailureDeadlineInfoQuery();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasRole } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  return (
    <Space className={styles.headerWrapper}>
      <div className={styles.logoWrapper}>
        <Logo />
      </div>
      <Space size="small">
        <Space size="large">
          <Space>
            <Avatar
              size={32}
              icon={<MaterialIcon path={mdiAlarm} className={styles.icon} />}
              className={styles.nearDeadlineAvatar}
            />
            <div className={styles.labelWrapper}>
              <span className={styles.count}>
                {query.data?.nearDeadlineFailureCount ?? 0}
              </span>
              <span className={styles.label}>
                {t("failure.nearDeadlineFailureCount")}
              </span>
            </div>
          </Space>

          <Space>
            <Avatar
              size={32}
              icon={<MaterialIcon path={mdiAlert} className={styles.icon} />}
              className={styles.expiredDeadlineAvatar}
            />
            <div className={styles.labelWrapper}>
              <span className={styles.count}>
                {query.data?.expiredDeadlineFailureCount ?? 0}
              </span>
              <span className={styles.label}>
                {t("failure.expiredDeadlineFailureCount")}
              </span>
            </div>
          </Space>
        </Space>

        <Divider type="vertical" />
        {hasRole(["Contractor"]) && (
          <Button
            onClick={() => {
              dispatch.common.setDeviceLayoutType("mobile");
              localStorage.setItem("fortana-view", "repairman");
              navigate(
                id && location.pathname.includes("failures")
                  ? `/failures/${id}`
                  : `/failures`
              );
            }}
          >
            {t("common.view.repairmen")}
          </Button>
        )}

        <ProfileMenu userName={userInfo?.userName} />
      </Space>
    </Space>
  );
};

export default Header;
