/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserPermission
 */
export interface UserPermission {
    /**
     * 
     * @type {string}
     * @memberof UserPermission
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPermission
     */
    permission?: string | null;
    /**
     * 
     * @type {User}
     * @memberof UserPermission
     */
    user?: User;
}

export function UserPermissionFromJSON(json: any): UserPermission {
    return UserPermissionFromJSONTyped(json, false);
}

export function UserPermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPermission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'permission': !exists(json, 'permission') ? undefined : json['permission'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function UserPermissionToJSON(value?: UserPermission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'permission': value.permission,
        'user': UserToJSON(value.user),
    };
}


