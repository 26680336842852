import { useSelector } from "app/store";
import AboutPage from "components/AboutPage";
import BlankLayout from "components/layout/BlankLayout";
import DefaultLayout from "components/layout/DefaultLayout";
import VersionHistoryPage from "components/layout/private-layout/components/VersionHistory";
import PrivateLayout from "components/layout/private-layout/PrivateLayout";
import ForgottenPasswordSetNewPassword from "features/auth/ForgottenPassword/ForgottenPasswordSetNewPassword";
import ForgottenPasswordSetNewPasswordFailed from "features/auth/ForgottenPassword/ForgottenPasswordSetNewPasswordFailed";
import ForgottenPasswordSetNewPasswordSuccess from "features/auth/ForgottenPassword/ForgottenPasswordSetNewPasswordSuccess";
import RequestForgottenPasswordEmail from "features/auth/ForgottenPassword/RequestForgottenPasswordEmail";
import RequestForgottenPasswordEmailSuccess from "features/auth/ForgottenPassword/RequestForgottenPasswordEmailSuccess";
import Login from "features/auth/Login/Login";
import Register from "features/auth/Register/Register";
import RegisterFailed from "features/auth/Register/RegisterFailed";
import RegisterSuccess from "features/auth/Register/RegisterSuccess";
import ContractorsList from "features/contractors/list/ContractorsList";
import ContractorView from "features/contractors/view/ContractorView";
import Error404 from "features/error/Error404";
import FailuresListHub from "features/failures/list/FailuresListHub";
import FailureReport from "features/failures/report/FailureReport";
import FailureViewHub from "features/failures/view/FailureViewHub";
import InventoryList from "features/inventory/list/InventoryList";
import MaintainedDevicesList from "features/maintained-devices/list/MaintainedDevicesList";
import MaintainedDevicesView from "features/maintained-devices/view/MaintainedDevicesView";
import ManageDictionaries from "features/manage-dictionaries/ManageDictionaries";
import RepairmenList from "features/repairmen/list/RepairmenList";
import RepairmenView from "features/repairmen/view/RepairmenView";
import ReportTabs from "features/report/tabs/ReportTabs";
import Settings from "features/settings/Settings";
import SpecialPlacesView from "features/special-places/view/SpecialPlacesView";
import useFeature from "hooks/useFeature";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";

export const RouterView: React.FC = () => {
  const { t } = useTranslation();
  const { deviceLayoutType } = useSelector((state) => state.common);

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const routes: RouteObject[] = [
    {
      path: "",
      element: <PrivateLayout />,
      children: [
        { path: "", element: <Navigate to="/failures" replace /> },
        {
          path: "failures",
          children: [
            {
              path: "",
              element: (
                <PrivateRoute title={t("pageTitle.failures")}>
                  <FailuresListHub />
                </PrivateRoute>
              ),
            },
            {
              path: ":id",
              element: (
                <PrivateRoute
                  title={t("pageTitle.failureView")}
                  roles={[
                    "LumenAdmin",
                    "Root_SUPER_ADMIN",
                    "Contractor",
                    "Repairman",
                  ]}
                >
                  <FailureViewHub />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: "contractors",
          children: [
            {
              path: "",
              element: (
                <PrivateRoute
                  title={t("pageTitle.contractors")}
                  roles={["LumenAdmin", "Root_SUPER_ADMIN"]}
                >
                  <ContractorsList />
                </PrivateRoute>
              ),
            },
            {
              path: ":id",
              element: (
                <PrivateRoute
                  title={t("pageTitle.contractorView")}
                  roles={["LumenAdmin", "Root_SUPER_ADMIN"]}
                >
                  <ContractorView />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: "repairmen",
          children: [
            {
              path: "",
              element: (
                <PrivateRoute
                  title={t("pageTitle.repairmen")}
                  roles={["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"]}
                >
                  <RepairmenList />
                </PrivateRoute>
              ),
            },
            {
              path: ":id",
              element: (
                <PrivateRoute
                  title={t("pageTitle.repairmenView")}
                  roles={["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"]}
                >
                  <RepairmenView />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: "inventory",
          element: (
            <PrivateRoute
              title={t("pageTitle.inventory")}
              roles={["LumenAdmin", "Root_SUPER_ADMIN"]}
            >
              <InventoryList />
            </PrivateRoute>
          ),
        },
        {
          path: "report",
          element: (
            <PrivateRoute
              title={t("pageTitle.report")}
              roles={["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"]}
            >
              <ReportTabs />
            </PrivateRoute>
          ),
        },
        {
          path: "settings",
          element: (
            <PrivateRoute
              title={t("pageTitle.settings")}
              roles={["LumenAdmin", "Root_SUPER_ADMIN"]}
            >
              <Settings />
            </PrivateRoute>
          ),
        },
        {
          path: "dictionaries",
          children: [
            {
              path: "",
              element: (
                <PrivateRoute
                  title={t("pageTitle.dictionaries")}
                  roles={["LumenAdmin", "Root_SUPER_ADMIN"]}
                >
                  <ManageDictionaries />
                </PrivateRoute>
              ),
            },
            {
              path: "special-places/:id",
              element: (
                <PrivateRoute
                  title={t("pageTitle.specialPlacesView")}
                  roles={["LumenAdmin", "Root_SUPER_ADMIN"]}
                >
                  <SpecialPlacesView />
                </PrivateRoute>
              ),
            },
          ],
        },
        ...(isMaintainedDevicesFeatureEnabled
          ? [
              {
                path: "maintained-devices",
                children: [
                  {
                    path: "",
                    element: (
                      <PrivateRoute
                        title={t("pageTitle.maintainedDevices")}
                        roles={["LumenAdmin", "Root_SUPER_ADMIN"]}
                      >
                        <MaintainedDevicesList />
                      </PrivateRoute>
                    ),
                  },
                  {
                    path: ":id",
                    element: (
                      <PrivateRoute
                        title={t("pageTitle.maintainedDevices")}
                        roles={["LumenAdmin", "Root_SUPER_ADMIN"]}
                      >
                        <MaintainedDevicesView />
                      </PrivateRoute>
                    ),
                  },
                ],
              },
            ]
          : []),
      ],
    },
    {
      path: "",
      element: <DefaultLayout />,
      children: [
        {
          path: "login",
          element: (
            <PublicRoute onlyGuest title={t("pageTitle.login")}>
              <Login />
            </PublicRoute>
          ),
        },
        {
          path: "versionhistory",
          element: (
            <PublicRoute title={t("pageTitle.versionHistory")}>
              <VersionHistoryPage />
            </PublicRoute>
          ),
        },
        {
          path: "register/:userId",
          children: [
            {
              path: "",
              element: (
                <PublicRoute title={t("pageTitle.register")}>
                  <Register />
                </PublicRoute>
              ),
            },
            {
              path: "success",
              element: (
                <PublicRoute onlyGuest title={t("pageTitle.registerSuccess")}>
                  <RegisterSuccess />
                </PublicRoute>
              ),
            },
            {
              path: "failed",
              element: (
                <PublicRoute onlyGuest title={t("pageTitle.registerFailed")}>
                  <RegisterFailed />
                </PublicRoute>
              ),
            },
          ],
        },
        {
          path: "forgotten-password",
          children: [
            {
              path: "request",
              children: [
                {
                  path: "",
                  element: (
                    <PublicRoute
                      onlyGuest
                      title={t("pageTitle.requestForgottenPasswordEmail")}
                    >
                      <RequestForgottenPasswordEmail />
                    </PublicRoute>
                  ),
                },
                {
                  path: "success",
                  element: (
                    <PublicRoute
                      title={t(
                        "pageTitle.requestForgottenPasswordEmailSuccess"
                      )}
                    >
                      <RequestForgottenPasswordEmailSuccess />
                    </PublicRoute>
                  ),
                },
              ],
            },
            {
              path: "set-password/:userId",
              children: [
                {
                  path: "",
                  element: (
                    <PublicRoute
                      title={t("pageTitle.forgottenPasswordSetNewPassword")}
                    >
                      <ForgottenPasswordSetNewPassword />
                    </PublicRoute>
                  ),
                },
                {
                  path: "success",
                  element: (
                    <PublicRoute
                      title={t(
                        "pageTitle.forgottenPasswordSetNewPasswordSuccess"
                      )}
                    >
                      <ForgottenPasswordSetNewPasswordSuccess />
                    </PublicRoute>
                  ),
                },
                {
                  path: "failed",
                  element: (
                    <PublicRoute
                      title={t(
                        "pageTitle.forgottenPasswordSetNewPasswordFailed"
                      )}
                    >
                      <ForgottenPasswordSetNewPasswordFailed />
                    </PublicRoute>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "failure-report", // TODO: ezt feature switchelhetővé kell tenni, lesz rá ticket
          element: (
            <PublicRoute title={t("pageTitle.reportFailure")}>
              <FailureReport />
            </PublicRoute>
          ),
        },
      ],
    },
    {
      path: "",
      element: <BlankLayout />,
      children: [{ path: "*", element: <Error404 /> }],
    },
  ];

  return deviceLayoutType ? (
    <>
      <RouterProvider router={createBrowserRouter(routes)} />
      <AboutPage />
    </>
  ) : null;
};
