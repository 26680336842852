import { createModel } from "@rematch/core";
import { api } from "api";
import { VersionDto } from "api/generated/lumen";
import { RootModel } from "app/store";
import { Breadcrumb, DeviceLayoutType } from "models/common";

interface CommonState {
  currentPageTitle?: string;
  deviceLayoutType?: DeviceLayoutType;
  breadCrumbs: Breadcrumb[];
  aboutModalVisible: boolean;
  versions: VersionDto | null;
  versionHistory: string | null;
}

const initialState: CommonState = {
  currentPageTitle: undefined,
  deviceLayoutType: undefined,
  breadCrumbs: [],
  aboutModalVisible: false,
  versions: null,
  versionHistory: null,
};

export const common = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setCurrentPageTitle(state, data: string | undefined) {
      state.currentPageTitle = data;
    },
    setDeviceLayoutType(state, data: DeviceLayoutType) {
      state.deviceLayoutType = data;
    },
    setBreadcrumbs(state, data: Breadcrumb[]) {
      state.breadCrumbs = data;
    },
    setAboutModalVisible(state, visible: boolean) {
      state.aboutModalVisible = visible;
    },
    setVersion(state, data: VersionDto) {
      state.versions = data;
    },
    setVersionHistory(state, data: string) {
      state.versionHistory = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { common } = dispatch;

    return {
      async getVersion(): Promise<void> {
        const data = await api.lumen.information.apiInformationVersionGet();
        dispatch(common.setVersion(data));
      },

      async getVersionHistory(): Promise<void> {
        const data =
          await api.lumen.information.apiInformationVersionHistoryGet();
        dispatch(common.setVersionHistory(data));
      },
    };
  },
});
