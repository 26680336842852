import { mdiInformationOutline, mdiLogoutVariant } from "@mdi/js";
import { List, Tag, Typography } from "antd";
import { useDispatch } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import useAuth from "features/auth/useAuth";
import useConfigurationQuery from "hooks/queries/useConfigurationQuery";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  SidebarDivider,
  SidebarMenu,
  SideMenuOptionProps,
} from "widgets/layout/sidebar-header-layout";
import styles from "./SidebarFooterMenu.module.scss";

const SidebarFooterMenu: React.FC = () => {
  const { t } = useTranslation();
  const { hasRole } = useAuth();
  const dispatch = useDispatch();
  const query = useConfigurationQuery();

  const footerOptions = useMemo<SideMenuOptionProps[]>(
    () => [
      {
        label: t("menu.logout"),
        icon: <MaterialIcon path={mdiLogoutVariant} />,
        onClick: () => dispatch.auth.logout(),
      },
    ],
    [dispatch.auth, t]
  );

  return (
    <>
      <List className={styles.about}>
        <List.Item>
          <MaterialIcon
            path={mdiInformationOutline}
            size={0.75}
            color="white"
          />
          <Typography.Text
            onClick={() => dispatch.common.setAboutModalVisible(true)}
          >
            {t("menu.about")}
          </Typography.Text>
          {query?.data?.newVersion && (
            <Tag className={styles.newTag}>{t("common.new")}</Tag>
          )}
          ,
        </List.Item>
      </List>

      <SidebarDivider />

      <SidebarMenu
        options={footerOptions}
        menuItemClassName={styles.footerMenuItem}
        hasRole={hasRole}
      />
    </>
  );
};

export default SidebarFooterMenu;
