/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    Contractor,
    ContractorFromJSON,
    ContractorFromJSONTyped,
    ContractorToJSON,
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    Dictionary,
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
    FailureCreatorType,
    FailureCreatorTypeFromJSON,
    FailureCreatorTypeFromJSONTyped,
    FailureCreatorTypeToJSON,
    FailureStatus,
    FailureStatusFromJSON,
    FailureStatusFromJSONTyped,
    FailureStatusToJSON,
    FailureType,
    FailureTypeFromJSON,
    FailureTypeFromJSONTyped,
    FailureTypeToJSON,
    Phone,
    PhoneFromJSON,
    PhoneFromJSONTyped,
    PhoneToJSON,
    Repairman,
    RepairmanFromJSON,
    RepairmanFromJSONTyped,
    RepairmanToJSON,
    Town,
    TownFromJSON,
    TownFromJSONTyped,
    TownToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    Workbook,
    WorkbookFromJSON,
    WorkbookFromJSONTyped,
    WorkbookToJSON,
} from './';

/**
 * 
 * @export
 * @interface Failure
 */
export interface Failure {
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    identifier?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Failure
     */
    reportedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Failure
     */
    deadline?: Date;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    optimalDeadlineDaysId?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Failure
     */
    closedAt?: Date | null;
    /**
     * 
     * @type {Address}
     * @memberof Failure
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    typeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    expanseId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    description1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    natureId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    description2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    statusId?: string;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    stateChangeReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    parentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    contractorId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    creatorTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    notifierFamilyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    notifierMiddlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    notifierForename?: string | null;
    /**
     * 
     * @type {Phone}
     * @memberof Failure
     */
    notifierPhone?: Phone;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    notifierEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    comment?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Failure
     */
    isAnonymized?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Failure
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Failure
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    townId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Failure
     */
    acceptedByContractor?: boolean;
    /**
     * 
     * @type {FailureType}
     * @memberof Failure
     */
    type?: FailureType;
    /**
     * 
     * @type {Dictionary}
     * @memberof Failure
     */
    expanse?: Dictionary;
    /**
     * 
     * @type {Dictionary}
     * @memberof Failure
     */
    nature?: Dictionary;
    /**
     * 
     * @type {Dictionary}
     * @memberof Failure
     */
    optimalDeadlineDays?: Dictionary;
    /**
     * 
     * @type {FailureStatus}
     * @memberof Failure
     */
    status?: FailureStatus;
    /**
     * 
     * @type {Failure}
     * @memberof Failure
     */
    parent?: Failure;
    /**
     * 
     * @type {Contractor}
     * @memberof Failure
     */
    contractor?: Contractor;
    /**
     * 
     * @type {Device}
     * @memberof Failure
     */
    device?: Device;
    /**
     * 
     * @type {FailureCreatorType}
     * @memberof Failure
     */
    creatorType?: FailureCreatorType;
    /**
     * 
     * @type {User}
     * @memberof Failure
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof Failure
     */
    modifier?: User;
    /**
     * 
     * @type {Town}
     * @memberof Failure
     */
    town?: Town;
    /**
     * 
     * @type {Array<Failure>}
     * @memberof Failure
     */
    attachedFailures?: Array<Failure> | null;
    /**
     * 
     * @type {Array<Repairman>}
     * @memberof Failure
     */
    repairmen?: Array<Repairman> | null;
    /**
     * 
     * @type {Workbook}
     * @memberof Failure
     */
    workbook?: Workbook;
    /**
     * 
     * @type {string}
     * @memberof Failure
     */
    id?: string;
}

export function FailureFromJSON(json: any): Failure {
    return FailureFromJSONTyped(json, false);
}

export function FailureFromJSONTyped(json: any, ignoreDiscriminator: boolean): Failure {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'reportedAt': !exists(json, 'reportedAt') ? undefined : (new Date(json['reportedAt'])),
        'deadline': !exists(json, 'deadline') ? undefined : (new Date(json['deadline'])),
        'optimalDeadlineDaysId': !exists(json, 'optimalDeadlineDaysId') ? undefined : json['optimalDeadlineDaysId'],
        'closedAt': !exists(json, 'closedAt') ? undefined : (json['closedAt'] === null ? null : new Date(json['closedAt'])),
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'typeId': !exists(json, 'typeId') ? undefined : json['typeId'],
        'expanseId': !exists(json, 'expanseId') ? undefined : json['expanseId'],
        'description1': !exists(json, 'description1') ? undefined : json['description1'],
        'natureId': !exists(json, 'natureId') ? undefined : json['natureId'],
        'description2': !exists(json, 'description2') ? undefined : json['description2'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'stateChangeReason': !exists(json, 'stateChangeReason') ? undefined : json['stateChangeReason'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'contractorId': !exists(json, 'contractorId') ? undefined : json['contractorId'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'creatorTypeId': !exists(json, 'creatorTypeId') ? undefined : json['creatorTypeId'],
        'notifierFamilyname': !exists(json, 'notifierFamilyname') ? undefined : json['notifierFamilyname'],
        'notifierMiddlename': !exists(json, 'notifierMiddlename') ? undefined : json['notifierMiddlename'],
        'notifierForename': !exists(json, 'notifierForename') ? undefined : json['notifierForename'],
        'notifierPhone': !exists(json, 'notifierPhone') ? undefined : PhoneFromJSON(json['notifierPhone']),
        'notifierEmail': !exists(json, 'notifierEmail') ? undefined : json['notifierEmail'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'isAnonymized': !exists(json, 'isAnonymized') ? undefined : json['isAnonymized'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'townId': !exists(json, 'townId') ? undefined : json['townId'],
        'acceptedByContractor': !exists(json, 'acceptedByContractor') ? undefined : json['acceptedByContractor'],
        'type': !exists(json, 'type') ? undefined : FailureTypeFromJSON(json['type']),
        'expanse': !exists(json, 'expanse') ? undefined : DictionaryFromJSON(json['expanse']),
        'nature': !exists(json, 'nature') ? undefined : DictionaryFromJSON(json['nature']),
        'optimalDeadlineDays': !exists(json, 'optimalDeadlineDays') ? undefined : DictionaryFromJSON(json['optimalDeadlineDays']),
        'status': !exists(json, 'status') ? undefined : FailureStatusFromJSON(json['status']),
        'parent': !exists(json, 'parent') ? undefined : FailureFromJSON(json['parent']),
        'contractor': !exists(json, 'contractor') ? undefined : ContractorFromJSON(json['contractor']),
        'device': !exists(json, 'device') ? undefined : DeviceFromJSON(json['device']),
        'creatorType': !exists(json, 'creatorType') ? undefined : FailureCreatorTypeFromJSON(json['creatorType']),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'town': !exists(json, 'town') ? undefined : TownFromJSON(json['town']),
        'attachedFailures': !exists(json, 'attachedFailures') ? undefined : (json['attachedFailures'] === null ? null : (json['attachedFailures'] as Array<any>).map(FailureFromJSON)),
        'repairmen': !exists(json, 'repairmen') ? undefined : (json['repairmen'] === null ? null : (json['repairmen'] as Array<any>).map(RepairmanFromJSON)),
        'workbook': !exists(json, 'workbook') ? undefined : WorkbookFromJSON(json['workbook']),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function FailureToJSON(value?: Failure | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'identifier': value.identifier,
        'reportedAt': value.reportedAt === undefined ? undefined : (value.reportedAt.toISOString()),
        'deadline': value.deadline === undefined ? undefined : (value.deadline.toISOString()),
        'optimalDeadlineDaysId': value.optimalDeadlineDaysId,
        'closedAt': value.closedAt === undefined ? undefined : (value.closedAt === null ? null : value.closedAt.toISOString()),
        'address': AddressToJSON(value.address),
        'typeId': value.typeId,
        'expanseId': value.expanseId,
        'description1': value.description1,
        'natureId': value.natureId,
        'description2': value.description2,
        'statusId': value.statusId,
        'stateChangeReason': value.stateChangeReason,
        'parentId': value.parentId,
        'contractorId': value.contractorId,
        'deviceId': value.deviceId,
        'creatorTypeId': value.creatorTypeId,
        'notifierFamilyname': value.notifierFamilyname,
        'notifierMiddlename': value.notifierMiddlename,
        'notifierForename': value.notifierForename,
        'notifierPhone': PhoneToJSON(value.notifierPhone),
        'notifierEmail': value.notifierEmail,
        'comment': value.comment,
        'isAnonymized': value.isAnonymized,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'townId': value.townId,
        'acceptedByContractor': value.acceptedByContractor,
        'type': FailureTypeToJSON(value.type),
        'expanse': DictionaryToJSON(value.expanse),
        'nature': DictionaryToJSON(value.nature),
        'optimalDeadlineDays': DictionaryToJSON(value.optimalDeadlineDays),
        'status': FailureStatusToJSON(value.status),
        'parent': FailureToJSON(value.parent),
        'contractor': ContractorToJSON(value.contractor),
        'device': DeviceToJSON(value.device),
        'creatorType': FailureCreatorTypeToJSON(value.creatorType),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'town': TownToJSON(value.town),
        'attachedFailures': value.attachedFailures === undefined ? undefined : (value.attachedFailures === null ? null : (value.attachedFailures as Array<any>).map(FailureToJSON)),
        'repairmen': value.repairmen === undefined ? undefined : (value.repairmen === null ? null : (value.repairmen as Array<any>).map(RepairmanToJSON)),
        'workbook': WorkbookToJSON(value.workbook),
        'id': value.id,
    };
}


