import { ConfigFailureRulesVm, FeatureSwitchVm } from "api/generated/lumen";
import { useTranslation } from "react-i18next";
import useConfigurationQuery from "./queries/useConfigurationQuery";

type FeatureNTranslationKeyMap = Record<
  keyof FeatureSwitchVm,
  string | undefined
>;

const featureNTranslationKeyMap: FeatureNTranslationKeyMap = {
  failureDescription1:
    process.env.REACT_APP_FIELD_TRANSLATION_KEY_FAILURE_DESCRIPTION1,
  failureDescription2:
    process.env.REACT_APP_FIELD_TRANSLATION_KEY_FAILURE_DESCRIPTION2,
  failureListParam1:
    process.env.REACT_APP_FIELD_TRANSLATION_KEY_FAILURE_LIST_PARAM1,
  failureListParam2:
    process.env.REACT_APP_FIELD_TRANSLATION_KEY_FAILURE_LIST_PARAM2,
  maintainedDevices: undefined,
  failureSpecialCase:
    process.env.REACT_APP_FIELD_TRANSLATION_KEY_FAILURE_TYPE_SPECIAL_CASE,
  exam: undefined,
  contractorAcceptOfTaskAssignment: undefined,
};

type FeatureNPatternKeyMap = Record<
  keyof FeatureSwitchVm,
  keyof ConfigFailureRulesVm | undefined
>;

const featureNPatternMap: FeatureNPatternKeyMap = {
  failureDescription1: "description1Pattern",
  failureDescription2: "description2Pattern",
  failureListParam1: undefined,
  failureListParam2: undefined,
  maintainedDevices: undefined,
  failureSpecialCase: undefined,
  exam: undefined,
  contractorAcceptOfTaskAssignment: undefined,
};

const currentBrand = process.env.REACT_APP_CURRENT_BRAND;

const useFeature = (feature: keyof FeatureSwitchVm) => {
  const query = useConfigurationQuery();
  const { t } = useTranslation();

  const translationKey = featureNTranslationKeyMap[feature];
  const patternKey = featureNPatternMap[feature];

  return {
    isEnabled: !!query.data?.featureSwitch?.[feature],
    label: translationKey
      ? t(`env.fieldLabel.${translationKey}.${currentBrand}`) // Note: translation key string concatenation is acceptable here, because it gets generated in env-translation-keys.ts file
      : undefined,
    placeholder: translationKey
      ? t(`env.fieldPlaceholder.${translationKey}.${currentBrand}`) // Note: translation key string concatenation is acceptable here, because it gets generated in env-translation-keys.ts file
      : undefined,
    formRules:
      patternKey && query.data?.rules?.failure?.[patternKey] !== ""
        ? [
            {
              pattern: query.data?.rules?.failure?.[patternKey],
              message: t(`env.fieldError.${translationKey}.${currentBrand}`), // Note: translation key string concatenation is acceptable here, because it gets generated in env-translation-keys.ts file
            },
          ]
        : [],
  };
};

export default useFeature;
