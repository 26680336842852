import {
  Configuration,
  FailuresApi,
  FailureTypesApi,
  TownsApi,
  DictionaryItemsApi,
  InventoryApi,
  ContractorsApi,
  RepairmenApi,
  AccountsApi,
  DocumentsApi,
  WorkbooksApi,
  ContactPersonsApi,
  ImagesApi,
  MaintenanceApi,
  InformationApi,
  FailureTasksApi,
  InventoryCategoryApi,
  SpecialPlacesApi,
  ExamTypesApi,
  ExamsApi,
  ReportApi,
  OptionsApi,
} from "api/generated/lumen";
import { DevicesApi } from "./generated/lumen/apis/DevicesApi";
import { errorHandlingMiddleware } from "./middleware/errorHandlingMiddleware";
import {
  requestHeadersMiddleware,
  unauthenticatedRequestHeadersMiddleware,
} from "./middleware/requestHeadersMiddleware";

const apiConfig: Configuration = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
  middleware: [...errorHandlingMiddleware, ...requestHeadersMiddleware],
});

const unauthenticatedApiConfig: Configuration = new Configuration({
  basePath: process.env.REACT_APP_API_URL,
  middleware: [
    ...errorHandlingMiddleware,
    ...unauthenticatedRequestHeadersMiddleware,
  ],
});

export const api = {
  lumen: {
    failures: new FailuresApi(apiConfig),
    failureTypes: new FailureTypesApi(apiConfig),
    towns: new TownsApi(apiConfig),
    dictionaryItems: new DictionaryItemsApi(apiConfig),
    inventory: new InventoryApi(apiConfig),
    contractors: new ContractorsApi(apiConfig),
    repairmen: new RepairmenApi(apiConfig),
    accounts: new AccountsApi(apiConfig),
    documents: new DocumentsApi(apiConfig),
    workbooks: new WorkbooksApi(apiConfig),
    contactPerson: new ContactPersonsApi(apiConfig),
    images: new ImagesApi(apiConfig),
    maintenance: new MaintenanceApi(apiConfig),
    information: new InformationApi(apiConfig),
    devices: new DevicesApi(apiConfig),
    failureTask: new FailureTasksApi(apiConfig),
    inventoryCategory: new InventoryCategoryApi(apiConfig),
    specialPlaces: new SpecialPlacesApi(apiConfig),
    examTypes: new ExamTypesApi(apiConfig),
    exams: new ExamsApi(apiConfig),
    report: new ReportApi(apiConfig),
    options: new OptionsApi(apiConfig),

    unauthenticated: {
      failures: new FailuresApi(unauthenticatedApiConfig),
      failureTypes: new FailureTypesApi(unauthenticatedApiConfig),
    },
  },
};
