/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OptionsVm
 */
export interface OptionsVm {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof OptionsVm
     */
    options?: { [key: string]: any; } | null;
}

export function OptionsVmFromJSON(json: any): OptionsVm {
    return OptionsVmFromJSONTyped(json, false);
}

export function OptionsVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionsVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': !exists(json, 'options') ? undefined : json['options'],
    };
}

export function OptionsVmToJSON(value?: OptionsVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': value.options,
    };
}


