/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
    ContactPerson,
    ContactPersonFromJSON,
    ContactPersonFromJSONTyped,
    ContactPersonToJSON,
    Device,
    DeviceFromJSON,
    DeviceFromJSONTyped,
    DeviceToJSON,
    Dictionary,
    DictionaryFromJSON,
    DictionaryFromJSONTyped,
    DictionaryToJSON,
    Document,
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
    Failure,
    FailureFromJSON,
    FailureFromJSONTyped,
    FailureToJSON,
    Phone,
    PhoneFromJSON,
    PhoneFromJSONTyped,
    PhoneToJSON,
    Repairman,
    RepairmanFromJSON,
    RepairmanFromJSONTyped,
    RepairmanToJSON,
    SpecialPlace,
    SpecialPlaceFromJSON,
    SpecialPlaceFromJSONTyped,
    SpecialPlaceToJSON,
    Town,
    TownFromJSON,
    TownFromJSONTyped,
    TownToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Contractor
 */
export interface Contractor {
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    name?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof Contractor
     */
    headquarterAddress?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Contractor
     */
    postalAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    email?: string | null;
    /**
     * 
     * @type {Phone}
     * @memberof Contractor
     */
    phone?: Phone;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    taxnumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    companyregisterNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    statusId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    isInvited?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    isActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Contractor
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    modifiedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Contractor
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Contractor
     */
    repairmanEmailOfTaskAssignment?: boolean;
    /**
     * 
     * @type {Dictionary}
     * @memberof Contractor
     */
    status?: Dictionary;
    /**
     * 
     * @type {User}
     * @memberof Contractor
     */
    creator?: User;
    /**
     * 
     * @type {User}
     * @memberof Contractor
     */
    modifier?: User;
    /**
     * 
     * @type {Array<Repairman>}
     * @memberof Contractor
     */
    repairmen?: Array<Repairman> | null;
    /**
     * 
     * @type {Array<Failure>}
     * @memberof Contractor
     */
    failures?: Array<Failure> | null;
    /**
     * 
     * @type {Array<ContactPerson>}
     * @memberof Contractor
     */
    contactPersons?: Array<ContactPerson> | null;
    /**
     * 
     * @type {Array<Town>}
     * @memberof Contractor
     */
    towns?: Array<Town> | null;
    /**
     * 
     * @type {Array<SpecialPlace>}
     * @memberof Contractor
     */
    specialPlaces?: Array<SpecialPlace> | null;
    /**
     * 
     * @type {Array<Device>}
     * @memberof Contractor
     */
    devices?: Array<Device> | null;
    /**
     * 
     * @type {Array<Document>}
     * @memberof Contractor
     */
    documents?: Array<Document> | null;
    /**
     * 
     * @type {string}
     * @memberof Contractor
     */
    id?: string;
}

export function ContractorFromJSON(json: any): Contractor {
    return ContractorFromJSONTyped(json, false);
}

export function ContractorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Contractor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'headquarterAddress': !exists(json, 'headquarterAddress') ? undefined : AddressFromJSON(json['headquarterAddress']),
        'postalAddress': !exists(json, 'postalAddress') ? undefined : AddressFromJSON(json['postalAddress']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : PhoneFromJSON(json['phone']),
        'taxnumber': !exists(json, 'taxnumber') ? undefined : json['taxnumber'],
        'companyregisterNumber': !exists(json, 'companyregisterNumber') ? undefined : json['companyregisterNumber'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'isInvited': !exists(json, 'isInvited') ? undefined : json['isInvited'],
        'isDeleted': !exists(json, 'isDeleted') ? undefined : json['isDeleted'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'repairmanEmailOfTaskAssignment': !exists(json, 'repairmanEmailOfTaskAssignment') ? undefined : json['repairmanEmailOfTaskAssignment'],
        'status': !exists(json, 'status') ? undefined : DictionaryFromJSON(json['status']),
        'creator': !exists(json, 'creator') ? undefined : UserFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : UserFromJSON(json['modifier']),
        'repairmen': !exists(json, 'repairmen') ? undefined : (json['repairmen'] === null ? null : (json['repairmen'] as Array<any>).map(RepairmanFromJSON)),
        'failures': !exists(json, 'failures') ? undefined : (json['failures'] === null ? null : (json['failures'] as Array<any>).map(FailureFromJSON)),
        'contactPersons': !exists(json, 'contactPersons') ? undefined : (json['contactPersons'] === null ? null : (json['contactPersons'] as Array<any>).map(ContactPersonFromJSON)),
        'towns': !exists(json, 'towns') ? undefined : (json['towns'] === null ? null : (json['towns'] as Array<any>).map(TownFromJSON)),
        'specialPlaces': !exists(json, 'specialPlaces') ? undefined : (json['specialPlaces'] === null ? null : (json['specialPlaces'] as Array<any>).map(SpecialPlaceFromJSON)),
        'devices': !exists(json, 'devices') ? undefined : (json['devices'] === null ? null : (json['devices'] as Array<any>).map(DeviceFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(DocumentFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function ContractorToJSON(value?: Contractor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'headquarterAddress': AddressToJSON(value.headquarterAddress),
        'postalAddress': AddressToJSON(value.postalAddress),
        'email': value.email,
        'phone': PhoneToJSON(value.phone),
        'taxnumber': value.taxnumber,
        'companyregisterNumber': value.companyregisterNumber,
        'statusId': value.statusId,
        'isInvited': value.isInvited,
        'isDeleted': value.isDeleted,
        'isActive': value.isActive,
        'externalId': value.externalId,
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'repairmanEmailOfTaskAssignment': value.repairmanEmailOfTaskAssignment,
        'status': DictionaryToJSON(value.status),
        'creator': UserToJSON(value.creator),
        'modifier': UserToJSON(value.modifier),
        'repairmen': value.repairmen === undefined ? undefined : (value.repairmen === null ? null : (value.repairmen as Array<any>).map(RepairmanToJSON)),
        'failures': value.failures === undefined ? undefined : (value.failures === null ? null : (value.failures as Array<any>).map(FailureToJSON)),
        'contactPersons': value.contactPersons === undefined ? undefined : (value.contactPersons === null ? null : (value.contactPersons as Array<any>).map(ContactPersonToJSON)),
        'towns': value.towns === undefined ? undefined : (value.towns === null ? null : (value.towns as Array<any>).map(TownToJSON)),
        'specialPlaces': value.specialPlaces === undefined ? undefined : (value.specialPlaces === null ? null : (value.specialPlaces as Array<any>).map(SpecialPlaceToJSON)),
        'devices': value.devices === undefined ? undefined : (value.devices === null ? null : (value.devices as Array<any>).map(DeviceToJSON)),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(DocumentToJSON)),
        'id': value.id,
    };
}


