import { mdiCommentTextOutline, mdiLink } from "@mdi/js";
import { Tag, Tooltip } from "antd";
import {
  DictionaryVm,
  FailureListItemVm,
  FailureStatusVm,
  RepairmanVm,
} from "api/generated/lumen";
import { useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import BooleanDisplay from "components/ui/BooleanDisplay";
import DictionaryDisplay from "components/ui/DictionaryDisplay";
import Claims from "features/auth/claims";
import useAuth from "features/auth/useAuth";
import useActivatedPublicTownsQuery from "hooks/queries/useActivatedPublicTownsQuery";
import useFailureStatusesQuery from "hooks/queries/useFailureStatusesQuery";
import useFeature from "hooks/useFeature";
import useLanguageSensitiveUtils from "hooks/useLanguageSensitiveUtils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { DateFormat } from "widgets/table/DateDisplay";
import { EmptyContent } from "widgets/table/table-content/EmptyContent";
import { ColumnState } from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  DataType,
  FilterMode,
} from "widgets/table/useTableUtils";
import StatusTag from "../components/StatusTag";
import useFailuresListColumnTitles from "../useFailuresListColumnTitles";
import styles from "./FailuresTable.module.scss";

interface UseFailureTableColumns {
  columnParams: ColumnConfigParams<FailureListItemVm>[];
}

function uniqueElementsBy<T>(arr: T[], fn: (a: T, b: T) => boolean) {
  return arr.reduce<T[]>((acc, v) => {
    if (!acc.some((x) => fn(v, x))) acc.push(v);
    return acc;
  }, []);
}

const useFailureTableColumns = (): UseFailureTableColumns => {
  const { t } = useTranslation();
  const { columnTitles } = useFailuresListColumnTitles();
  const { displayName } = useLanguageSensitiveUtils();
  const failureStatusesQuery = useFailureStatusesQuery();
  const { hasRole, hasClaim } = useAuth();

  const failureStatusFilters = useMemo(
    () =>
      failureStatusesQuery.data?.map((item) => ({
        text: item.name,
        value: item.name,
      })),
    [failureStatusesQuery.data]
  );

  const alternativeFailureStatusFilters = useMemo(
    () =>
      uniqueElementsBy(
        failureStatusesQuery.data || [],
        (a, b) => a.alternativeName === b.alternativeName
      ).map((item) => ({
        text: item.alternativeName,
        value: item.alternativeName,
      })),
    [failureStatusesQuery.data]
  );

  const { failureTypes } = useSelector((state) => state.failureList);

  const { fetchFailureTypes } = useSelector(
    (state) => state.loading.effects.failureList
  );
  const activatedTownsQuery = useActivatedPublicTownsQuery();

  const { isEnabled: isMaintainedDevicesEnabled } =
    useFeature("maintainedDevices");

  const { isEnabled: isContractorAcceptOfTaskAssignment } = useFeature(
    "contractorAcceptOfTaskAssignment"
  );

  const columnParams = useMemo<ColumnConfigParams<FailureListItemVm>[]>(
    () =>
      [
        {
          key: "identifier",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
          render: (
            value: string,
            record: FailureListItemVm
          ): React.ReactNode => {
            return (
              <>
                {!!record.attachedFailuresCount &&
                  record.attachedFailuresCount > 0 && (
                    <Link to={`/failures/${record.id}`}>
                      <Tooltip title={t("failure.mergedFailure")}>
                        <MaterialIcon
                          path={mdiLink}
                          className={styles.attachedFailuresIcon}
                        />
                      </Tooltip>
                    </Link>
                  )}
                {value}
                {!!record.attachedFailuresCount &&
                  record.attachedFailuresCount > 0 && (
                    <sup className={styles.attachedFailuresSup}>
                      ({record.attachedFailuresCount})
                    </sup>
                  )}
              </>
            );
          },
        },
        ...((isMaintainedDevicesEnabled
          ? [
              {
                key: "deviceName",
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.SEARCH,
              },
            ]
          : [
              {
                key: "address.zip",
                dataIndex: ["address", "zip"],
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.SEARCH,
                width: "150px",
              },
              {
                key: "address.city",
                dataIndex: ["address", "city"],
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.FILTER_SELECT,
                filters: (activatedTownsQuery.data?.items || [])
                  .map((item) => ({
                    text: item.name || "",
                    value: item.name || "",
                  }))
                  .sort((a, b) => a.text.localeCompare(b.text)),
                filterOptionsLoading: activatedTownsQuery.isLoading,
              },
              {
                key: "address.street",
                dataIndex: ["address", "street"],
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.SEARCH,
              },
              {
                key: "address.number",
                dataIndex: ["address", "number"],
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.SEARCH,
              },
            ]) as ColumnConfigParams<FailureListItemVm>[]),
        {
          key: "type.name",
          dataIndex: ["type", "name"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.FILTER,
          filters: failureTypes
            .map((item) => ({
              text: item.name || "",
              value: item.name || "",
            }))
            .sort((a, b) => a.text.localeCompare(b.text)),
          filterOptionsLoading: fetchFailureTypes.loading,
        },
        {
          key: "comment",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          render: (value: string | undefined): React.ReactNode => {
            return value ? (
              <Tooltip title={value}>
                <MaterialIcon path={mdiCommentTextOutline} />
              </Tooltip>
            ) : (
              ""
            );
          },
        },
        {
          key: "reportedAt",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.DATERANGEPICKER,
          dataType: DataType.DATE,
          dateFormat: DateFormat.DateTime,
        },
        {
          key: "deadline",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.DATERANGEPICKER,
          dataType: DataType.DATE,
        },
        {
          key: "optimalDeadlineDays.name",
          dataIndex: ["optimalDeadlineDays"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          className: styles.recommendedFixingDays,
          filterMode: FilterMode.SEARCH,
          render: (dictionary: DictionaryVm) => {
            return <DictionaryDisplay dictionary={dictionary} />;
          },
        },
        ...((hasRole(["Council"])
          ? [
              {
                key: "status.alternativeName",
                dataIndex: "status",
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.FILTER,
                filters: alternativeFailureStatusFilters,
                filterOptionsLoading: failureStatusesQuery.isLoading,
                render: (status: FailureStatusVm) => {
                  return status ? (
                    <StatusTag status={status} useAlternativeName />
                  ) : (
                    <EmptyContent />
                  );
                },
              },
            ]
          : [
              {
                key: "status.name",
                dataIndex: "status",
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.FILTER,
                filters: failureStatusFilters,
                filterOptionsLoading: failureStatusesQuery.isLoading,
                render: (status: FailureStatusVm) => {
                  return status ? (
                    <StatusTag status={status} />
                  ) : (
                    <EmptyContent />
                  );
                },
              },
            ]) as ColumnConfigParams<FailureListItemVm>[]),
        ...((hasClaim([Claims.Failures.CONTRACTORVIEW])
          ? [
              {
                key: "contractor.name",
                dataIndex: ["contractor", "name"],
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.SEARCH,
              },
              {
                key: "repairmen",
                sort: false,
                filterMode: FilterMode.SEARCH,
                defaultState: ColumnState.AlwaysVisible,
                render: (value: RepairmanVm[]): React.ReactNode => {
                  return value.length ? (
                    value.map((repairer) => displayName(repairer)).join(", ")
                  ) : (
                    <EmptyContent />
                  );
                },
              },
            ]
          : []) as ColumnConfigParams<FailureListItemVm>[]),
        {
          key: "closedAt",
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.DATERANGEPICKER,
          dateFormat: DateFormat.DateTime,
          dataType: DataType.DATE,
        },
        {
          key: "creator.familyname",
          dataIndex: ["creator", "familyname"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "creator.forename",
          dataIndex: ["creator", "forename"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        {
          key: "creatorType.displayName",
          dataIndex: ["creatorType", "displayName"],
          sort: true,
          defaultState: ColumnState.AlwaysVisible,
          filterMode: FilterMode.SEARCH,
        },
        ...(isContractorAcceptOfTaskAssignment
          ? [
              {
                key: "acceptedByContractor",
                sort: true,
                defaultState: ColumnState.AlwaysVisible,
                filterMode: FilterMode.YES_NO,
                dataType: DataType.YES_NO,
                render: (value: boolean) => {
                  return (
                    <BooleanDisplay
                      value={value}
                      trueDisplay={<Tag color="success">{t("common.yes")}</Tag>}
                      falseDisplay={<Tag color="error">{t("common.no")}</Tag>}
                    />
                  );
                },
              },
            ]
          : []),
      ].map((item, index) => ({
        ...item,
        title: columnTitles[index],
      })),
    [
      isMaintainedDevicesEnabled,
      activatedTownsQuery.data?.items,
      activatedTownsQuery.isLoading,
      failureTypes,
      fetchFailureTypes.loading,
      hasRole,
      alternativeFailureStatusFilters,
      failureStatusesQuery.isLoading,
      failureStatusFilters,
      hasClaim,
      isContractorAcceptOfTaskAssignment,
      t,
      displayName,
      columnTitles,
    ]
  );

  return {
    columnParams,
  };
};

export default useFailureTableColumns;
