import { mdiDelete, mdiEye, mdiPlus } from "@mdi/js";
import { Button, Modal, ModalFuncProps, Space, Tag } from "antd";
import { ExamTypeVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import BooleanDisplay from "components/ui/BooleanDisplay";
import IconButton from "components/ui/IconButton";
import useTable from "hooks/useTable";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import {
  ColumnState,
  ExtendedColumnType,
} from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";
import ExamTypeCreateModal from "../create/ExamTypeCreateModal";

const { confirm } = Modal;

const ExamTypeList: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { listParams, list } = useSelector((state) => state.examTypeList);
  const { fetchList } = useSelector(
    (state) => state.loading.effects.examTypeList
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.examTypeList.fetchList(requestParams),
    [dispatch.examTypeList]
  );

  const columnParams = useMemo<ColumnConfigParams<ExamTypeVm>[]>(
    () => [
      {
        key: "name",
        sort: true,
        defaultState: ColumnState.AlwaysVisible,
        filterMode: FilterMode.SEARCH,
        title: t("examType.name"),
      },
      {
        key: "description",
        sort: true,
        defaultState: ColumnState.AlwaysVisible,
        filterMode: FilterMode.SEARCH,
        title: t("examType.description"),
      },
      {
        key: "warningDaysBeforeExpiration",
        sort: true,
        defaultState: ColumnState.AlwaysVisible,
        filterMode: FilterMode.SEARCH,
        title: t("examType.warningDaysBeforeExpiration"),
      },
      {
        key: "isActive",
        sort: true,
        defaultState: ColumnState.AlwaysVisible,
        filterMode: FilterMode.ACTIVE_INACTIVE,
        title: t("examType.status"),
        render: (value: boolean) => {
          return (
            <BooleanDisplay
              value={value}
              trueDisplay={<Tag color="success">{t("common.active")}</Tag>}
              falseDisplay={<Tag color="error">{t("common.inactive")}</Tag>}
            />
          );
        },
      },
    ],
    [t]
  );

  const handleNewButtonClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      dispatch.examTypeCreate.setEditingItemId(id);

      setCreateModalOpen(true);
    },
    [dispatch.examTypeCreate]
  );

  const handleDeleteButtonClick = useCallback(
    (id?: string) => {
      if (typeof id !== "string") {
        return;
      }

      const confirmProps: ModalFuncProps = {
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.examTypeList.delete(id);

          return dispatch.examTypeList.fetchList(listParams);
        },
      };

      confirm(confirmProps);
    },
    [dispatch.examTypeList, listParams, t]
  );

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<ExamTypeVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />

            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDeleteButtonClick, handleViewButtonClick, t]
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    actionColumnParams,
    listParams,
    list,
    columnStorageName: ColumnStorageName.EXAM_TYPES,
    headerOptions: (
      <Button
        icon={<MaterialIcon path={mdiPlus} />}
        type="primary"
        onClick={handleNewButtonClick}
      >
        {t("common.new")}
      </Button>
    ),
    resetListParams: dispatch.examTypeList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.examTypeList.reset,
  });

  return (
    <>
      <CustomTable<ExamTypeVm>
        {...customTableProps}
        scrollX={800}
        selectable={false}
      />

      <ExamTypeCreateModal
        visible={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        fetchDataParams={listParams}
      />
    </>
  );
};

export default ExamTypeList;
