import { FailureStatusFilter } from "api/generated/lumen";
import useAuth from "features/auth/useAuth";
import { Role } from "models/common";
import { useMemo } from "react";
import { ListRequestParams } from "widgets/table/useTableUtils";
import FailuresTable from "../../default/FailuresTable";

const TableToAttend: React.FC = () => {
  const { userInfo } = useAuth();

  const fetchDataParams = useMemo<ListRequestParams>(() => {
    switch (userInfo?.role?.name as Role) {
      case "LumenAdmin":
      case "Root_SUPER_ADMIN":
        return {
          filter: {
            contractor: "", // Not assigned to contractor
          },
          statusFilter: FailureStatusFilter.OnlyOpened,
        };

      case "Contractor":
        return {
          filter: {},
          statusFilter: FailureStatusFilter.OnlyOpened,
          onlyNotAssignedToRepairman: true,
        };

      case "CallCenter":
      case "Council":
        return {
          filter: {
            "status.isDefault": "true", // Is in first status
          },
          statusFilter: FailureStatusFilter.OnlyOpened,
        };

      default:
        return {
          filter: {},
          statusFilter: FailureStatusFilter.OnlyOpened,
        };
    }
  }, [userInfo?.role]);

  return (
    <FailuresTable
      fetchDataParams={fetchDataParams}
      failureListType="toAttend"
    />
  );
};

export default TableToAttend;
