import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import useLanguageConfigurator from "./hooks/useLanguageConfigurator";
import { RouterView } from "components/router/RouterView";
import useAuth from "features/auth/useAuth";
import { Role } from "models/common";
import { useDispatch } from "app/store";
import FullPageSpin from "components/FullPageSpin";
import useConfigurationQuery from "hooks/queries/useConfigurationQuery";

function App() {
  const { antDLocale } = useLanguageConfigurator();
  const { userInfo } = useAuth();
  const dispatch = useDispatch();
  const configurationQuery = useConfigurationQuery();
  const view = localStorage.getItem("fortana-view");
  const { hasRole } = useAuth();

  useEffect(() => {
    if (
      (userInfo?.role?.name as Role) === "Repairman" ||
      (view === "repairman" && hasRole(["Contractor"]))
    ) {
      dispatch.common.setDeviceLayoutType("mobile");
    } else {
      dispatch.common.setDeviceLayoutType("default");
    }
  }, [dispatch.common, userInfo, view, hasRole]);

  return configurationQuery.isSuccess ? (
    <ConfigProvider locale={antDLocale}>
      <RouterView />
    </ConfigProvider>
  ) : (
    <FullPageSpin />
  );
}

export default App;
