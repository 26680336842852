import { createModel } from "@rematch/core";
import { api } from "api";
import {
  ContactPerson,
  FailureTypeVm,
  OrderByType,
  SlenderTownVm,
  UpdateTownCommand,
} from "api/generated/lumen";
import { RootModel } from "app/store";

interface TownCreateState {
  failureTypes: FailureTypeVm[];
  editingItemId?: string;
  existingContacts?: ContactPerson[];
  slenderList?: SlenderTownVm[];
}

const initialState: TownCreateState = {
  failureTypes: [],
  editingItemId: undefined,
  existingContacts: undefined,
  slenderList: [],
};

export const townCreate = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setFailureTypes(state, data: FailureTypeVm[]) {
      state.failureTypes = data;
    },
    setEditingItemId(state, data?: string) {
      state.editingItemId = data;
    },
    setExistingContacts(state, data?: ContactPerson[]) {
      state.existingContacts = data;
    },
    setSlenderList(state, data: SlenderTownVm[]) {
      state.slenderList = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { townCreate } = dispatch;

    return {
      async create({
        id,
        values,
      }: {
        id?: string;
        values: UpdateTownCommand;
      }): Promise<void> {
        if (typeof id === "string") {
          return api.lumen.towns.apiTownsIdPut({
            id,
            updateTownCommand: values,
          });
        }
      },

      async fetchFailureTypes(): Promise<void> {
        const data = await api.lumen.failureTypes.apiFailureTypesGetAllPost({
          getAllFailureTypesQuery: {
            pageSize: -1,
            sortings: [
              {
                orderByType: OrderByType.Ascend,
                orderBy: "name",
              },
            ],
          },
        });

        const { items } = data;

        dispatch(townCreate.setFailureTypes(items || []));
      },

      async edit(values: UpdateTownCommand): Promise<void> {
        return api.lumen.towns.apiTownsIdPut({
          id: values.id,
          updateTownCommand: values,
        });
      },

      async fetchExistingContacts(): Promise<void> {
        const data = await api.lumen.contactPerson.apiContactPersonsGetAllPost({
          getAllContactPersonsQuery: {
            pageSize: -1,
            sortings: [
              {
                orderByType: OrderByType.Ascend,
                orderBy: "familyname",
              },
              {
                orderByType: OrderByType.Ascend,
                orderBy: "forename",
              },
            ],
          },
        });
        const { items } = data;
        dispatch(townCreate.setExistingContacts(items || []));
      },

      async fetchAllSlender(): Promise<void> {
        const data = await api.lumen.towns.apiTownsGetAllSlenderPost({
          getAllTownsSlenderQuery: {
            pageSize: -1,
            sortings: [{ orderBy: "name", orderByType: OrderByType.Ascend }],
          },
        });

        const { items } = data;

        dispatch(townCreate.setSlenderList(items || []));
      },
    };
  },
});
