import useAuth from "features/auth/useAuth";
import React from "react";
import FailureViewDefault from "./default/FailureViewDefault";
import FailureViewRepairer from "./repairer/FailureViewRepairer";

const FailureViewHub: React.FC = () => {
  const { hasRole } = useAuth();
  const view = localStorage.getItem("fortana-view");

  return hasRole(["Repairman"]) ||
    (hasRole(["Contractor"]) && view === "repairman") ? (
    <FailureViewRepairer />
  ) : (
    <FailureViewDefault />
  );
};

export default FailureViewHub;
