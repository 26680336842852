import { mdiInformation, mdiPencil } from "@mdi/js";
import {
  Button,
  Divider,
  ModalFuncProps,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import confirm from "antd/lib/modal/confirm";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import { MaterialIcon } from "components/MaterialIcon";
import CardContent from "components/ui/CardContent";
import CardTitle from "components/ui/CardTitle";
import FieldsetFieldsWrapper from "components/ui/FieldsetFieldsWrapper";
import useFeature from "hooks/useFeature";
import { FormMode } from "models/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, email, useGrapeAntdForm } from "widgets/form";
import GrapeAntdFormSubmit from "widgets/form/components/GrapeAntdFormSubmit";
import styles from "./GeneralSettings.module.scss";
import useConfigurationQuery from "hooks/queries/useConfigurationQuery";

const GeneralSettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formUtils = useGrapeAntdForm<any>();
  const { settings } = useSelector((state) => state.settings);
  const query = useConfigurationQuery();

  const initialValues = useMemo(() => {
    return {
      options: {
        ...settings?.options,
        SystemNotificationEmailAddresses:
          settings?.options?.SystemNotificationEmailAddresses === ""
            ? undefined
            : settings?.options?.SystemNotificationEmailAddresses.split(";"),
      },
    };
  }, [settings]);

  const { fetchSettings, editSettings } = useSelector(
    (state) => state.loading.effects.settings
  );
  const [formMode, setFormMode] = useState<FormMode>("View");

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const { isEnabled: isContractorAcceptOfTaskAssignment } = useFeature(
    "contractorAcceptOfTaskAssignment"
  );

  const setInitialValues = useCallback(() => {
    formUtils.form.setFieldsValue(initialValues);

    /**
     * After setting the initial values with `form.setFieldsValue`, the form becomes dirty.
     * Setting the touched prop with setFields rollbacks the dirtiness
     */
    formUtils.form.setFields(
      Object.keys(initialValues.options).map((key) => ({
        name: ["options", key],
        touched: false,
      }))
    );
  }, [formUtils.form, initialValues]);

  const fetchAllSettings = useCallback(() => {
    dispatch.settings.fetchSettings();
  }, [dispatch.settings]);

  const handleFinish = useCallback(
    async (values: any): Promise<void> => {
      const payload = {
        options: {
          ...values?.options,
          ...(isMaintainedDevicesFeatureEnabled && {
            DevicesAutoSynchronizationEnabled:
              values?.options?.DevicesAutoSynchronizationEnabled,
          }),
          ...(isContractorAcceptOfTaskAssignment && {
            ContractorAcceptOfTaskAssignment:
              values?.options?.ContractorAcceptOfTaskAssignment,
          }),
          SystemNotificationEmailAddresses: values?.options
            ?.SystemNotificationEmailAddresses
            ? values?.options?.SystemNotificationEmailAddresses?.join(";")
            : "",
        },
      };
      await dispatch.settings.editSettings(payload);
      fetchAllSettings();
      setFormMode("View");
      query.refetch();
    },
    [
      dispatch.settings,
      fetchAllSettings,
      isContractorAcceptOfTaskAssignment,
      isMaintainedDevicesFeatureEnabled,
      query,
    ]
  );

  const handleCancel = useCallback(() => {
    if (!formUtils.form || !formUtils.form.isFieldsTouched()) {
      setFormMode("View");
      formUtils.form.resetFields();
      setInitialValues();
      return;
    }

    const confirmProps: ModalFuncProps = {
      okText: t("common.yes"),
      okType: "danger",
      cancelText: t("common.no"),
      title: t("settings.generalSettings.confirmModalTitle"),
      onOk() {
        setFormMode("View");
        formUtils.form.resetFields();
        setInitialValues();
      },
    };

    confirm(confirmProps);
  }, [formUtils.form, t, setInitialValues]);

  useEffect(() => {
    fetchAllSettings();
  }, [fetchAllSettings]);

  useEffect(() => {
    if (fetchSettings.loading) {
      return;
    }

    setInitialValues();
  }, [fetchSettings.loading, setInitialValues]);

  return (
    <CustomForm
      formUtils={formUtils}
      onFinish={handleFinish}
      viewMode={formMode === "View"}
      initialValues={settings}
    >
      <CardTitle className={styles.titleContainer}>
        {formMode === "View" ? (
          <Button
            icon={<MaterialIcon path={mdiPencil} />}
            onClick={() => setFormMode("Edit")}
          >
            {t("common.edit")}
          </Button>
        ) : (
          <Space>
            <Button
              type="text"
              loading={editSettings.loading}
              onClick={handleCancel}
            >
              {t("common.cancel")}
            </Button>

            <GrapeAntdFormSubmit
              loading={editSettings.loading}
              onlySubmitValidForm
              type="primary"
            >
              {t("common.save")}
            </GrapeAntdFormSubmit>
          </Space>
        )}
      </CardTitle>

      <CardContent className={styles.card}>
        <Spin spinning={fetchSettings.loading}>
          <Space direction="vertical" className={styles.fieldsetWrapper}>
            <h3>{t("settings.generalSettings.emailSendingSubTitle")}</h3>
            <FieldsetFieldsWrapper className={styles.fieldsetFieldsWrapper}>
              <GrapeAntdForm.Item
                name={["options", "EmailSendingEnabled"]}
                valuePropName="checked"
                className={styles.field}
                extra={
                  <Space>
                    {t("settings.generalSettings.emailSendingEnabled")}
                    <Tooltip
                      title={t(
                        "settings.generalSettings.emailSendingEnabledTooltip"
                      )}
                      className={styles.infoIcon}
                    >
                      <MaterialIcon path={mdiInformation} />
                    </Tooltip>
                  </Space>
                }
              >
                <Switch
                  checkedChildren={t("common.yes")}
                  unCheckedChildren={t("common.no")}
                />
              </GrapeAntdForm.Item>

              <Divider />

              <GrapeAntdForm.Item
                name={["options", "SystemNotificationEmailEnabled"]}
                valuePropName="checked"
                className={styles.field}
                extra={t(
                  "settings.generalSettings.systemNotificationEmailEnabled"
                )}
              >
                <Switch
                  checkedChildren={t("common.yes")}
                  unCheckedChildren={t("common.no")}
                />
              </GrapeAntdForm.Item>

              <GrapeAntdForm.Item
                name={["options", "SystemNotificationEmailAddresses"]}
                label={t(
                  "settings.generalSettings.systemNotificationEmailAddresses"
                )}
                rules={[
                  {
                    type: "array",
                    defaultField: email(t),
                  },
                ]}
              >
                <Select
                  allowClear
                  size="large"
                  mode="tags"
                  tagRender={({ value, onClose }) => (
                    <Tag
                      closable={formMode === "Edit"}
                      onClose={onClose}
                      key={value}
                      color={
                        email(t).pattern?.test(value)
                          ? styles.primaryColor
                          : styles.errorColor
                      }
                    >
                      {value}
                    </Tag>
                  )}
                />
              </GrapeAntdForm.Item>

              <Divider />

              <GrapeAntdForm.Item
                name={[
                  "options",
                  "ContractorNotificationEmailOfTaskAssignmentEnabled",
                ]}
                valuePropName="checked"
                className={styles.field}
                extra={t(
                  "settings.generalSettings.contractorNotificationEmailOfTaskAssignmentEnabled"
                )}
              >
                <Switch
                  checkedChildren={t("common.yes")}
                  unCheckedChildren={t("common.no")}
                />
              </GrapeAntdForm.Item>
            </FieldsetFieldsWrapper>

            <h3>{t("settings.generalSettings.otherSettingsSubTitle")}</h3>
            <FieldsetFieldsWrapper className={styles.fieldsetFieldsWrapper}>
              <GrapeAntdForm.Item
                name={["options", "ContractorAcceptOfTaskAssignment"]}
                valuePropName="checked"
                className={styles.field}
                extra={t(
                  "settings.generalSettings.contractorAcceptOfTaskAssignment"
                )}
              >
                <Switch
                  checkedChildren={t("common.yes")}
                  unCheckedChildren={t("common.no")}
                />
              </GrapeAntdForm.Item>

              {isMaintainedDevicesFeatureEnabled && (
                <GrapeAntdForm.Item
                  name={["options", "DevicesAutoSynchronizationEnabled"]}
                  valuePropName="checked"
                  className={styles.field}
                  extra={t(
                    "settings.generalSettings.devicesAutoSynchronizationEnabled"
                  )}
                >
                  <Switch
                    checkedChildren={t("common.yes")}
                    unCheckedChildren={t("common.no")}
                  />
                </GrapeAntdForm.Item>
              )}

              <GrapeAntdForm.Item
                name={["options", "ExamBlockEnabled"]}
                valuePropName="checked"
                className={styles.field}
                extra={t("settings.generalSettings.examBlockEnabled")}
              >
                <Switch
                  checkedChildren={t("common.yes")}
                  unCheckedChildren={t("common.no")}
                />
              </GrapeAntdForm.Item>
            </FieldsetFieldsWrapper>
          </Space>
        </Spin>
      </CardContent>
    </CustomForm>
  );
};

export default GeneralSettings;
