import { TabPaneProps, Tabs } from "antd";
import TabsWrapper from "components/ui/TabsWrapper";
import { useTranslation } from "react-i18next";
import GeneralSettings from "./GeneralSettings";
import { useEffect } from "react";
import { useDispatch } from "app/store";
import ListPageLayout from "components/layout/ListPageLayout";
import styles from "./Settings.module.scss";

interface CustomTabPaneProps extends Omit<TabPaneProps, "key"> {
  key: string;
}

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch.settings.reset();
    };
  }, [dispatch.settings]);

  const tabs: CustomTabPaneProps[] = [
    {
      tab: t("settings.tabs.generalSettings"),
      key: "generalSetting",
      children: <GeneralSettings />,
    },
  ];

  return (
    <ListPageLayout title={t("menu.settings")}>
      <TabsWrapper>
        <Tabs defaultActiveKey="1" type="card" size="small">
          {tabs.map(({ tab, key, children }) => (
            <Tabs.TabPane tab={tab} key={key} className={styles.tab}>
              {children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </TabsWrapper>
    </ListPageLayout>
  );
};

export default Settings;
