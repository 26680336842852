import { createModel } from "@rematch/core";
import { api } from "api";
import { DynamicDeviceVm } from "api/generated/lumen/models/DynamicDeviceVm";
import { RootModel } from "app/store";
import { calculateFilterRequestParams, mapToGetAllQuery } from "utils";
import {
  ListRequestParams,
  mergeListParams,
  mergeListParamsWithPagination,
  OrderByType,
} from "widgets/table/useTableUtils";

interface MaintainedDevicesListState {
  listParams: ListRequestParams;
  list: DynamicDeviceVm[];
  selectedIds: string[];
}

const initialState: MaintainedDevicesListState = {
  listParams: {
    pageSize: 10,
    order: [
      {
        orderBy: "name",
        orderByType: OrderByType.Ascending,
      },
    ],
    filter: {
      isActive: true,
    },
  },
  list: [],
  selectedIds: [],
};

export const maintainedDevicesList = createModel<RootModel>()({
  state: initialState,
  reducers: {
    resetListParams(state) {
      state.listParams = {
        ...initialState.listParams,
        order: initialState.listParams.order,
        filter: {
          isActive: true,
        },
      };
    },
    setList(
      state,
      {
        data,
        listParams,
      }: { data: DynamicDeviceVm[]; listParams: ListRequestParams }
    ) {
      state.list = data;
      state.listParams = listParams;
    },
    setSelectedIds(state, data: string[]) {
      state.selectedIds = data;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { maintainedDevicesList } = dispatch;

    return {
      async fetchList(params: ListRequestParams = {}, state): Promise<void> {
        const mergedParams = mergeListParams(
          state.maintainedDevicesList.listParams,
          params
        );
        const filter =
          calculateFilterRequestParams<DynamicDeviceVm>(mergedParams);

        const payload: ListRequestParams = {
          ...mergedParams,
          filter,
        };

        const data = await api.lumen.devices.apiDevicesGetAllPost({
          getAllDevicesQuery: mapToGetAllQuery(payload),
        });

        const { items, ...pagination } = data;

        dispatch(
          maintainedDevicesList.setList({
            data: items || [],
            listParams: mergeListParamsWithPagination(mergedParams, pagination),
          })
        );
      },

      async syncDeviceData(): Promise<void> {
        return api.lumen.devices.apiDevicesSyncGet();
      },
    };
  },
});
