import { Card, Col, ColProps, Row, Space } from "antd";
import { Container } from "components/layout/Container";
import StretchToCardEdge from "components/StretchToCardEdge";
import React, { FC, ReactNode, useMemo } from "react";
import styles from "./CardWithBrandLogo.module.scss";
import BrandImage from "./BrandImage";
import useConfigurationQuery from "hooks/queries/useConfigurationQuery";

interface CardWithBrandLogoProps {
  footerContent?: ReactNode;
  size?: "small" | "default";
  children?: ReactNode;
}

const CardWithBrandLogo: FC<CardWithBrandLogoProps> = ({
  children,
  footerContent,
  size = "default",
}) => {
  const colAttributes = useMemo<ColProps>(() => {
    if (size === "small") {
      return {
        xxl: 8,
        xl: 10,
        lg: 12,
        span: 24,
      };
    }

    return {
      xxl: 10,
      xl: 12,
      lg: 14,
      span: 24,
    };
  }, [size]);

  const configQuery = useConfigurationQuery();

  return (
    <Container>
      <Row justify="center">
        <Col {...colAttributes}>
          <Card>
            <Space direction="vertical" size="large" className={styles.space}>
              <Space className={styles.logoAndAppNameContainer}>
                <BrandImage
                  path={`${process.env.PUBLIC_URL}/images`}
                  subPath="fortana-logo.svg"
                  className={styles.logo}
                />
                <div className={styles.appName}>
                  {configQuery.data?.applicationName}
                </div>
              </Space>
              {children}
            </Space>

            {footerContent && (
              <StretchToCardEdge>
                <div className={styles.footer}>{footerContent}</div>
              </StretchToCardEdge>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CardWithBrandLogo;
