import { Checkbox, ConfigProvider, Empty, Spin, Typography } from "antd";
import { FailureVm, RepairmanVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import {
  FailureAssignToRepairmenFormValues,
  FailureListRequestParams,
} from "features/failures/models";
import useLanguageSensitiveUtils from "hooks/useLanguageSensitiveUtils";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FormUtils, GrapeAntdForm, requiredField } from "widgets/form";
import styles from "./ChooseRepairmenToAssign.module.scss";
import { getProperPageParamAfterFailureAssign } from "features/failures/utils";

interface ChooseRepairmenToAssignProps {
  formUtils: FormUtils;
  failure?: FailureVm;
  onCancel:
    | ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void)
    | undefined;
  repairmen: RepairmanVm[];
  fetchDataParams?: FailureListRequestParams;
  finishHandler?: (repairmanIds: string[]) => Promise<void>;
  assignableFailures?: FailureVm[];
  failureListType?: "toAttend";
}

const ChooseRepairmenToAssign: React.FC<ChooseRepairmenToAssignProps> = ({
  formUtils,
  failure,
  onCancel,
  repairmen,
  fetchDataParams,
  finishHandler,
  assignableFailures,
  failureListType,
}) => {
  const dispatch = useDispatch();
  const { displayName } = useLanguageSensitiveUtils();
  const { t } = useTranslation();

  const {
    fetchRepairmen: fetchRepairmenBulkAssign,
    assignFailuresToRepairmen: assignToRepairMenBulkAssign,
  } = useSelector((state) => state.loading.effects.failureBulkAssign);
  const { fetchRepairmen, assignToRepairmen } = useSelector(
    (state) => state.loading.effects.failureAssign
  );

  const handleFinish = useCallback(
    async (values: FailureAssignToRepairmenFormValues): Promise<void> => {
      if (finishHandler) {
        await finishHandler(values.repairmanIds);
      } else {
        if (typeof failure?.id !== "string") {
          return;
        }

        await dispatch.failureAssign.assignToRepairmen({
          id: failure?.id,
          repairmanIds: values.repairmanIds,
        });
      }

      dispatch.failureList.fetchList({
        ...fetchDataParams,
        page:
          failureListType === "toAttend"
            ? getProperPageParamAfterFailureAssign(
                assignableFailures || [failure ?? {}],
                fetchDataParams
              )
            : fetchDataParams?.page,
      });

      onCancel?.("" as any);
    },
    [
      dispatch.failureAssign,
      dispatch.failureList,
      failure,
      fetchDataParams,
      finishHandler,
      onCancel,
      assignableFailures,
      failureListType,
    ]
  );

  /**
   * This is for setting the initialValues of the form. The initialValues prop doesn't work
   * because the `failure` prop needs to be loaded first
   */
  useEffect(() => {
    if (assignToRepairMenBulkAssign.loading || assignToRepairmen.loading) {
      return;
    }

    formUtils.form.setFieldsValue({
      repairmanIds: failure?.repairmen?.map(({ id }) => id),
    });

    /**
     * After setting the initial values with `form.setFieldsValue`, the form becomes dirty
     * setting the touched prop with setFields rollbacks the dirtiness
     */
    formUtils.form.setFields([{ name: "repairmanIds", touched: false }]);
  }, [assignToRepairMenBulkAssign, assignToRepairmen, failure, formUtils.form]);

  if (fetchRepairmen.loading || fetchRepairmenBulkAssign.loading) {
    return (
      <div className={styles.spinWrapper}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <CustomForm
      formUtils={formUtils}
      onFinish={handleFinish}
      className={styles.form}
    >
      <GrapeAntdForm.Item
        name="repairmanIds"
        required
        rules={[
          requiredField(
            t,
            undefined,
            { type: "array" },
            t("failure.assignModal.repairmanIdsRequired")
          ),
        ]}
      >
        {repairmen.length ? (
          <Checkbox.Group className={styles.checkboxGroupWrapper}>
            {repairmen.map((repairman) => (
              <Checkbox value={repairman.id} key={repairman.id}>
                <Typography.Text
                  className={styles.repairmanName}
                  title={
                    displayName({
                      familyname: repairman.familyname,
                      forename: repairman.forename,
                      middlename: repairman.middlename,
                    }) || ""
                  }
                  ellipsis
                >
                  {displayName({
                    familyname: repairman.familyname,
                    forename: repairman.forename,
                    middlename: repairman.middlename,
                  })}
                </Typography.Text>
              </Checkbox>
            ))}
          </Checkbox.Group>
        ) : (
          <ConfigProvider
            locale={{
              locale: "hu",
              Empty: {
                description: t("common.noDataForSearchValue"),
              },
            }}
          >
            <Empty />
          </ConfigProvider>
        )}
      </GrapeAntdForm.Item>
    </CustomForm>
  );
};

export default ChooseRepairmenToAssign;
