/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DictionaryGroupVmPaginatedSearchResponse,
    DictionaryGroupVmPaginatedSearchResponseFromJSON,
    DictionaryGroupVmPaginatedSearchResponseToJSON,
    DictionaryVmPaginatedSearchResponse,
    DictionaryVmPaginatedSearchResponseFromJSON,
    DictionaryVmPaginatedSearchResponseToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllDictionaryGroupsQuery,
    GetAllDictionaryGroupsQueryFromJSON,
    GetAllDictionaryGroupsQueryToJSON,
    GetAllDictionaryItemsQuery,
    GetAllDictionaryItemsQueryFromJSON,
    GetAllDictionaryItemsQueryToJSON,
} from '../models';

export interface ApiDictionaryItemsGroupsPostRequest {
    getAllDictionaryGroupsQuery?: GetAllDictionaryGroupsQuery;
}

export interface ApiDictionaryItemsPostRequest {
    getAllDictionaryItemsQuery?: GetAllDictionaryItemsQuery;
}

/**
 * 
 */
export class DictionaryItemsApi extends runtime.BaseAPI {

    /**
     * Dictionary groups together with their items.
     */
    async apiDictionaryItemsGroupsPostRaw(requestParameters: ApiDictionaryItemsGroupsPostRequest): Promise<runtime.ApiResponse<DictionaryGroupVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/DictionaryItems/Groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllDictionaryGroupsQueryToJSON(requestParameters.getAllDictionaryGroupsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DictionaryGroupVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Dictionary groups together with their items.
     */
    async apiDictionaryItemsGroupsPost(requestParameters: ApiDictionaryItemsGroupsPostRequest): Promise<DictionaryGroupVmPaginatedSearchResponse> {
        const response = await this.apiDictionaryItemsGroupsPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Dictionary items
     */
    async apiDictionaryItemsPostRaw(requestParameters: ApiDictionaryItemsPostRequest): Promise<runtime.ApiResponse<DictionaryVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/DictionaryItems`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllDictionaryItemsQueryToJSON(requestParameters.getAllDictionaryItemsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DictionaryVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Dictionary items
     */
    async apiDictionaryItemsPost(requestParameters: ApiDictionaryItemsPostRequest): Promise<DictionaryVmPaginatedSearchResponse> {
        const response = await this.apiDictionaryItemsPostRaw(requestParameters);
        return await response.value();
    }

}
