import { mdiPlus } from "@mdi/js";
import { Button, Col, Empty, Row, Spin } from "antd";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import FailureViewWorbookTaskCard from "features/failures/view/components/FailureViewWorbookTaskCard";
import useFailureViewWorkbookTaskCard from "features/failures/view/components/useFailureViewWorkbookTaskCard";
import AddNewWorkbookTaskModal from "features/failures/view/repairer/components/FailureViewWorkbookTab/components/AddNewWorkbookTaskModal";
import { LumenWorkbookTaskStatuses } from "models/common";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FailureWorkbookTasksTab.module.scss";

const taskStatusesInOrder = [
  LumenWorkbookTaskStatuses.ToDo,
  LumenWorkbookTaskStatuses.Rejected,
  LumenWorkbookTaskStatuses.Done,
];

const FailureWorkbookTasksTab: React.FC = () => {
  const { failure, workbook } = useSelector((state) => state.failureView);
  const { fetchWorkbook } = useSelector(
    (state) => state.loading.effects.failureView
  );
  const { t } = useTranslation();

  const sortedWorkbookTasks = useMemo(
    () =>
      [...(workbook?.tasks || [])].sort(
        (a, b) =>
          taskStatusesInOrder.indexOf(
            a.status?.value as LumenWorkbookTaskStatuses
          ) -
          taskStatusesInOrder.indexOf(
            b.status?.value as LumenWorkbookTaskStatuses
          )
      ),
    [workbook?.tasks]
  );

  const dispatch = useDispatch();
  const {
    handleTaskCompleted,
    selectedTask,
    setWorkbookTaskStatusIsLoading,
    fetchWorkbookTaskReasonsIsLoading,
    fetchWorkbookTaskStatusesIsLoading,
    addNewTaskModalIsOpen,
    setAddNewTaskModalIsOpen,
  } = useFailureViewWorkbookTaskCard();

  useEffect(() => {
    if (typeof failure?.id === "string" && failure.contractor) {
      dispatch.failureView.fetchWorkbook(failure.id);
    }
  }, [dispatch.failureView, failure?.id, failure?.contractor]);

  return (
    <div className={styles.wrapper}>
      <Row justify="end">
        <Button
          icon={<MaterialIcon path={mdiPlus} />}
          onClick={() => setAddNewTaskModalIsOpen(true)}
          type="primary"
          disabled={!failure?.contractor}
        >
          {t("failure.addNewWorkbookTask")}
        </Button>
      </Row>

      <div className={styles.workbookTasksWrapper}>
        {workbook?.tasks?.length || fetchWorkbook.loading ? (
          <Spin
            spinning={
              fetchWorkbook.loading ||
              fetchWorkbookTaskReasonsIsLoading ||
              fetchWorkbookTaskStatusesIsLoading
            }
          >
            <div>
              <Row gutter={[16, 16]}>
                {sortedWorkbookTasks?.map((task) => (
                  <Col span={12} className={styles.col} key={task.id}>
                    <FailureViewWorbookTaskCard
                      task={task}
                      onTaskCompleted={handleTaskCompleted}
                      completeButtonIsLoading={
                        selectedTask === task.id &&
                        setWorkbookTaskStatusIsLoading
                      }
                      key={task.id}
                      deviceLayoutType="default"
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Spin>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>

      <AddNewWorkbookTaskModal
        visible={addNewTaskModalIsOpen}
        onCancel={() => setAddNewTaskModalIsOpen(false)}
        deviceLayoutType="default"
      />
    </div>
  );
};

export default FailureWorkbookTasksTab;
