/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContractorExcelVm,
    ContractorExcelVmFromJSON,
    ContractorExcelVmToJSON,
    CreateRepairmanCommand,
    CreateRepairmanCommandFromJSON,
    CreateRepairmanCommandToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllRepairmenExcelQuery,
    GetAllRepairmenExcelQueryFromJSON,
    GetAllRepairmenExcelQueryToJSON,
    GetAllRepairmenQuery,
    GetAllRepairmenQueryFromJSON,
    GetAllRepairmenQueryToJSON,
    RepairmanListItemVmPaginatedSearchResponse,
    RepairmanListItemVmPaginatedSearchResponseFromJSON,
    RepairmanListItemVmPaginatedSearchResponseToJSON,
    RepairmanVm,
    RepairmanVmFromJSON,
    RepairmanVmToJSON,
    UpdateRepairmanCommand,
    UpdateRepairmanCommandFromJSON,
    UpdateRepairmanCommandToJSON,
} from '../models';

export interface ApiRepairmenExportPostRequest {
    getAllRepairmenExcelQuery?: GetAllRepairmenExcelQuery;
}

export interface ApiRepairmenGetAllPostRequest {
    getAllRepairmenQuery?: GetAllRepairmenQuery;
}

export interface ApiRepairmenIdDeleteRequest {
    id: string;
}

export interface ApiRepairmenIdGetRequest {
    id: string;
}

export interface ApiRepairmenIdPutRequest {
    id: string;
    updateRepairmanCommand?: UpdateRepairmanCommand;
}

export interface ApiRepairmenPostRequest {
    createRepairmanCommand?: CreateRepairmanCommand;
}

/**
 * 
 */
export class RepairmenApi extends runtime.BaseAPI {

    /**
     * List of repairman as Excel file
     */
    async apiRepairmenExportPostRaw(requestParameters: ApiRepairmenExportPostRequest): Promise<runtime.ApiResponse<ContractorExcelVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Repairmen/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllRepairmenExcelQueryToJSON(requestParameters.getAllRepairmenExcelQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContractorExcelVmFromJSON(jsonValue));
    }

    /**
     * List of repairman as Excel file
     */
    async apiRepairmenExportPost(requestParameters: ApiRepairmenExportPostRequest): Promise<ContractorExcelVm> {
        const response = await this.apiRepairmenExportPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns the filtered instances for the actual query. For query description Lumen.Domain.Messages.Requests.GetAllQuery.
     */
    async apiRepairmenGetAllPostRaw(requestParameters: ApiRepairmenGetAllPostRequest): Promise<runtime.ApiResponse<RepairmanListItemVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Repairmen/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllRepairmenQueryToJSON(requestParameters.getAllRepairmenQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairmanListItemVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Returns the filtered instances for the actual query. For query description Lumen.Domain.Messages.Requests.GetAllQuery.
     */
    async apiRepairmenGetAllPost(requestParameters: ApiRepairmenGetAllPostRequest): Promise<RepairmanListItemVmPaginatedSearchResponse> {
        const response = await this.apiRepairmenGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiRepairmenIdDeleteRaw(requestParameters: ApiRepairmenIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRepairmenIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Repairmen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiRepairmenIdDelete(requestParameters: ApiRepairmenIdDeleteRequest): Promise<void> {
        await this.apiRepairmenIdDeleteRaw(requestParameters);
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiRepairmenIdGetRaw(requestParameters: ApiRepairmenIdGetRequest): Promise<runtime.ApiResponse<RepairmanVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRepairmenIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Repairmen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RepairmanVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiRepairmenIdGet(requestParameters: ApiRepairmenIdGetRequest): Promise<RepairmanVm> {
        const response = await this.apiRepairmenIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiRepairmenIdPutRaw(requestParameters: ApiRepairmenIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiRepairmenIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Repairmen/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRepairmanCommandToJSON(requestParameters.updateRepairmanCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiRepairmenIdPut(requestParameters: ApiRepairmenIdPutRequest): Promise<void> {
        await this.apiRepairmenIdPutRaw(requestParameters);
    }

    /**
     * Creates a Repairman
     */
    async apiRepairmenPostRaw(requestParameters: ApiRepairmenPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Repairmen`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRepairmanCommandToJSON(requestParameters.createRepairmanCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a Repairman
     */
    async apiRepairmenPost(requestParameters: ApiRepairmenPostRequest): Promise<string> {
        const response = await this.apiRepairmenPostRaw(requestParameters);
        return await response.value();
    }

}
