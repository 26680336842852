/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressVm,
    AddressVmFromJSON,
    AddressVmFromJSONTyped,
    AddressVmToJSON,
    ContractorVm,
    ContractorVmFromJSON,
    ContractorVmFromJSONTyped,
    ContractorVmToJSON,
    DeadlineStatusType,
    DeadlineStatusTypeFromJSON,
    DeadlineStatusTypeFromJSONTyped,
    DeadlineStatusTypeToJSON,
    DictionaryVm,
    DictionaryVmFromJSON,
    DictionaryVmFromJSONTyped,
    DictionaryVmToJSON,
    FailureCreatorTypeVm,
    FailureCreatorTypeVmFromJSON,
    FailureCreatorTypeVmFromJSONTyped,
    FailureCreatorTypeVmToJSON,
    FailureStatusVm,
    FailureStatusVmFromJSON,
    FailureStatusVmFromJSONTyped,
    FailureStatusVmToJSON,
    FailureTypeVm,
    FailureTypeVmFromJSON,
    FailureTypeVmFromJSONTyped,
    FailureTypeVmToJSON,
    NameVm,
    NameVmFromJSON,
    NameVmFromJSONTyped,
    NameVmToJSON,
    PhoneVm,
    PhoneVmFromJSON,
    PhoneVmFromJSONTyped,
    PhoneVmToJSON,
    RepairmanVm,
    RepairmanVmFromJSON,
    RepairmanVmFromJSONTyped,
    RepairmanVmToJSON,
} from './';

/**
 * 
 * @export
 * @interface FailureVm
 */
export interface FailureVm {
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof FailureVm
     */
    reportedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FailureVm
     */
    closedAt?: Date | null;
    /**
     * 
     * @type {AddressVm}
     * @memberof FailureVm
     */
    address?: AddressVm;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    identifier?: string | null;
    /**
     * 
     * @type {FailureTypeVm}
     * @memberof FailureVm
     */
    type?: FailureTypeVm;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof FailureVm
     */
    expanse?: DictionaryVm;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    description1?: string | null;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof FailureVm
     */
    nature?: DictionaryVm;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    description2?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof FailureVm
     */
    deadline?: Date;
    /**
     * 
     * @type {Date}
     * @memberof FailureVm
     */
    fixedDate?: Date;
    /**
     * 
     * @type {FailureStatusVm}
     * @memberof FailureVm
     */
    status?: FailureStatusVm;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    stateChangeReason?: string | null;
    /**
     * 
     * @type {DictionaryVm}
     * @memberof FailureVm
     */
    optimalDeadlineDays?: DictionaryVm;
    /**
     * 
     * @type {ContractorVm}
     * @memberof FailureVm
     */
    contractor?: ContractorVm;
    /**
     * 
     * @type {FailureCreatorTypeVm}
     * @memberof FailureVm
     */
    creatorType?: FailureCreatorTypeVm;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    notifierFamilyname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    notifierMiddlename?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    notifierForename?: string | null;
    /**
     * 
     * @type {PhoneVm}
     * @memberof FailureVm
     */
    notifierPhone?: PhoneVm;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    notifierEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    comment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    deviceName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    redirectPathPostfix1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    redirectPathPostfix2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    deviceExternalId?: string | null;
    /**
     * 
     * @type {DeadlineStatusType}
     * @memberof FailureVm
     */
    deadlineStatus?: DeadlineStatusType;
    /**
     * 
     * @type {string}
     * @memberof FailureVm
     */
    parentId?: string | null;
    /**
     * 
     * @type {NameVm}
     * @memberof FailureVm
     */
    creator?: NameVm;
    /**
     * 
     * @type {NameVm}
     * @memberof FailureVm
     */
    modifier?: NameVm;
    /**
     * 
     * @type {Date}
     * @memberof FailureVm
     */
    modifiedDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof FailureVm
     */
    createdDate?: Date;
    /**
     * 
     * @type {Array<RepairmanVm>}
     * @memberof FailureVm
     */
    repairmen?: Array<RepairmanVm> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FailureVm
     */
    acceptedByContractor?: boolean;
}

export function FailureVmFromJSON(json: any): FailureVm {
    return FailureVmFromJSONTyped(json, false);
}

export function FailureVmFromJSONTyped(json: any, ignoreDiscriminator: boolean): FailureVm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reportedAt': !exists(json, 'reportedAt') ? undefined : (new Date(json['reportedAt'])),
        'closedAt': !exists(json, 'closedAt') ? undefined : (json['closedAt'] === null ? null : new Date(json['closedAt'])),
        'address': !exists(json, 'address') ? undefined : AddressVmFromJSON(json['address']),
        'identifier': !exists(json, 'identifier') ? undefined : json['identifier'],
        'type': !exists(json, 'type') ? undefined : FailureTypeVmFromJSON(json['type']),
        'expanse': !exists(json, 'expanse') ? undefined : DictionaryVmFromJSON(json['expanse']),
        'description1': !exists(json, 'description1') ? undefined : json['description1'],
        'nature': !exists(json, 'nature') ? undefined : DictionaryVmFromJSON(json['nature']),
        'description2': !exists(json, 'description2') ? undefined : json['description2'],
        'deadline': !exists(json, 'deadline') ? undefined : (new Date(json['deadline'])),
        'fixedDate': !exists(json, 'fixedDate') ? undefined : (new Date(json['fixedDate'])),
        'status': !exists(json, 'status') ? undefined : FailureStatusVmFromJSON(json['status']),
        'stateChangeReason': !exists(json, 'stateChangeReason') ? undefined : json['stateChangeReason'],
        'optimalDeadlineDays': !exists(json, 'optimalDeadlineDays') ? undefined : DictionaryVmFromJSON(json['optimalDeadlineDays']),
        'contractor': !exists(json, 'contractor') ? undefined : ContractorVmFromJSON(json['contractor']),
        'creatorType': !exists(json, 'creatorType') ? undefined : FailureCreatorTypeVmFromJSON(json['creatorType']),
        'notifierFamilyname': !exists(json, 'notifierFamilyname') ? undefined : json['notifierFamilyname'],
        'notifierMiddlename': !exists(json, 'notifierMiddlename') ? undefined : json['notifierMiddlename'],
        'notifierForename': !exists(json, 'notifierForename') ? undefined : json['notifierForename'],
        'notifierPhone': !exists(json, 'notifierPhone') ? undefined : PhoneVmFromJSON(json['notifierPhone']),
        'notifierEmail': !exists(json, 'notifierEmail') ? undefined : json['notifierEmail'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'deviceName': !exists(json, 'deviceName') ? undefined : json['deviceName'],
        'redirectPathPostfix1': !exists(json, 'redirectPathPostfix1') ? undefined : json['redirectPathPostfix1'],
        'redirectPathPostfix2': !exists(json, 'redirectPathPostfix2') ? undefined : json['redirectPathPostfix2'],
        'deviceExternalId': !exists(json, 'deviceExternalId') ? undefined : json['deviceExternalId'],
        'deadlineStatus': !exists(json, 'deadlineStatus') ? undefined : DeadlineStatusTypeFromJSON(json['deadlineStatus']),
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'creator': !exists(json, 'creator') ? undefined : NameVmFromJSON(json['creator']),
        'modifier': !exists(json, 'modifier') ? undefined : NameVmFromJSON(json['modifier']),
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (json['modifiedDate'] === null ? null : new Date(json['modifiedDate'])),
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'repairmen': !exists(json, 'repairmen') ? undefined : (json['repairmen'] === null ? null : (json['repairmen'] as Array<any>).map(RepairmanVmFromJSON)),
        'acceptedByContractor': !exists(json, 'acceptedByContractor') ? undefined : json['acceptedByContractor'],
    };
}

export function FailureVmToJSON(value?: FailureVm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reportedAt': value.reportedAt === undefined ? undefined : (value.reportedAt.toISOString()),
        'closedAt': value.closedAt === undefined ? undefined : (value.closedAt === null ? null : value.closedAt.toISOString()),
        'address': AddressVmToJSON(value.address),
        'identifier': value.identifier,
        'type': FailureTypeVmToJSON(value.type),
        'expanse': DictionaryVmToJSON(value.expanse),
        'description1': value.description1,
        'nature': DictionaryVmToJSON(value.nature),
        'description2': value.description2,
        'deadline': value.deadline === undefined ? undefined : (value.deadline.toISOString()),
        'fixedDate': value.fixedDate === undefined ? undefined : (value.fixedDate.toISOString()),
        'status': FailureStatusVmToJSON(value.status),
        'stateChangeReason': value.stateChangeReason,
        'optimalDeadlineDays': DictionaryVmToJSON(value.optimalDeadlineDays),
        'contractor': ContractorVmToJSON(value.contractor),
        'creatorType': FailureCreatorTypeVmToJSON(value.creatorType),
        'notifierFamilyname': value.notifierFamilyname,
        'notifierMiddlename': value.notifierMiddlename,
        'notifierForename': value.notifierForename,
        'notifierPhone': PhoneVmToJSON(value.notifierPhone),
        'notifierEmail': value.notifierEmail,
        'comment': value.comment,
        'deviceId': value.deviceId,
        'deviceName': value.deviceName,
        'redirectPathPostfix1': value.redirectPathPostfix1,
        'redirectPathPostfix2': value.redirectPathPostfix2,
        'deviceExternalId': value.deviceExternalId,
        'deadlineStatus': DeadlineStatusTypeToJSON(value.deadlineStatus),
        'parentId': value.parentId,
        'creator': NameVmToJSON(value.creator),
        'modifier': NameVmToJSON(value.modifier),
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate === null ? null : value.modifiedDate.toISOString()),
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'repairmen': value.repairmen === undefined ? undefined : (value.repairmen === null ? null : (value.repairmen as Array<any>).map(RepairmanVmToJSON)),
        'acceptedByContractor': value.acceptedByContractor,
    };
}


