import { ModalProps, Select } from "antd";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import CustomModal from "components/ui/CustomModal";
import useAuth from "features/auth/useAuth";
import FailureNotifierFieldset from "features/failures/create/components/FailureNotifierFieldset";
import useDevicesQuery from "hooks/queries/useDevicesQuery";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, useGrapeAntdForm } from "widgets/form";
import styles from "./BulkFailureCreateModal.module.scss";
import FailureAttributesFieldset from "features/failures/create/components/FailureAttributesFieldset";

const BulkFailureCreateModal: FC<ModalProps> = ({
  visible,
  onOk,
  onCancel,
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm();
  const dispatch = useDispatch();
  const { hasRole } = useAuth();

  const { bulkCreate } = useSelector(
    (state) => state.loading.effects.failureCreate
  );

  const { selectedIds } = useSelector((state) => state.maintainedDevicesList);

  const handleCancel = useCallback(() => {
    formUtils.form.resetFields();
    onCancel?.("" as any);
  }, [formUtils.form, onCancel]);

  const handleFinish = useCallback(
    async (values: any) => {
      await dispatch.failureCreate.bulkCreate({
        ...values,
        deviceIds: values?.devices,
      });

      onOk?.("" as any);
      handleCancel();
    },
    [dispatch.failureCreate, onOk, handleCancel]
  );

  const devicesQuery = useDevicesQuery();

  const devices = useMemo(() => {
    return devicesQuery.data?.items?.map((item) => ({
      label: item.name,
      value: item.id,
    }));
  }, [devicesQuery.data?.items]);

  useEffect(() => {
    if (visible) {
      dispatch.failureCreate.fetchFailureTypes();
      formUtils.form.setFieldsValue(devices);
    }
  }, [dispatch.failureCreate, visible, formUtils.form, devices, selectedIds]);

  return (
    <CustomModal
      visible={visible}
      onCancel={handleCancel}
      title={t("maintainedDevice.bulkFailureCreateModal.title")}
      onOk={formUtils.form.submit}
      destroyOnClose
      okButtonProps={{ loading: bulkCreate.loading }}
      cancelButtonProps={{ disabled: bulkCreate.loading }}
      formInstance={formUtils.form}
      size="xl"
    >
      <p className="text-center">{t("failure.createModal.description")}</p>

      <CustomForm formUtils={formUtils} onFinish={handleFinish}>
        <GrapeAntdForm.Item
          name="devices"
          label={t("maintainedDevice.bulkFailureCreateModal.devices")}
        >
          <Select
            mode="multiple"
            size="large"
            allowClear
            defaultValue={devices?.filter((item) =>
              selectedIds.includes(item?.value ?? "")
            )}
            options={devices}
            className={styles.devicesSelect}
          />
        </GrapeAntdForm.Item>

        <FailureAttributesFieldset />

        {hasRole(["CallCenter"]) && (
          <FailureNotifierFieldset isEmailRequired={false} />
        )}
      </CustomForm>
    </CustomModal>
  );
};

export default BulkFailureCreateModal;
