import { mdiPencil } from "@mdi/js";
import { Button, Input, ModalProps, Row } from "antd";
import {
  ContactPersonVm,
  SlaVm,
  FailureTypeVm,
  TownVm,
  UpdateTownCommand,
} from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import CustomForm from "components/form/CustomForm";
import { MaterialIcon } from "components/MaterialIcon";
import CustomModal from "components/ui/CustomModal";
import useEditFormModal from "hooks/useEditModal";
import { ItemProps, mapPublicTownVmToExtendedSelectValue } from "models/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrapeAntdForm, useGrapeAntdForm } from "widgets/form";
import { mapToUpdateTownCommand, TownCreateFormValues } from "../models";
import BaseDataFieldset from "./components/BaseDataFieldset";
import ContactsFieldset from "./components/ContactsFieldset";
import MunicipalityFieldset from "./components/MunicipalityFieldset";
import SLAFieldset from "./components/SLAFieldset";
import { ListRequestParams } from "widgets/table/useTableUtils";

export interface TownCreateModalProps extends ModalProps {
  fetchDataParams?: ListRequestParams;
}

const TownCreateModal: React.FC<TownCreateModalProps> = ({
  onCancel,
  visible,
  fetchDataParams,
  ...rest
}) => {
  const { t } = useTranslation();
  const formUtils = useGrapeAntdForm<TownCreateFormValues>("UpdateTownCommand");

  const dispatch = useDispatch();
  const { editingItemId, failureTypes } = useSelector(
    (state) => state.townCreate
  );

  const { list } = useSelector((state) => state.townList);
  const { slenderList } = useSelector((state) => state.townCreate);
  const { create, edit, fetchAllSlender } = useSelector(
    (state) => state.loading.effects.townCreate
  );
  const [selectedTownId, setSelectedTownId] = useState<string>();

  const getInitialValues = useCallback(
    (itemData: TownVm): TownCreateFormValues => {
      const townVm = (slenderList || []).find(
        (item) => item.name === itemData.municipalityAddress?.city
      );

      return {
        ...itemData,
        id: itemData.id || "",
        name: itemData.name || "",
        municipalityAddress: {
          ...itemData.municipalityAddress,
          zip: itemData.municipalityAddress?.zip,
          city: mapPublicTownVmToExtendedSelectValue(townVm),
          street: itemData.municipalityAddress?.street || "",
          number: itemData.municipalityAddress?.number || "",
        },
        contactPersons: [
          ...(itemData.contactPersons?.length
            ? itemData.contactPersons.map((person: ContactPersonVm) => ({
                ...person,
                familyname: person.familyname || "",
                forename: person.forename || "",
                email: person.email || "",
                phone: {
                  number: person.phone?.number || "",
                },
              }))
            : [
                {
                  familyname: "",
                  forename: "",
                  email: "",
                  phone: {
                    number: "",
                  },
                },
              ]),
        ],
        municipalityName: itemData.municipalityName || "",
        slas:
          itemData.slas?.map((sla: SlaVm) => ({
            deadlineDays: sla.deadlineDays + "",
            failureTypes:
              sla.failureTypes?.map(
                (failureType: FailureTypeVm) => failureType.id || ""
              ) || [],
            slaCategoryId: sla.slaCategoryId || "",
            id: sla.id,
            optimalDeadlineDaysId: sla.optimalDeadlineDaysId || "",
          })) || [],
        email: itemData.email || "",
        phone: {
          number: itemData.phone?.number || "",
        },
        sectionActionId: itemData.sectionActionId || "",
        sectionFailureEmailTo: itemData.sectionFailureEmailTo?.split(";"),
      };
    },
    [slenderList]
  );

  const { formMode, setFormMode } = useEditFormModal<
    TownVm,
    TownCreateFormValues
  >({
    list,
    formUtils,
    getInitialValues,
    modalIsVisible: visible,
    resetItemId: dispatch.townCreate.setEditingItemId,
    itemId: editingItemId,
  });

  const notActivatedTownsOptions = useMemo<ItemProps[]>(
    () =>
      (slenderList || [])
        .filter((item) => !item.isActivated)
        .map((type) => ({
          label: type.name || "",
          value: type.id || "",
        })),
    [slenderList]
  );

  const handleFinish = useCallback(
    async (values: TownCreateFormValues): Promise<void> => {
      if (formMode === "Create") {
        await dispatch.townCreate.create({
          id: selectedTownId,
          values: mapToUpdateTownCommand(values, failureTypes),
        });
      }

      if (formMode === "Edit" && typeof editingItemId === "string") {
        await dispatch.townCreate.edit(
          mapToUpdateTownCommand(values, failureTypes)
        );
      }

      dispatch.townList.fetchList(fetchDataParams);

      onCancel?.("" as any);
      formUtils.form.resetFields();
    },
    [
      dispatch.townCreate,
      dispatch.townList,
      editingItemId,
      failureTypes,
      fetchDataParams,
      formMode,
      formUtils.form,
      onCancel,
      selectedTownId,
    ]
  );

  const handleTownChange = useCallback(
    (value: any) => {
      const town = notActivatedTownsOptions.find(
        (option) => option.value === value
      );

      formUtils.form.setFieldsValue({
        id: value,
        municipalityAddress: {
          city: {
            key: town?.value || "",
            label: town?.label || "",
            value: town?.value || "",
          },
          street: "",
          number: "",
        },
        name: notActivatedTownsOptions.find((option) => option.value === value)
          ?.label,
      });

      setSelectedTownId(value);
    },
    [formUtils.form, notActivatedTownsOptions]
  );

  const handleModalOk = useCallback(() => {
    formUtils.form.submit();
  }, [formUtils.form]);

  const formInitialValues = useMemo<Partial<UpdateTownCommand>>(
    () => ({
      isActivated: true,
    }),
    []
  );

  useEffect(() => {
    if (!visible) {
      setSelectedTownId(undefined);
    } else {
      dispatch.townCreate.fetchFailureTypes();
      dispatch.townCreate.fetchAllSlender();
    }
  }, [dispatch.dictionaries, dispatch.townCreate, visible]);

  return (
    <CustomModal
      {...rest}
      onCancel={onCancel}
      title={
        formMode === "Create"
          ? t("town.createModal.title")
          : formMode === "Edit"
          ? t("town.editModal.title")
          : t("town.modal.title")
      }
      size="xl"
      onOk={handleModalOk}
      destroyOnClose
      okButtonProps={{
        loading: create.loading || edit.loading,
        disabled: formMode === "View",
      }}
      cancelButtonProps={{ disabled: create.loading || edit.loading }}
      formInstance={formUtils.form}
      visible={visible}
    >
      <CustomForm
        formUtils={formUtils}
        onFinish={handleFinish}
        initialValues={formInitialValues}
        viewMode={formMode === "View"}
      >
        <GrapeAntdForm.Item name="isActivated" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        <GrapeAntdForm.Item name="id" hidden>
          <Input hidden />
        </GrapeAntdForm.Item>

        {formMode === "View" && (
          <Row justify="end">
            <Button
              icon={<MaterialIcon path={mdiPencil} />}
              onClick={() => setFormMode("Edit")}
            >
              {t("common.edit")}
            </Button>
          </Row>
        )}

        <Row gutter={[0, 48]}>
          <BaseDataFieldset
            formMode={formMode}
            formUtils={formUtils}
            notActivatedTownsOptions={notActivatedTownsOptions}
            notActivatedTownsOptionsLoading={fetchAllSlender.loading}
            handleTownChange={handleTownChange}
            visible={visible}
          />

          {(selectedTownId || editingItemId) && (
            <>
              <MunicipalityFieldset />
              <ContactsFieldset formMode={formMode} formUtils={formUtils} />
              <SLAFieldset formMode={formMode} formUtils={formUtils} />
            </>
          )}
        </Row>
      </CustomForm>
    </CustomModal>
  );
};

export default TownCreateModal;
