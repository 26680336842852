/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionDto
 */
export interface VersionDto {
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    version?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionDto
     */
    mainVersion?: string | null;
}

export function VersionDtoFromJSON(json: any): VersionDto {
    return VersionDtoFromJSONTyped(json, false);
}

export function VersionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
        'mainVersion': !exists(json, 'mainVersion') ? undefined : json['mainVersion'],
    };
}

export function VersionDtoToJSON(value?: VersionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'mainVersion': value.mainVersion,
    };
}


