import { TabPaneProps, Tabs } from "antd";
import useFeature from "hooks/useFeature";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ExamTypeList from "./tabs/exam-type-tab/list/ExamTypeList";
import FailureTaskList from "./tabs/failure-task-tab/list/FailureTaskList";
import InventoryCategoryList from "./tabs/inventory-category-tab/list/InventoryCategoryList";
import TabsWrapper from "components/ui/TabsWrapper";
import SpecialPlacesList from "features/special-places/list/SpecialPlacesList";
import TownsList from "features/towns/list/TownList";
import FailureTypesList from "features/failure-types/list/FailureTypesList";
import DataImport from "features/data-import/DataImport";
import { useSearchParams } from "react-router-dom";
import ListPageLayout from "components/layout/ListPageLayout";

type TabKey =
  | "inventoryCategory"
  | "failureTask"
  | "examType"
  | "specialPlaces"
  | "towns"
  | "failureTypes"
  | "dataImport";
interface CustomTabPaneProps extends Omit<TabPaneProps, "key"> {
  key: TabKey;
}

const ManageDictionaries = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams("");
  const activeTabParam = searchParams.get("tab");

  const { isEnabled: isExamFeatureEnabled } = useFeature("exam");

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const tabs = useMemo<CustomTabPaneProps[]>(
    () => [
      {
        tab: t("dictionaries.inventoryCategory.tabLabel"),
        children: <InventoryCategoryList />,
        key: "inventoryCategory",
      },
      {
        tab: t("dictionaries.failureTask.tabLabel"),
        children: <FailureTaskList />,
        key: "failureTask",
      },
      ...((isExamFeatureEnabled
        ? [
            {
              tab: t("dictionaries.examType.tabLabel"),
              children: <ExamTypeList />,
              key: "examType",
            },
          ]
        : []) as CustomTabPaneProps[]),
      ...((isMaintainedDevicesFeatureEnabled
        ? [
            {
              tab: t("dictionaries.specialPlaces.tabLabel"),
              children: <SpecialPlacesList />,
              key: "specialPlaces",
            },
          ]
        : [
            {
              tab: t("dictionaries.towns.tabLabel"),
              children: <TownsList />,
              key: "towns",
            },
          ]) as CustomTabPaneProps[]),
      {
        tab: t("dictionaries.failureTypes.tabLabel"),
        children: <FailureTypesList />,
        key: "failureTypes",
      },
      ...((!isMaintainedDevicesFeatureEnabled
        ? [
            {
              tab: t("dictionaries.dataImport.tabLabel"),
              children: <DataImport />,
              key: "dataImport",
            },
          ]
        : []) as CustomTabPaneProps[]),
    ],
    [isExamFeatureEnabled, isMaintainedDevicesFeatureEnabled, t]
  );

  const defaultActiveKey = useMemo(() => {
    const activeTab = tabs.find((tab) => tab.key === activeTabParam);

    return activeTab ? activeTab.key : tabs[0].key;
  }, [activeTabParam, tabs]);

  const handleTabsChange = useCallback(
    (activeKey: string) => {
      setSearchParams(new URLSearchParams(`tab=${activeKey}`));
    },
    [setSearchParams]
  );

  return (
    <ListPageLayout title={t("pageTitle.dictionaries")}>
      <TabsWrapper>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          destroyInactiveTabPane
          onChange={handleTabsChange}
          type="card"
          size="small"
        >
          {tabs.map(({ tab, key, children }) => (
            <Tabs.TabPane tab={tab} key={key} tabKey={key}>
              {children}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </TabsWrapper>
    </ListPageLayout>
  );
};

export default ManageDictionaries;
