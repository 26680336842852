/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Role,
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface RolePermission
 */
export interface RolePermission {
    /**
     * 
     * @type {string}
     * @memberof RolePermission
     */
    roleId?: string;
    /**
     * 
     * @type {string}
     * @memberof RolePermission
     */
    permission?: string | null;
    /**
     * 
     * @type {Role}
     * @memberof RolePermission
     */
    role?: Role;
}

export function RolePermissionFromJSON(json: any): RolePermission {
    return RolePermissionFromJSONTyped(json, false);
}

export function RolePermissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): RolePermission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'permission': !exists(json, 'permission') ? undefined : json['permission'],
        'role': !exists(json, 'role') ? undefined : RoleFromJSON(json['role']),
    };
}

export function RolePermissionToJSON(value?: RolePermission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roleId': value.roleId,
        'permission': value.permission,
        'role': RoleToJSON(value.role),
    };
}


