import { mdiArrowRight, mdiCheckBold, mdiEye, mdiMenuDown } from "@mdi/js";
import { Button, Dropdown, Menu, Space, notification } from "antd";
import { DeadlineStatusType, FailureListItemVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import { Permission } from "components/Permission";
import TableHeaderOptions from "components/table/TableHeaderOptions";
import IconButton from "components/ui/IconButton";
import Claims from "features/auth/claims";
import useAuth from "features/auth/useAuth";
import FailureAssignModal, {
  FailureAssignType,
} from "features/failures/assign/FailureAssignModal";
import { FailureListRequestParams } from "features/failures/models";
import { getProperPageParamAfterFailureAttach } from "features/failures/utils";
import useFeature from "hooks/useFeature";
import useTable from "hooks/useTable";
import _merge from "lodash.merge";
import { FailureStatus } from "models/common";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import { ExtendedColumnType } from "widgets/table/table-settings/ExtendedColumnType";
import { ListRequestParams } from "widgets/table/useTableUtils";
import { FailureBulkAssignModal } from "../bulk-actions/FailureBulkAssignModal";
import useFailuresListColumnTitles from "../useFailuresListColumnTitles";
import styles from "./FailuresTable.module.scss";
import useFailureTableColumns from "./useFailureTableColumns";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFunc = () => {};

const closedStatuses: FailureStatus[] = [
  "SectionFailure",
  "DidNotFix",
  "Fixed",
];

interface FailuresTableProps {
  fetchDataParams?: FailureListRequestParams;
  failureListType?: "toAttend";
}

const FailuresTable: React.FC<FailuresTableProps> = ({
  fetchDataParams,
  failureListType,
}) => {
  const { listParams, list, collectedFailureList, selectedIds } = useSelector(
    (state) => state.failureList
  );
  const { fetchList, generateExcel, attach } = useSelector(
    (state) => state.loading.effects.failureList
  );
  const { isSaved: isFailureCreateModalSaved } = useSelector(
    (state) => state.failureCreate
  );
  const { userIsAdmin, hasRole } = useAuth();

  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [bulkAssignModalOpen, setBulkAssignModalOpen] = useState(false);
  const [assignType, setAssignType] = useState<FailureAssignType>("repairmen");
  const [resetSwitch, setResetSwitch] = useState(false); // Used to reset table selection

  const { columnParams } = useFailureTableColumns();

  const { isEnabled: isContractorAcceptOfTaskAssignment } = useFeature(
    "contractorAcceptOfTaskAssignment"
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { exportColumnTitles } = useFailuresListColumnTitles();

  const location = useLocation();

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/failures/${id}`, {
        state: { failuresListPageSearchParams: location.search },
      });
    },
    [location.search, navigate]
  );

  const handleAcceptedByContractorButtonClick = useCallback(
    async (failureIds?: string[]) => {
      await dispatch.failureList.updateFailuresAcceptByContractor({
        failureIds,
      });
      await dispatch.failureList.fetchList(listParams);
    },
    [dispatch.failureList, listParams]
  );

  const handleAssignButtonClick = useCallback(
    (id?: string) => {
      setAssignModalOpen(true);
      dispatch.failureAssign.setEditingItemId(id);
    },
    [dispatch.failureAssign]
  );

  const handleNewButtonClick = useCallback(() => {
    dispatch.failureCreate.setIsVisible(true);
  }, [dispatch.failureCreate]);

  const handleExportButtonClick = useCallback(() => {
    dispatch.failureList.generateExcel(exportColumnTitles);
  }, [exportColumnTitles, dispatch.failureList]);

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<FailureListItemVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: isContractorAcceptOfTaskAssignment ? 135 : 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />
            {isContractorAcceptOfTaskAssignment && (
              <IconButton
                path={mdiCheckBold}
                onClick={() => {
                  record.id &&
                    handleAcceptedByContractorButtonClick([record.id]);
                }}
                title={t("failure.acceptedByContractor")}
              />
            )}
            <Dropdown
              trigger={["click"]}
              overlay={
                userIsAdmin ? (
                  <Menu
                    items={[
                      {
                        label: t("failure.assignTo.contractor"),
                        key: "assignToContractor",
                        onClick: () => {
                          setAssignType("contractor");

                          handleAssignButtonClick(record.id);
                        },
                        disabled: (closedStatuses as string[]).includes(
                          record.status?.value || ""
                        ),
                      },
                      {
                        label: t("failure.assignTo.repairmen"),
                        key: "assignToRepairmen",
                        onClick: () => {
                          setAssignType("repairmen");

                          handleAssignButtonClick(record.id);
                        },
                        disabled:
                          !record.contractor ||
                          (closedStatuses as string[]).includes(
                            record.status?.value || ""
                          ),
                      },
                    ]}
                  />
                ) : (
                  <></>
                )
              }
            >
              <IconButton
                path={mdiArrowRight}
                iconClassname={styles.assignButtonIcon}
                onClick={() =>
                  !userIsAdmin // If user is admin, he needs to choose menu item from dropdown first
                    ? handleAssignButtonClick(record.id)
                    : {}
                }
                title={t("failure.assignModal.title")}
              />
            </Dropdown>
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [
      handleAcceptedByContractorButtonClick,
      handleAssignButtonClick,
      handleViewButtonClick,
      isContractorAcceptOfTaskAssignment,
      t,
      userIsAdmin,
    ]
  );

  const fetchAdditionalData = useCallback(() => {
    dispatch.failureList.fetchFailureTypes();
  }, [dispatch.failureList]);

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.failureList.fetchList(_merge(requestParams, fetchDataParams)),
    [dispatch.failureList, fetchDataParams]
  );

  const handleTableSelectedChange = useCallback(
    (selectedIds: string[]) => {
      dispatch.failureList.setSelectedIds(selectedIds);
    },
    [dispatch.failureList]
  );

  const handleBulkAttach = useCallback(async () => {
    await dispatch.failureList.attach(selectedIds);

    setResetSwitch(true);

    await dispatch.failureList.fetchList({
      ...listParams,
      page: getProperPageParamAfterFailureAttach(selectedIds, listParams),
    });

    setResetSwitch(false);
  }, [dispatch.failureList, listParams, selectedIds]);

  const bulkAssign = useCallback(
    async (type: FailureAssignType) => {
      // TODO: leave the closed status check for backend. Pass only the ids to the modal, and get data there
      const selectedFailures = collectedFailureList.filter(
        ({ id }) => typeof id === "string" && selectedIds.includes(id)
      );

      const closedFailure = selectedFailures.find((item) =>
        (closedStatuses as string[]).includes(item.status?.value ?? "")
      );
      if (closedFailure) {
        notification.error({
          message: t("failure.bulkActions.cantAssign.containsClosedFailure"),
        });
        return;
      }

      dispatch.failureBulkAssign.setAssignableFailures(selectedFailures);

      setAssignType(type);
      setBulkAssignModalOpen(true);
    },
    [collectedFailureList, dispatch.failureBulkAssign, selectedIds, t]
  );

  const bulkAccept = useCallback(async () => {
    const failureIds = collectedFailureList
      .filter(({ id }) => typeof id === "string" && selectedIds.includes(id))
      .map(({ id }) => id || "");

    await dispatch.failureList.updateFailuresAcceptByContractor({
      failureIds,
    });

    setResetSwitch(true);

    await dispatch.failureList.fetchList(listParams);

    setResetSwitch(false);
  }, [collectedFailureList, dispatch.failureList, listParams, selectedIds]);

  const handleBulkAssignToContractor = useCallback(async () => {
    await bulkAssign("contractor");
  }, [bulkAssign]);

  const handleBulkAssignToRepairmen = useCallback(async () => {
    await bulkAssign("repairmen");
  }, [bulkAssign]);

  const handleBulkAcceptedByContractor = useCallback(async () => {
    await bulkAccept();
  }, [bulkAccept]);

  const bulkActionsMenu = (
    <Menu
      items={
        // Logged in contractors should not have the abbility to assign failures to (other) contractor
        !hasRole(["Contractor"])
          ? [
              {
                label: t("failure.bulkActions.attach"),
                key: "attach",
                onClick: () => handleBulkAttach(),
              },
              {
                label: t("failure.bulkActions.assignToContractor"),
                key: "assignToContractor",
                onClick: () => handleBulkAssignToContractor(),
              },
              {
                label: t("failure.bulkActions.assignToRepairmen"),
                key: "assignToRepairmen",
                onClick: () => handleBulkAssignToRepairmen(),
              },
              ...(isContractorAcceptOfTaskAssignment
                ? [
                    {
                      label: t("failure.acceptedByContractor"),
                      key: "acceptedByContractor",
                      onClick: () => handleBulkAcceptedByContractor(),
                    },
                  ]
                : []),
            ]
          : [
              {
                label: t("failure.bulkActions.attach"),
                key: "attach",
                onClick: () => handleBulkAttach(),
              },
              {
                label: t("failure.bulkActions.assignToRepairmen"),
                key: "assignToRepairmen",
                onClick: () => handleBulkAssignToRepairmen(),
              },
              ...(isContractorAcceptOfTaskAssignment
                ? [
                    {
                      label: t("failure.acceptedByContractor"),
                      key: "acceptedByContractor",
                      onClick: () => handleBulkAcceptedByContractor(),
                    },
                  ]
                : []),
            ]
      }
    />
  );

  const customTableProps = useTable({
    fetchTableData,
    fetchAdditionalData,
    columnParams,
    actionColumnParams: hasRole([
      "LumenAdmin",
      "Root_SUPER_ADMIN",
      "Contractor",
    ])
      ? actionColumnParams
      : undefined,
    listParams,
    list,
    columnStorageName: ColumnStorageName.FAILURES,
    headerOptions: (
      <Space>
        <Permission
          acceptableRoles={["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"]}
        >
          <Dropdown
            overlay={bulkActionsMenu}
            trigger={["click"]}
            disabled={selectedIds.length < 2}
          >
            <Button>
              <span>{t("failure.bulkActions.placeholder")}</span>
              <MaterialIcon
                path={mdiMenuDown}
                className={styles.bulkActionsIcon}
              />
            </Button>
          </Dropdown>
        </Permission>

        <TableHeaderOptions
          onNewButtonClick={handleNewButtonClick}
          onExportButtonClick={handleExportButtonClick}
          exportLoading={generateExcel.loading}
          addNewClaims={[Claims.Failures.CREATE]}
          exportRoles={[
            "LumenAdmin",
            "Root_SUPER_ADMIN",
            "Contractor",
            "Council",
          ]}
        />
      </Space>
    ),
    resetListParams: dispatch.failureList.resetListParams,
    loading: fetchList.loading || attach.loading,
    resetStore: emptyFunc, // store gets reseted at FailuresList, because of TabPane change delay
  });

  const getRowClassName = useCallback(
    (record: FailureListItemVm, index: number) => {
      switch (record.deadlineStatus) {
        case DeadlineStatusType.Expired:
          return styles.expiredDeadlineRow;

        case DeadlineStatusType.NearDeadline:
          return styles.nearDeadlineRow;

        default:
          return "";
      }
    },
    []
  );

  useEffect(() => {
    if (isFailureCreateModalSaved) {
      dispatch.failureList.fetchList(listParams);
    }
  }, [dispatch.failureList, listParams, isFailureCreateModalSaved]);

  return (
    <>
      <CustomTable<FailureListItemVm>
        {...customTableProps}
        onSelectedChange={handleTableSelectedChange}
        resetSwitch={resetSwitch}
        selectable={hasRole(["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"])}
        rowClassName={getRowClassName}
        pageHasTabs
      />
      <FailureAssignModal
        visible={assignModalOpen}
        onCancel={() => setAssignModalOpen(false)}
        type={assignType}
        fetchDataParams={listParams}
        failureListType={failureListType}
      />

      <FailureBulkAssignModal
        visible={bulkAssignModalOpen}
        onCancel={() => setBulkAssignModalOpen(false)}
        type={assignType}
        fetchDataParams={listParams}
        failureListType={failureListType}
      />
    </>
  );
};

export default FailuresTable;
