import { Typography } from "antd";
import StretchToCardEdge from "components/StretchToCardEdge";
import { FC, ReactNode } from "react";
import styles from "./ListPageLayout.module.scss";
import CardContent from "components/ui/CardContent";
import clsx from "clsx";

interface ListPageLayoutProps {
  title: string;
  children?: ReactNode;
}

const ListPageLayout: FC<ListPageLayoutProps> = ({ children, title }) => {
  return (
    <CardContent className={clsx(styles.card)}>
      <Typography.Title level={2}>{title}</Typography.Title>
      <StretchToCardEdge className={styles.maxHeight}>
        {children}
      </StretchToCardEdge>
    </CardContent>
  );
};

export default ListPageLayout;
