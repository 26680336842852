/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateFailureTypeCommand,
    CreateFailureTypeCommandFromJSON,
    CreateFailureTypeCommandToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    FailureTypeVm,
    FailureTypeVmFromJSON,
    FailureTypeVmToJSON,
    FailureTypeVmPaginatedSearchResponse,
    FailureTypeVmPaginatedSearchResponseFromJSON,
    FailureTypeVmPaginatedSearchResponseToJSON,
    GetAllFailureTypesExcelQuery,
    GetAllFailureTypesExcelQueryFromJSON,
    GetAllFailureTypesExcelQueryToJSON,
    GetAllFailureTypesPubliclyQuery,
    GetAllFailureTypesPubliclyQueryFromJSON,
    GetAllFailureTypesPubliclyQueryToJSON,
    GetAllFailureTypesQuery,
    GetAllFailureTypesQueryFromJSON,
    GetAllFailureTypesQueryToJSON,
    PublicFailureTypeVmPaginatedSearchResponse,
    PublicFailureTypeVmPaginatedSearchResponseFromJSON,
    PublicFailureTypeVmPaginatedSearchResponseToJSON,
    TownExcelVm,
    TownExcelVmFromJSON,
    TownExcelVmToJSON,
    UpdateFailureTypeCommand,
    UpdateFailureTypeCommandFromJSON,
    UpdateFailureTypeCommandToJSON,
} from '../models';

export interface ApiFailureTypesExportPostRequest {
    getAllFailureTypesExcelQuery?: GetAllFailureTypesExcelQuery;
}

export interface ApiFailureTypesGetAllPostRequest {
    getAllFailureTypesQuery?: GetAllFailureTypesQuery;
}

export interface ApiFailureTypesGetAllPublicPostRequest {
    getAllFailureTypesPubliclyQuery?: GetAllFailureTypesPubliclyQuery;
}

export interface ApiFailureTypesIdDeleteRequest {
    id: string;
}

export interface ApiFailureTypesIdGetRequest {
    id: string;
}

export interface ApiFailureTypesIdPutRequest {
    id: string;
    updateFailureTypeCommand?: UpdateFailureTypeCommand;
}

export interface ApiFailureTypesPostRequest {
    createFailureTypeCommand?: CreateFailureTypeCommand;
}

/**
 * 
 */
export class FailureTypesApi extends runtime.BaseAPI {

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of failure types as Excel file
     */
    async apiFailureTypesExportPostRaw(requestParameters: ApiFailureTypesExportPostRequest): Promise<runtime.ApiResponse<TownExcelVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/failure-types/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllFailureTypesExcelQueryToJSON(requestParameters.getAllFailureTypesExcelQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TownExcelVmFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of failure types as Excel file
     */
    async apiFailureTypesExportPost(requestParameters: ApiFailureTypesExportPostRequest): Promise<TownExcelVm> {
        const response = await this.apiFailureTypesExportPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Failure types list
     */
    async apiFailureTypesGetAllPostRaw(requestParameters: ApiFailureTypesGetAllPostRequest): Promise<runtime.ApiResponse<FailureTypeVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/failure-types/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllFailureTypesQueryToJSON(requestParameters.getAllFailureTypesQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FailureTypeVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Failure types list
     */
    async apiFailureTypesGetAllPost(requestParameters: ApiFailureTypesGetAllPostRequest): Promise<FailureTypeVmPaginatedSearchResponse> {
        const response = await this.apiFailureTypesGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get failure types publicly. Yes, get by Post.
     */
    async apiFailureTypesGetAllPublicPostRaw(requestParameters: ApiFailureTypesGetAllPublicPostRequest): Promise<runtime.ApiResponse<PublicFailureTypeVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/failure-types/GetAllPublic`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllFailureTypesPubliclyQueryToJSON(requestParameters.getAllFailureTypesPubliclyQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PublicFailureTypeVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * Get failure types publicly. Yes, get by Post.
     */
    async apiFailureTypesGetAllPublicPost(requestParameters: ApiFailureTypesGetAllPublicPostRequest): Promise<PublicFailureTypeVmPaginatedSearchResponse> {
        const response = await this.apiFailureTypesGetAllPublicPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiFailureTypesIdDeleteRaw(requestParameters: ApiFailureTypesIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFailureTypesIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/failure-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiFailureTypesIdDelete(requestParameters: ApiFailureTypesIdDeleteRequest): Promise<void> {
        await this.apiFailureTypesIdDeleteRaw(requestParameters);
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiFailureTypesIdGetRaw(requestParameters: ApiFailureTypesIdGetRequest): Promise<runtime.ApiResponse<FailureTypeVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFailureTypesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/failure-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FailureTypeVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiFailureTypesIdGet(requestParameters: ApiFailureTypesIdGetRequest): Promise<FailureTypeVm> {
        const response = await this.apiFailureTypesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiFailureTypesIdPutRaw(requestParameters: ApiFailureTypesIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiFailureTypesIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/failure-types/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFailureTypeCommandToJSON(requestParameters.updateFailureTypeCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiFailureTypesIdPut(requestParameters: ApiFailureTypesIdPutRequest): Promise<void> {
        await this.apiFailureTypesIdPutRaw(requestParameters);
    }

    /**
     * Creates a Failure type
     */
    async apiFailureTypesPostRaw(requestParameters: ApiFailureTypesPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/failure-types`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFailureTypeCommandToJSON(requestParameters.createFailureTypeCommand),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates a Failure type
     */
    async apiFailureTypesPost(requestParameters: ApiFailureTypesPostRequest): Promise<string> {
        const response = await this.apiFailureTypesPostRaw(requestParameters);
        return await response.value();
    }

}
