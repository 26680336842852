import {
  mdiAccountHardHat,
  mdiAlert,
  mdiBookAlphabet,
  mdiChartBar,
  mdiCog,
  mdiOfficeBuilding,
  mdiStore,
  mdiWrench,
} from "@mdi/js";
import { MaterialIcon } from "components/MaterialIcon";
import { Permission } from "components/Permission";
import useAuth from "features/auth/useAuth";
import useFeature from "hooks/useFeature";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  SidebarDivider,
  SidebarMenu,
  SideMenuOptionProps,
} from "widgets/layout/sidebar-header-layout";

const SidebarMainMenu: React.FC = () => {
  const { t } = useTranslation();
  const { hasRole } = useAuth();

  const { isEnabled: isMaintainedDevicesFeatureEnabled } =
    useFeature("maintainedDevices");

  const group1Options = useMemo<SideMenuOptionProps[]>(
    () => [
      {
        label: t("menu.failures"),
        link: "/failures",
        icon: <MaterialIcon path={mdiAlert} />,
      },
      {
        label: t("menu.contractors"),
        icon: <MaterialIcon path={mdiOfficeBuilding} />,
        link: "/contractors",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
      },
      {
        label: t("menu.repairmen"),
        icon: <MaterialIcon path={mdiAccountHardHat} />,
        link: "/repairmen",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"],
      },
      {
        label: t("menu.inventory"),
        icon: <MaterialIcon path={mdiStore} />,
        link: "/inventory",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
      },
      ...(isMaintainedDevicesFeatureEnabled
        ? [
            {
              label: t("menu.maintainedDevices"),
              icon: <MaterialIcon path={mdiWrench} />,
              link: "/maintained-devices",
              roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
            } as SideMenuOptionProps,
          ]
        : []),
    ],
    [isMaintainedDevicesFeatureEnabled, t]
  );

  const group2Options = useMemo<SideMenuOptionProps[]>(
    () => [
      {
        label: t("menu.report"),
        icon: <MaterialIcon path={mdiChartBar} />,
        link: "/report",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN", "Contractor"],
      },
      {
        label: t("menu.dictionaries"),
        icon: <MaterialIcon path={mdiBookAlphabet} />,
        link: "/dictionaries",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
      },
      {
        label: t("menu.settings"),
        icon: <MaterialIcon path={mdiCog} />,
        link: "/settings",
        roles: ["LumenAdmin", "Root_SUPER_ADMIN"],
      },
    ],
    [t]
  );

  return (
    <>
      <SidebarMenu options={group1Options} hasRole={hasRole} />

      <Permission acceptableRoles={["LumenAdmin", "Root_SUPER_ADMIN"]}>
        <SidebarDivider />
        <SidebarMenu options={group2Options} hasRole={hasRole} />
      </Permission>
    </>
  );
};

export default SidebarMainMenu;
