import { useSelector } from "app/store";
import React from "react";
import { SidebarHeaderLayout } from "widgets/layout/sidebar-header-layout";
import Collapser from "./components/Collapser";
import PrivateMobileLayout from "./private-mobile-layout/PrivateMobileLayout";
import SidebarFooterMenu from "./components/SidebarFooterMenu";
import SidebarMainMenu from "./components/SidebarMainMenu";
import Breadcrumbs from "../Breadcrumbs";
import Header from "./components/Header";
import useAuth from "features/auth/useAuth";
import DefaultLayout from "../DefaultLayout";

const PrivateLayout: React.FC = () => {
  const { deviceLayoutType, breadCrumbs } = useSelector(
    (state) => state.common
  );

  const { userIsLoggedIn } = useAuth();

  if (!userIsLoggedIn) {
    return <DefaultLayout />;
  }

  return deviceLayoutType === "mobile" ? (
    <PrivateMobileLayout />
  ) : (
    <SidebarHeaderLayout
      headerChildren={<Header />}
      collapser={<Collapser />}
      sidebarChildren={<SidebarMainMenu />}
      sidebarFooter={<SidebarFooterMenu />}
      aboveMainContent={<Breadcrumbs items={breadCrumbs} />}
    />
  );
};

export default PrivateLayout;
