import { useQuery } from "@tanstack/react-query";
import { api } from "api";
import { MILISECONDS_24H } from "models/constants";
import { queryKeys } from ".";

const useConfigurationQuery = () => {
  const query = useQuery(
    queryKeys.information.features,
    () => api.lumen.information.apiInformationConfigurationGet(),
    {
      cacheTime: MILISECONDS_24H,
      staleTime: MILISECONDS_24H,
    }
  );

  return query;
};

export default useConfigurationQuery;
