/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcceptByContractorCommand
 */
export interface AcceptByContractorCommand {
    /**
     * 
     * @type {Array<string>}
     * @memberof AcceptByContractorCommand
     */
    failureIds?: Array<string> | null;
}

export function AcceptByContractorCommandFromJSON(json: any): AcceptByContractorCommand {
    return AcceptByContractorCommandFromJSONTyped(json, false);
}

export function AcceptByContractorCommandFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptByContractorCommand {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'failureIds': !exists(json, 'failureIds') ? undefined : json['failureIds'],
    };
}

export function AcceptByContractorCommandToJSON(value?: AcceptByContractorCommand | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'failureIds': value.failureIds,
    };
}


