import { FC, ReactNode } from "react";
import styles from "./FieldsetFieldsWrapper.module.scss";
import clsx from "clsx";

interface FieldsetFieldsWrapperProps {
  children?: ReactNode;
  className?: string;
}

const FieldsetFieldsWrapper: FC<FieldsetFieldsWrapperProps> = ({
  children,
  className,
}) => {
  return <div className={clsx(styles.wrapper, className)}>{children}</div>;
};

export default FieldsetFieldsWrapper;
