/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConfigSettingVm,
    ConfigSettingVmFromJSON,
    ConfigSettingVmToJSON,
    TranslationCodesVm,
    TranslationCodesVmFromJSON,
    TranslationCodesVmToJSON,
    VersionDto,
    VersionDtoFromJSON,
    VersionDtoToJSON,
} from '../models';

/**
 * 
 */
export class InformationApi extends runtime.BaseAPI {

    /**
     * Returns a part of the Configuration from Backend appSettings.json
     */
    async apiInformationConfigurationGetRaw(): Promise<runtime.ApiResponse<ConfigSettingVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Information/Configuration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigSettingVmFromJSON(jsonValue));
    }

    /**
     * Returns a part of the Configuration from Backend appSettings.json
     */
    async apiInformationConfigurationGet(): Promise<ConfigSettingVm> {
        const response = await this.apiInformationConfigurationGetRaw();
        return await response.value();
    }

    /**
     * Returns the Translation codes
     */
    async apiInformationTranslationCodesGetRaw(): Promise<runtime.ApiResponse<TranslationCodesVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Information/TranslationCodes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TranslationCodesVmFromJSON(jsonValue));
    }

    /**
     * Returns the Translation codes
     */
    async apiInformationTranslationCodesGet(): Promise<TranslationCodesVm> {
        const response = await this.apiInformationTranslationCodesGetRaw();
        return await response.value();
    }

    /**
     * Get version.
     */
    async apiInformationVersionGetRaw(): Promise<runtime.ApiResponse<VersionDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Information/Version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => VersionDtoFromJSON(jsonValue));
    }

    /**
     * Get version.
     */
    async apiInformationVersionGet(): Promise<VersionDto> {
        const response = await this.apiInformationVersionGetRaw();
        return await response.value();
    }

    /**
     * Get version history.
     */
    async apiInformationVersionHistoryGetRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Information/VersionHistory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Get version history.
     */
    async apiInformationVersionHistoryGet(): Promise<string> {
        const response = await this.apiInformationVersionHistoryGetRaw();
        return await response.value();
    }

}
