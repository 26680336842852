/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AssignDevicesToContractorCommand,
    AssignDevicesToContractorCommandFromJSON,
    AssignDevicesToContractorCommandToJSON,
    ContractorVm,
    ContractorVmFromJSON,
    ContractorVmToJSON,
    DetachAllDevicesFromContractorCommand,
    DetachAllDevicesFromContractorCommandFromJSON,
    DetachAllDevicesFromContractorCommandToJSON,
    DetachDeviceFromContractorCommand,
    DetachDeviceFromContractorCommandFromJSON,
    DetachDeviceFromContractorCommandToJSON,
    DetailedDynamicDeviceVm,
    DetailedDynamicDeviceVmFromJSON,
    DetailedDynamicDeviceVmToJSON,
    DevicePropertyOption,
    DevicePropertyOptionFromJSON,
    DevicePropertyOptionToJSON,
    DynamicDeviceVmPaginatedSearchResponse,
    DynamicDeviceVmPaginatedSearchResponseFromJSON,
    DynamicDeviceVmPaginatedSearchResponseToJSON,
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllDevicesQuery,
    GetAllDevicesQueryFromJSON,
    GetAllDevicesQueryToJSON,
} from '../models';

export interface ApiDevicesAssignDevicesToContractorPostRequest {
    assignDevicesToContractorCommand?: AssignDevicesToContractorCommand;
}

export interface ApiDevicesDetachAllDevicesFromContractorDeleteRequest {
    detachAllDevicesFromContractorCommand?: DetachAllDevicesFromContractorCommand;
}

export interface ApiDevicesDetachDeviceFromContractorDeleteRequest {
    detachDeviceFromContractorCommand?: DetachDeviceFromContractorCommand;
}

export interface ApiDevicesGetAllPostRequest {
    getAllDevicesQuery?: GetAllDevicesQuery;
}

export interface ApiDevicesGetDeviceContractorsidGetRequest {
    id: string;
}

export interface ApiDevicesIdGetRequest {
    id: string;
}

/**
 * 
 */
export class DevicesApi extends runtime.BaseAPI {

    /**
     * Assign multiple devices to a contractor
     */
    async apiDevicesAssignDevicesToContractorPostRaw(requestParameters: ApiDevicesAssignDevicesToContractorPostRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Devices/AssignDevicesToContractor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AssignDevicesToContractorCommandToJSON(requestParameters.assignDevicesToContractorCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Assign multiple devices to a contractor
     */
    async apiDevicesAssignDevicesToContractorPost(requestParameters: ApiDevicesAssignDevicesToContractorPostRequest): Promise<void> {
        await this.apiDevicesAssignDevicesToContractorPostRaw(requestParameters);
    }

    /**
     * Detaches a All Device from a Contractor Delete
     */
    async apiDevicesDetachAllDevicesFromContractorDeleteRaw(requestParameters: ApiDevicesDetachAllDevicesFromContractorDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Devices/DetachAllDevicesFromContractor`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DetachAllDevicesFromContractorCommandToJSON(requestParameters.detachAllDevicesFromContractorCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Detaches a All Device from a Contractor Delete
     */
    async apiDevicesDetachAllDevicesFromContractorDelete(requestParameters: ApiDevicesDetachAllDevicesFromContractorDeleteRequest): Promise<void> {
        await this.apiDevicesDetachAllDevicesFromContractorDeleteRaw(requestParameters);
    }

    /**
     * Detaches a Device from a Contractor Delete
     */
    async apiDevicesDetachDeviceFromContractorDeleteRaw(requestParameters: ApiDevicesDetachDeviceFromContractorDeleteRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Devices/DetachDeviceFromContractor`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DetachDeviceFromContractorCommandToJSON(requestParameters.detachDeviceFromContractorCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Detaches a Device from a Contractor Delete
     */
    async apiDevicesDetachDeviceFromContractorDelete(requestParameters: ApiDevicesDetachDeviceFromContractorDeleteRequest): Promise<void> {
        await this.apiDevicesDetachDeviceFromContractorDeleteRaw(requestParameters);
    }

    /**
     * List of device
     */
    async apiDevicesGetAllPostRaw(requestParameters: ApiDevicesGetAllPostRequest): Promise<runtime.ApiResponse<DynamicDeviceVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Devices/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllDevicesQueryToJSON(requestParameters.getAllDevicesQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DynamicDeviceVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * List of device
     */
    async apiDevicesGetAllPost(requestParameters: ApiDevicesGetAllPostRequest): Promise<DynamicDeviceVmPaginatedSearchResponse> {
        const response = await this.apiDevicesGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * List of Contractors of a specific Device
     */
    async apiDevicesGetDeviceContractorsidGetRaw(requestParameters: ApiDevicesGetDeviceContractorsidGetRequest): Promise<runtime.ApiResponse<Array<ContractorVm>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDevicesGetDeviceContractorsidGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Devices/GetDeviceContractors{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContractorVmFromJSON));
    }

    /**
     * List of Contractors of a specific Device
     */
    async apiDevicesGetDeviceContractorsidGet(requestParameters: ApiDevicesGetDeviceContractorsidGetRequest): Promise<Array<ContractorVm>> {
        const response = await this.apiDevicesGetDeviceContractorsidGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiDevicesIdGetRaw(requestParameters: ApiDevicesIdGetRequest): Promise<runtime.ApiResponse<DetailedDynamicDeviceVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiDevicesIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Devices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DetailedDynamicDeviceVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiDevicesIdGet(requestParameters: ApiDevicesIdGetRequest): Promise<DetailedDynamicDeviceVm> {
        const response = await this.apiDevicesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Gets the property types saved in config />.
     */
    async apiDevicesPropertiesGetRaw(): Promise<runtime.ApiResponse<Array<DevicePropertyOption>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Devices/Properties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DevicePropertyOptionFromJSON));
    }

    /**
     * Gets the property types saved in config />.
     */
    async apiDevicesPropertiesGet(): Promise<Array<DevicePropertyOption>> {
        const response = await this.apiDevicesPropertiesGetRaw();
        return await response.value();
    }

    /**
     * Syncs the devices />.
     */
    async apiDevicesSyncGetRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Devices/Sync`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Syncs the devices />.
     */
    async apiDevicesSyncGet(): Promise<void> {
        await this.apiDevicesSyncGetRaw();
    }

}
