import { RequestErrorBody } from "api/middleware/errorHandlingMiddleware";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { globalErrorDisplay } from "utils/globalErrorDisplay";
import { FieldError, GrapeAntdForm, GrapeAntdFormProps } from "widgets/form";
import { useBlocker } from "react-router-dom";
import useNavigationBlocker from "widgets/form/hooks/useNavigationBlocker";

const CustomForm: React.FC<GrapeAntdFormProps> = ({
  children,
  formUtils,
  viewMode,
  onBeforeNavigation,
  ...rest
}) => {
  const { t } = useTranslation();
  const hasBeenTouchedRef = useRef<boolean>(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      !viewMode &&
      hasBeenTouchedRef.current
  );

  useNavigationBlocker({
    blocker,
    formUtils: formUtils,
    onBeforeNavigation,
  });

  const getArgumentValueForErrorType = useCallback(
    (errorType: string, argument: string | null): any => {
      switch (errorType) {
        case "file-mime-type":
          if (argument === "image") {
            return t("error.argument.image");
          }

          return argument;
          break;

        case "max-length":
          return Number(argument) / (1024 * 1024);
          break;

        default:
          return argument;
          break;
      }
    },
    [t]
  );

  const backendErrorsMapper = useCallback(
    async (errorData: RequestErrorBody, formValues: object) => {
      const fieldErrors: FieldError[] = errorData.errors
        .filter((item) => item.fieldname && item.fieldname !== "")
        .reduce<FieldError[]>((previousValue, currentValue) => {
          const fieldName = currentValue.fieldname;
          const errorType = currentValue.errortype;
          const errorArgument = getArgumentValueForErrorType(
            errorType,
            currentValue.argument
          );
          const errorArgumentRange = currentValue.range;

          const errorMessage = t(`error.beField.${errorType}`, {
            argument: errorArgument,
            range: errorArgumentRange,
          });

          const alreadyExistingItem = previousValue.find(
            (item) => item.name === fieldName
          );

          if (!Object.prototype.hasOwnProperty.call(formValues, fieldName)) {
            globalErrorDisplay(errorData.code, currentValue.errorkey, false);

            return previousValue;
          }

          if (alreadyExistingItem) {
            alreadyExistingItem.errors = [
              ...alreadyExistingItem.errors,
              errorMessage,
            ];

            return previousValue;
          }

          return [
            ...previousValue,
            {
              name: fieldName,
              errors: [errorMessage] as string[],
            },
          ];
        }, []);

      return fieldErrors;
    },
    [getArgumentValueForErrorType, t]
  );

  useEffect(() => {
    if (viewMode) {
      hasBeenTouchedRef.current = false;
    }
  }, [viewMode]);

  return (
    <GrapeAntdForm
      scrollToFirstError
      backendErrorsMapper={backendErrorsMapper}
      formUtils={formUtils}
      viewMode={viewMode}
      {...rest}
      onValuesChange={(changedValues, values) => {
        if (hasBeenTouchedRef.current) {
          return;
        }

        hasBeenTouchedRef.current = true;

        rest.onValuesChange?.(changedValues, values);
      }}
    >
      {children}
    </GrapeAntdForm>
  );
};

export default CustomForm;
