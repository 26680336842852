/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetOptionsQuery,
    GetOptionsQueryFromJSON,
    GetOptionsQueryToJSON,
    OptionsVm,
    OptionsVmFromJSON,
    OptionsVmToJSON,
    UpdateOptionsCommand,
    UpdateOptionsCommandFromJSON,
    UpdateOptionsCommandToJSON,
} from '../models';

export interface ApiOptionsGetPostRequest {
    getOptionsQuery?: GetOptionsQuery;
}

export interface ApiOptionsPutRequest {
    updateOptionsCommand?: UpdateOptionsCommand;
}

/**
 * 
 */
export class OptionsApi extends runtime.BaseAPI {

    /**
     * Returns requested or all options.
     */
    async apiOptionsGetPostRaw(requestParameters: ApiOptionsGetPostRequest): Promise<runtime.ApiResponse<OptionsVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Options/Get`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetOptionsQueryToJSON(requestParameters.getOptionsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionsVmFromJSON(jsonValue));
    }

    /**
     * Returns requested or all options.
     */
    async apiOptionsGetPost(requestParameters: ApiOptionsGetPostRequest): Promise<OptionsVm> {
        const response = await this.apiOptionsGetPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update
     */
    async apiOptionsPutRaw(requestParameters: ApiOptionsPutRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Options`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOptionsCommandToJSON(requestParameters.updateOptionsCommand),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update
     */
    async apiOptionsPut(requestParameters: ApiOptionsPutRequest): Promise<void> {
        await this.apiOptionsPutRaw(requestParameters);
    }

}
