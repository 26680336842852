import { mdiDelete, mdiEye, mdiPlus } from "@mdi/js";
import { Button, Modal, ModalFuncProps, Space } from "antd";
import { SpecialPlaceVm } from "api/generated/lumen";
import { useDispatch, useSelector } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import IconButton from "components/ui/IconButton";
import useTable from "hooks/useTable";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTable from "widgets/table/CustomTable";
import { ColumnStorageName } from "widgets/table/table-settings/ColumnStorageName";
import {
  ColumnState,
  ExtendedColumnType,
} from "widgets/table/table-settings/ExtendedColumnType";
import {
  ColumnConfigParams,
  FilterMode,
  ListRequestParams,
} from "widgets/table/useTableUtils";
import SpecialPlacesCreateModal from "../create/SpecialPlacesCreateModal";

const { confirm } = Modal;

const SpecialPlacesList: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { listParams, list } = useSelector((state) => state.specialPlacesList);
  const { fetchList } = useSelector(
    (state) => state.loading.effects.specialPlacesList
  );
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchTableData = useCallback(
    (requestParams: ListRequestParams) =>
      dispatch.specialPlacesList.fetchList(requestParams),
    [dispatch.specialPlacesList]
  );

  const columnParams = useMemo<ColumnConfigParams<SpecialPlaceVm>[]>(
    () => [
      {
        key: "name",
        sort: true,
        defaultState: ColumnState.AlwaysVisible,
        filterMode: FilterMode.SEARCH,
        title: t("specialPlace.name"),
      },
      {
        key: "description",
        sort: true,
        defaultState: ColumnState.AlwaysVisible,
        filterMode: FilterMode.SEARCH,
        title: t("specialPlace.description"),
      },
    ],
    [t]
  );

  const handleNewButtonClick = useCallback(() => {
    setCreateModalOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(
    (id?: string) => {
      navigate(`/dictionaries/special-places/${id}`, {
        state: { dictionariesListPageSearchParams: location.search },
      });
    },
    [navigate, location.search]
  );

  const handleDeleteButtonClick = useCallback(
    (id?: string) => {
      if (typeof id !== "string") {
        return;
      }

      const confirmProps: ModalFuncProps = {
        okText: t("common.confirmDeleteModal.yes"),
        okType: "danger",
        cancelText: t("common.confirmDeleteModal.no"),
        title: t("common.confirmDeleteModal.title"),
        onOk: async () => {
          await dispatch.specialPlacesList.delete(id);

          return dispatch.specialPlacesList.fetchList(listParams);
        },
      };

      confirm(confirmProps);
    },
    [dispatch.specialPlacesList, listParams, t]
  );

  const actionColumnParams = useMemo<
    Partial<ExtendedColumnType<SpecialPlaceVm>> | undefined
  >(
    () => ({
      fixed: "right",
      columnOrderFixed: true,
      width: 90,
      render(item: any, record) {
        return (
          <Space>
            <IconButton
              path={mdiEye}
              onClick={() => handleViewButtonClick(record.id)}
            />

            <IconButton
              path={mdiDelete}
              onClick={() => handleDeleteButtonClick(record.id)}
            />
          </Space>
        );
      },
      title: t("common.table.actions"),
    }),
    [handleDeleteButtonClick, handleViewButtonClick, t]
  );

  const customTableProps = useTable({
    fetchTableData,
    columnParams,
    actionColumnParams,
    listParams,
    list,
    columnStorageName: ColumnStorageName.SPECIAL_PLACES,
    headerOptions: (
      <Button
        icon={<MaterialIcon path={mdiPlus} />}
        type="primary"
        onClick={handleNewButtonClick}
      >
        {t("common.new")}
      </Button>
    ),
    resetListParams: dispatch.specialPlacesList.resetListParams,
    loading: fetchList.loading,
    resetStore: dispatch.specialPlacesList.reset,
  });

  return (
    <>
      <CustomTable<SpecialPlaceVm>
        {...customTableProps}
        scrollX={800}
        selectable={false}
      />

      <SpecialPlacesCreateModal
        visible={createModalOpen}
        onCancel={() => setCreateModalOpen(false)}
        fetchDataParams={listParams}
      />
    </>
  );
};

export default SpecialPlacesList;
