import { FC, ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "features/auth/useAuth";
import { Role } from "models/common";
import DocumentTitle from "components/DocumentTitle";
import { useDispatch, useSelector } from "app/store";
import { ScrollToTop } from "./ScrollToTop";
import FailureCreateModal from "features/failures/create/FailureCreateModal";

export type PrivateRouteProps = {
  roles?: Role[];
  title?: string;
  children?: ReactNode;
};

export const PrivateRoute: FC<PrivateRouteProps> = ({
  roles,
  title,
  children,
}) => {
  const { userIsLoggedIn, hasRole, userInfo } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isVisible: isFailureCreateModalVisible } = useSelector(
    (state) => state.failureCreate
  );

  const renderLoggedInContent = () =>
    hasRole(roles) ? (
      <ScrollToTop>
        <DocumentTitle title={title} />
        {children}

        {userInfo && (
          <FailureCreateModal
            visible={isFailureCreateModalVisible}
            onCancel={() => dispatch.failureCreate.setIsVisible(false)}
          />
        )}
      </ScrollToTop>
    ) : (
      <Navigate to={{ pathname: "/" }} replace />
    );

  const renderNotLoggedInContent = () => (
    <Navigate to={{ pathname: "/login" }} state={{ from: location }} replace />
  );

  useEffect(() => {
    dispatch.common.setCurrentPageTitle(title);
  }, [dispatch.common, title]);

  return userIsLoggedIn ? renderLoggedInContent() : renderNotLoggedInContent();
};
