import { mdiLogoutVariant } from "@mdi/js";
import { useDispatch } from "app/store";
import { MaterialIcon } from "components/MaterialIcon";
import BrandImage from "components/ui/BrandImage";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./PrivateMobileHeader.module.scss";
import { Button } from "antd";
import useAuth from "features/auth/useAuth";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

interface PrivateMobileHeaderProps {
  userName?: string | null;
}

const PrivateMobileHeader: React.FC<PrivateMobileHeaderProps> = ({
  userName,
}) => {
  const dispatch = useDispatch();
  const { hasRole } = useAuth();
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Link to="/">
        <BrandImage subPath="logo-light.svg" className={styles.logo} />
      </Link>
      {hasRole(["Contractor"]) && (
        <Button
          onClick={() => {
            dispatch.common.setDeviceLayoutType("default");
            localStorage.setItem("fortana-view", "contractor");
          }}
          className={styles.viewSwitch}
          type="primary"
        >
          {t("common.view.contractor")}
        </Button>
      )}
      <div className={clsx({ [styles.names]: !hasRole(["Contractor"]) })}>
        <span className={styles.userName}>{userName}</span>
      </div>
      <div
        className={styles.logoutWrapper}
        onClick={() => dispatch.auth.logout()}
      >
        <MaterialIcon path={mdiLogoutVariant} />
      </div>
    </div>
  );
};

export default PrivateMobileHeader;
