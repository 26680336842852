/* tslint:disable */
/* eslint-disable */
/**
 * Lumen Failure Management API
 * <h5>ToDo: API description</h5>
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ExceptionDto,
    ExceptionDtoFromJSON,
    ExceptionDtoToJSON,
    GetAllInventoryItemsExcelQuery,
    GetAllInventoryItemsExcelQueryFromJSON,
    GetAllInventoryItemsExcelQueryToJSON,
    GetAllInventoryItemsQuery,
    GetAllInventoryItemsQueryFromJSON,
    GetAllInventoryItemsQueryToJSON,
    InventoryDetailsVm,
    InventoryDetailsVmFromJSON,
    InventoryDetailsVmToJSON,
    InventoryExcelVm,
    InventoryExcelVmFromJSON,
    InventoryExcelVmToJSON,
    InventoryVmPaginatedSearchResponse,
    InventoryVmPaginatedSearchResponseFromJSON,
    InventoryVmPaginatedSearchResponseToJSON,
} from '../models';

export interface ApiInventoryExportPostRequest {
    getAllInventoryItemsExcelQuery?: GetAllInventoryItemsExcelQuery;
}

export interface ApiInventoryGetAllPostRequest {
    getAllInventoryItemsQuery?: GetAllInventoryItemsQuery;
}

export interface ApiInventoryIdDeleteRequest {
    id: string;
}

export interface ApiInventoryIdGetRequest {
    id: string;
}

export interface ApiInventoryIdPutRequest {
    id: string;
    partNumber?: string;
    name?: string;
    manufacturer?: string;
    type?: string;
    imageData?: Blob;
    categoryId?: string;
    isActivated?: boolean;
}

export interface ApiInventoryPostRequest {
    partNumber?: string;
    name?: string;
    manufacturer?: string;
    type?: string;
    imageData?: Blob;
    categoryId?: string;
    isActivated?: boolean;
}

/**
 * 
 */
export class InventoryApi extends runtime.BaseAPI {

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of items as Excel file
     */
    async apiInventoryExportPostRaw(requestParameters: ApiInventoryExportPostRequest): Promise<runtime.ApiResponse<InventoryExcelVm>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Inventory/export`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllInventoryItemsExcelQueryToJSON(requestParameters.getAllInventoryItemsExcelQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InventoryExcelVmFromJSON(jsonValue));
    }

    /**
     * Yes, indeed, should be GET but there are circumstances with complex parameters in the query string
     * List of items as Excel file
     */
    async apiInventoryExportPost(requestParameters: ApiInventoryExportPostRequest): Promise<InventoryExcelVm> {
        const response = await this.apiInventoryExportPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Query the inventory
     */
    async apiInventoryGetAllPostRaw(requestParameters: ApiInventoryGetAllPostRequest): Promise<runtime.ApiResponse<InventoryVmPaginatedSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Inventory/GetAll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetAllInventoryItemsQueryToJSON(requestParameters.getAllInventoryItemsQuery),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InventoryVmPaginatedSearchResponseFromJSON(jsonValue));
    }

    /**
     * Query the inventory
     */
    async apiInventoryGetAllPost(requestParameters: ApiInventoryGetAllPostRequest): Promise<InventoryVmPaginatedSearchResponse> {
        const response = await this.apiInventoryGetAllPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiInventoryIdDeleteRaw(requestParameters: ApiInventoryIdDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiInventoryIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Inventory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The endpoint basic results in Microsoft.AspNetCore.Mvc.NoContentResult
     * Delete the item signed with the id.
     */
    async apiInventoryIdDelete(requestParameters: ApiInventoryIdDeleteRequest): Promise<void> {
        await this.apiInventoryIdDeleteRaw(requestParameters);
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiInventoryIdGetRaw(requestParameters: ApiInventoryIdGetRequest): Promise<runtime.ApiResponse<InventoryDetailsVm>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiInventoryIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const response = await this.request({
            path: `/api/Inventory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InventoryDetailsVmFromJSON(jsonValue));
    }

    /**
     * Gets the requested instance, identified by id.
     */
    async apiInventoryIdGet(requestParameters: ApiInventoryIdGetRequest): Promise<InventoryDetailsVm> {
        const response = await this.apiInventoryIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiInventoryIdPutRaw(requestParameters: ApiInventoryIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiInventoryIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.partNumber !== undefined) {
            formParams.append('PartNumber', requestParameters.partNumber as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.manufacturer !== undefined) {
            formParams.append('Manufacturer', requestParameters.manufacturer as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('Type', requestParameters.type as any);
        }

        if (requestParameters.imageData !== undefined) {
            formParams.append('ImageData', requestParameters.imageData as any);
        }

        if (requestParameters.categoryId !== undefined) {
            formParams.append('CategoryId', requestParameters.categoryId as any);
        }

        if (requestParameters.isActivated !== undefined) {
            formParams.append('IsActivated', requestParameters.isActivated as any);
        }

        const response = await this.request({
            path: `/api/Inventory/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the current instance with the fulfilled model
     */
    async apiInventoryIdPut(requestParameters: ApiInventoryIdPutRequest): Promise<void> {
        await this.apiInventoryIdPutRaw(requestParameters);
    }

    /**
     * Creates an inventory item item
     */
    async apiInventoryPostRaw(requestParameters: ApiInventoryPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["x-api-key"] = this.configuration.apiKey("x-api-key"); // x-api-key authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
            { contentType: 'application/json' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.partNumber !== undefined) {
            formParams.append('PartNumber', requestParameters.partNumber as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.manufacturer !== undefined) {
            formParams.append('Manufacturer', requestParameters.manufacturer as any);
        }

        if (requestParameters.type !== undefined) {
            formParams.append('Type', requestParameters.type as any);
        }

        if (requestParameters.imageData !== undefined) {
            formParams.append('ImageData', requestParameters.imageData as any);
        }

        if (requestParameters.categoryId !== undefined) {
            formParams.append('CategoryId', requestParameters.categoryId as any);
        }

        if (requestParameters.isActivated !== undefined) {
            formParams.append('IsActivated', requestParameters.isActivated as any);
        }

        const response = await this.request({
            path: `/api/Inventory`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Creates an inventory item item
     */
    async apiInventoryPost(requestParameters: ApiInventoryPostRequest): Promise<string> {
        const response = await this.apiInventoryPostRaw(requestParameters);
        return await response.value();
    }

}
