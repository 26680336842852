import React from "react";
import themeConfig from "themeConfig";

interface BrandImageProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ImgHTMLAttributes<HTMLImageElement>,
      HTMLImageElement
    >,
    "src"
  > {
  subPath: string;
  path?: string;
}

const BrandImage: React.FC<BrandImageProps> = ({
  subPath,
  path,
  alt,
  ...rest
}) => {
  return (
    <img
      src={`${path || themeConfig.imagesFolder}/${subPath}`}
      alt={alt}
      {...rest}
    />
  );
};

export default BrandImage;
