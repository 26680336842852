import { createModel } from "@rematch/core";
import { api } from "api";
import { OptionsVm, UpdateOptionsCommand } from "api/generated/lumen";
import { RootModel } from "app/store";

interface SettingsState {
  settings?: OptionsVm;
}

const initialState: SettingsState = {
  settings: undefined,
};

export const settings = createModel<RootModel>()({
  state: initialState,
  reducers: {
    setSettings(state, payload: OptionsVm) {
      state.settings = payload;
    },
    reset: () => initialState,
  },
  effects: (dispatch) => {
    const { settings } = dispatch;

    return {
      async fetchSettings(keys?: string[]): Promise<void> {
        const data = await api.lumen.options.apiOptionsGetPost({
          getOptionsQuery: { keys: keys },
        });

        dispatch(settings.setSettings(data));
      },

      async editSettings(options: UpdateOptionsCommand): Promise<void> {
        await api.lumen.options.apiOptionsPut({
          updateOptionsCommand: options,
        });
      },
    };
  },
});
