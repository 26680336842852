import React, {
  createContext,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useEffect,
  useState,
} from "react";
import { Form, FormInstance } from "antd";
import { FormProps } from "antd/es/form/Form";
import GrapeAntdFormItem from "./GrapeAntdFormItem";
import GrapeAntdFormSubmit from "./GrapeAntdFormSubmit";
import useFormUtils, { FormUtils } from "../hooks/useGrapeAntdForm";
import { SchemaKey } from "../utils/schema";
import { NamePath } from "antd/lib/form/interface";
import { RequestErrorBody } from "api/middleware/errorHandlingMiddleware";

export interface GrapeAntdFormProps extends FormProps {
  schema?: SchemaKey;
  formUtils?: FormUtils;
  loading?: boolean;
  viewMode?: boolean;
  backendErrorsMapper?: (error: any, formValues: any) => Promise<FieldError[]>;
  onBeforeNavigation?: () => void;
}

export type GrapeAntdFormComponent = ForwardRefExoticComponent<
  GrapeAntdFormProps & RefAttributes<FormInstance<any>>
> & { Item: typeof GrapeAntdFormItem; Submit: typeof GrapeAntdFormSubmit };

// Form context to get schema props for each field
export const GrapeAntdFormContext = createContext({} as any);

export interface FieldError {
  errors: string[];
  name: NamePath;
}

const GrapeAntdForm = forwardRef(
  (
    {
      formUtils,
      schema,
      loading,
      viewMode,
      onFinish,
      backendErrorsMapper,
      ...formProps
    }: GrapeAntdFormProps,
    ref?: React.Ref<any>
  ) => {
    const formUtilsBasedOnSchema = useFormUtils(schema);
    const [backendFieldErrors, setBackendFieldErrors] =
      useState<FieldError[]>();

    const utils = formUtils || formUtilsBasedOnSchema;
    const { form, submittable, handleFieldsChanged } = utils;
    const [fieldsAreTouched, setFieldsAreTouched] = useState<boolean>(false);

    useEffect(() => {
      if (viewMode) {
        setFieldsAreTouched(false);
      }
    }, [viewMode]);

    // custom error handler for form field errors
    const handleFinish = async (values: any): Promise<void> => {
      try {
        await onFinish?.(values);

        setBackendFieldErrors(undefined);
        form?.setFields([]);
        setFieldsAreTouched(false);
      } catch (error: any) {
        const errorData: RequestErrorBody = await error.json();
        const fieldErrors =
          (await backendErrorsMapper?.(errorData, values)) || [];

        const hasOtherError = errorData.errors.length > fieldErrors.length;

        setBackendFieldErrors(fieldErrors);
        form?.setFields(fieldErrors);

        if (hasOtherError) {
          throw error;
        }
      }
    };

    return (
      <GrapeAntdFormContext.Provider
        value={{
          schema: utils.schema,
          requiredFields: utils.required,
          loading,
          submittable,
          backendFieldErrors,
          viewMode,
          form,
          fieldsAreTouched,
        }}
      >
        <Form
          layout="vertical"
          form={form}
          onFieldsChange={() => {
            handleFieldsChanged();
            setFieldsAreTouched(true);
          }}
          onFinish={handleFinish}
          ref={ref}
          noValidate
          {...(formProps as any)}
        />
      </GrapeAntdFormContext.Provider>
    );
  }
) as GrapeAntdFormComponent;

GrapeAntdForm.Item = GrapeAntdFormItem;
GrapeAntdForm.Submit = GrapeAntdFormSubmit;

export default GrapeAntdForm;
